import { Button, FormControl, Modal, Select, useDisclose } from 'native-base';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

function CopyRangeDialog({ target, widgets, subtype, onConfirm }, ref) {
  const { isOpen, onOpen, onClose } = useDisclose();
  const [source, setSource] = useState(null);

  const filteredWidgets = widgets.filter(
    (widget) =>
      (['TH', 'CY'].includes(subtype.tag) || widget.subtype === subtype.id) &&
      widget.ranges.length > 0 &&
      widget.id !== target?.id
  );

  useImperativeHandle(ref, () => ({
    show: onOpen,
    close: onClose,
  }));

  const handleConfirm = () => {
    const selectedSource = widgets.find((i) => i.id.toString() === source);
    onConfirm(selectedSource);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content placement="center">
        <Modal.CloseButton />
        <Modal.Header>Add Range</Modal.Header>
        <Modal.Body>
          <FormControl>
            <FormControl.Label>Copy from widget</FormControl.Label>
            <Select placeholder="Please select..." onValueChange={setSource}>
              {filteredWidgets.map((widget) => (
                <Select.Item
                  key={widget.id}
                  label={
                    widget.display_name || widget?.data[`field${widget.field}`]
                  }
                  value={widget.id.toString()}
                />
              ))}
            </Select>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" mr="1" onPress={onClose}>
              Cancel
            </Button>
            <Button onPress={handleConfirm}>Confirm</Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

const WrappedCopyRangeDialog = forwardRef(CopyRangeDialog);

export { WrappedCopyRangeDialog as CopyRangeDialog };
