import { useGlobalContext } from '@hooks/useGlobalContext';
import { orientation } from '@hooks/useWinDimensions';
import { maxBy } from 'lodash';
import {
  Box,
  Hidden,  
  VStack,
  useBreakpointValue,
} from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

const StaticGridItem = ({
  children,
  cols,
  cellHeight,
  w,
  h,
  x,
  y,
  inset = 5,
  oneColumn = false,
}) => {
  const inner = (
    <Box
      w="100%"
      h="100%"
      _light={{ bg: 'widgetBgColorLight' }}
      _dark={{ bg: 'widgetBgColorDark' }}
      rounded={4}
      shadow={1}
    >
      {children}
    </Box>
  );
  if (oneColumn) {
    return (
      <Box w={'100%'} h={`${h * cellHeight}px`} p={`${inset}px`}>
        {inner}
      </Box>
    );
  }
  return (
    <Box
      position="absolute"
      w={`${(w * 100) / cols}%`}
      h={`${h * cellHeight}px`}
      left={`${(x * 100) / cols}%`}
      top={`${y * cellHeight}px`}
      p={`${inset}px`}
    >
      {inner}
    </Box>
  );
};

const StaticGridLayout = ({ widgets, render, header }) => {
  const isLargeScreen = useBreakpointValue({
    md: true,
  });
  const { winDims, currentViewType } = useGlobalContext();
  const { deviceOrientation } = winDims;
  const cols = currentViewType === 'M' ? 12 : 24; 
  const cellHeight = 35;
  let oneColumn = false;
  const gridItemFieldName =
    currentViewType === 'M' ? 'mobile_grid_item' : 'web_grid_item';

  const lastX = maxBy(
    widgets,
    (o) => o[gridItemFieldName].x + o[gridItemFieldName].w
  );
  const lastY = maxBy(
    widgets,
    (o) => o[gridItemFieldName].y + o[gridItemFieldName].h
  );
  const isSixCols =
    lastX[gridItemFieldName].x + lastX[gridItemFieldName].w <= 12;
  const containerHeight =
    lastY &&
    (lastY[gridItemFieldName].y + lastY[gridItemFieldName].h) * cellHeight;

  if (!isSixCols) {
    if (!isLargeScreen && deviceOrientation === orientation.PORTRAIT) {
      oneColumn = true;
    }
  }


  return (
    <Box
      _web={{
        _dark: { bgColor: '#1d2027' },
      }}
      mb={4}
      rounded={5}
      flex={1}
      w="100%"
    >
      <Hidden till="md">
        {header && (
          <Box p={3} m="4px" mt="0">
            {header}
          </Box>
        )}
      </Hidden>
      <Box
        w={currentViewType === 'M' && isLargeScreen ? '50%' : '100%'}
        minH={`${cellHeight}px`}
        height={oneColumn ? '100%' : `${containerHeight}px`}
        alignSelf="center"
      >
        {render({ cols, cellHeight, oneColumn }, widgets)}
      </Box>
    </Box>
  );
};

export { StaticGridItem, StaticGridLayout };
