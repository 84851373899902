import { ColorInput } from '@components/ColorInput';
import { BottomBar } from '@components/Container';
import { DiscardButton } from '@components/DiscardButton';
import { SubmitButton } from '@components/SubmitButton';
import { useRoute } from '@react-navigation/native';
import { toInteger } from 'lodash';
import { useLinkTo } from '@react-navigation/native';
import {
  Box,
  Checkbox,
  FormControl,
  HStack,
  Input,
  Select,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import { saveProfile } from '@utils/profile';
import { useAuth } from '@hooks/useAuth';

function Form({
  values,
  errors,
  handleChange,
  handleBlur,
  sources,
  widget,
  types,
  setFieldValue,
}) {
  const linkTo = useLinkTo();
  const route = useRoute();
  const { params } = route;
  const { fetchUserData } = useAuth();
  const findSource = (id) =>
    sources.find((source) => source.id === toInteger(id));

  const handleSourceChange = (text) => {
    if (text === '-1') {
      linkTo('/connections/new?selectable=1');
    } else {
      saveProfile({
        user_last_source: text,
      }).then(fetchUserData);
      handleChange('source')(text);
    }
  };

  return (
    <>
      <VStack space={3}>
        <FormControl isRequired isInvalid={'source' in errors}>
          <FormControl.Label>Connection</FormControl.Label>
          <Select
            placeholder="Please select..."
            onValueChange={handleSourceChange}
            onBlur={handleBlur('source')}
            selectedValue={values.source}
          >
            <Select.Item label="New Connection" value={-1} />
            {sources.map((o) => (
              <Select.Item
                label={o.display_name}
                key={o.id}
                value={o.id.toString()}
              />
            ))}
          </Select>
          <FormControl.ErrorMessage>{errors.source}</FormControl.ErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={'field' in errors}>
          <FormControl.Label>Field</FormControl.Label>
          <Select
            placeholder="Please select..."
            onValueChange={handleChange('field')}
            onBlur={handleBlur('field')}
            selectedValue={values.field}
          >
            {findSource(values.source).allFields ? (
              findSource(values.source).allFields.map((o) => (
                <Select.Item
                  label={`${o.name} (${o.id})`}
                  key={o.id}
                  value={o.id.toString()}
                />
              ))
            ) : (
              <></>
            )}
          </Select>
          <FormControl.ErrorMessage>{errors.field}</FormControl.ErrorMessage>
        </FormControl>
        <FormControl>
          <FormControl.Label>Display name</FormControl.Label>
          <Input
            onChangeText={handleChange('display_name')}
            onBlur={handleBlur('display_name')}
            value={values.display_name}
            maxLength={64}
          />
        </FormControl>
        <FormControl>
          <FormControl.Label>Decimal places</FormControl.Label>
          <Input
            keyboardType="numeric"
            onChangeText={(text) =>
              handleChange('decimal_places')(text.replace(/[^0-9]/g, ''))
            }
            maxLength={2}
            onBlur={handleBlur('decimal_places')}
            value={values.decimal_places}
          />
        </FormControl>
        <FormControl>
          <FormControl.Label>Unit</FormControl.Label>
          <Input
            onChangeText={handleChange('unit')}
            onBlur={handleBlur('unit')}
            value={values.unit}
            maxLength={5}
          />
        </FormControl>
        <HStack space={3}>
          <FormControl
            isRequired
            isInvalid={'type' in errors}
            flex={1}
            flexGrow={1}
          >
            <FormControl.Label>Type</FormControl.Label>
            <Select
              placeholder="Please select..."
              onValueChange={handleChange('type')}
              onBlur={handleBlur('type')}
              selectedValue={values.type}
            >
              {types.map((o) => (
                <Select.Item key={o.value} label={o.label} value={o.value} />
              ))}
            </Select>
            <FormControl.ErrorMessage>{errors.type}</FormControl.ErrorMessage>
          </FormControl>
          <FormControl flex={1} flexGrow={1}>
            <FormControl.Label>Thickness</FormControl.Label>
            <Select
              placeholder="Please select..."
              onValueChange={handleChange('thickness')}
              onBlur={handleBlur('thickness')}
              selectedValue={values.thickness}
            >
              <Select.Item label="1" value="1" />
              <Select.Item label="2" value="2" />
              <Select.Item label="3" value="3" />
            </Select>
          </FormControl>
        </HStack>
        <HStack space={3}>
          <FormControl flex={1} flexGrow={1}>
            <FormControl.Label>Transparency</FormControl.Label>
            <Select
              placeholder="Please select..."
              onValueChange={handleChange('transparency')}
              onBlur={handleBlur('transparency')}
              selectedValue={values.transparency}
            >
              <Select.Item label="None" value="1" />
              <Select.Item label="Low" value="0.75" />
              <Select.Item label="Medium" value="0.5" />
              <Select.Item label="High" value="0.25" />
            </Select>
          </FormControl>
          <FormControl flex={1} flexGrow={1}>
            <FormControl.Label>Color</FormControl.Label>
            <ColorInput
              onChange={handleChange('color')}
              onBlur={handleBlur('color')}
              value={values.color}
            />
          </FormControl>
        </HStack>
        <VStack>
          <Checkbox
            value="0"
            size="sm"
            isChecked={values.y_scale_is_active}
            onChange={(checked) => {
              setFieldValue('y_scale_is_active', checked);
              setFieldValue('scale_min', '');
              setFieldValue('scale_max', '');
            }}
          >
            Set Y-axis scale
          </Checkbox>
          {values.y_scale_is_active && (
            <HStack space={3} mt="1">
              <FormControl flex={1} flexGrow={1}>
                <FormControl.Label>Scale (min)</FormControl.Label>
                <Input
                  keyboardType="numeric"
                  onChangeText={handleChange('scale_min')}
                  onBlur={handleBlur('scale_min')}
                  value={values.scale_min}
                />
              </FormControl>
              <FormControl flex={1} flexGrow={1}>
                <FormControl.Label>Scale (max)</FormControl.Label>
                <Input
                  keyboardType="numeric"
                  onChangeText={handleChange('scale_max')}
                  onBlur={handleBlur('scale_max')}
                  value={values.scale_max}
                />
              </FormControl>
            </HStack>
          )}
        </VStack>
      </VStack>
      <BottomBar>
        <DiscardButton
          to={`/panels/${params?.panel_id}/widgets/${params?.widget_id}`}
        />
        <SubmitButton />
      </BottomBar>
    </>
  );
}

export { Form };
