import React, { memo, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

function ControlledField({ value, onChangeText, children }) {
  const [textValue, setTextValue] = useState(value);
  const debounced = useDebouncedCallback(onChangeText, 200);

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  const handleChangeText = (text) => {
    setTextValue(text);
    debounced(text);
  };

  return children({ value: textValue, onChangeText: handleChangeText });
}

const WrappedControlledField = memo(ControlledField);

export { WrappedControlledField as ControlledField };
