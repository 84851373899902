import { useGlobalContext } from '@hooks/useGlobalContext';
import { Button, HStack } from 'native-base';
import React from 'react';
import sleep from 'sleep-promise';

function DockedFormFooter({ onDiscardPress, onSavePress, isSubmitting }) {
  const { alertDialog } = useGlobalContext();

  const handleDiscardPress = () => {
    alertDialog.current.show(
      'Discard changes',
      'Do you want to discard the changes?',
      <Button
        colorScheme="danger"
        onPress={() => {
          alertDialog.current.close();
          sleep(200).then(onDiscardPress);
        }}
      >
        Discard
      </Button>
    );
  };

  return (
    <HStack space={3} mt={{ base: 50 }} pb={{ base: 10, md: 5 }}>
      <Button
        colorScheme="secondary"
        flex={1}
        flexGrow={1}
        isDisabled={isSubmitting}
        onPress={handleDiscardPress}
      >
        DISCARD
      </Button>
      <Button
        flex={1}
        flexGrow={1}
        onPress={onSavePress}
        isLoading={isSubmitting}
        isLoadingText="Saving..."
      >
        SAVE
      </Button>
    </HStack>
  );
}

export { DockedFormFooter };
