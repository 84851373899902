import { LoadingIndicator } from '@components/LoadingIndicator';
import { WinDimensions } from '@components/WinDimensions';
import { useGlobalContext } from '@hooks/useGlobalContext';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { getChartScales } from '@utils/chartscale';
import { getPanel, savePanel } from '@utils/panel';
import { getWidgets } from '@utils/widget';
import { cloneDeep, get } from 'lodash';
import {
  Button,
  Center,
  HStack,
  Hidden,
  Menu,
  Text,
  VStack,
  useBreakpointValue,
} from 'native-base';
import { Platform } from 'react-native';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { CustomHeaderRight } from '@components/CustomHeaderRight';
import { GridLayout } from './GridLayout';
import { Header } from './header';
import * as Clipboard from 'expo-clipboard';
import { NotFoundScreen } from '@screens/NotFoundScreen';
import Constants from 'expo-constants';
import useInterval from 'use-interval';
import { Container } from '@components/Container';
import { bodyBgColor } from '@utils/customTheme';
import {
  retrieveChannelData,
  retrieveChannelFeeds,
} from '@utils/thingSpeakAPI_2';
import { HeaderBackButton } from '@react-navigation/elements';
import { useAuth } from '@hooks/useAuth';
import { ChartScale } from '@components/ChartScale/ChartScale';
import * as WebBrowser from 'expo-web-browser';

const getInitialScale = (panel, scales) => {
  const firstScale = scales.find((scale) => scale.name === '1H');
  const currentScale = get(panel, 'preferences.selectedScale', firstScale);
  return currentScale;
};

function WidgetPreviewScreen({ route, navigation }) {
  const { params } = route;
  const [panel, setPanel] = useState(null);
  const [widgets, setWidgets] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const [visibleScale, setVisibleScale] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scales, setScales] = useState([]);
  const [currentScale, setCurrentScale] = useState(null);
  const gridLayout = useRef(null);
  const {
    winDims,
    toast,
    currentViewType,
    setCurrentViewType,
    progressDialog,
    alertDialog
  } = useGlobalContext();

  const { deviceOrientation } = winDims;
  const chartScaleRef = useRef(null);
  const linkTo = useLinkTo();
  const [pollingDelay] = useState(5000);
  const [pollingEnabled, setPollingEnabled] = useState(false);
  const { isAuthenticated } = useAuth();
  const isLargeScreen = useBreakpointValue({
    md: true,
  });
  const platformType = Platform.OS === 'web' ? 'W' : 'M';
  const gridItemFieldName =
    currentViewType === 'M' ? 'mobile_grid_item' : 'web_grid_item';
  const hasTimeSeries = async (widgetData) => {
    widgetData.forEach((widget) => {
      if (!visibleScale && widget.type_tag === 'T') {
        setVisibleScale(true);
      }
    });
  };
  useFocusEffect(
    useCallback(() => {
      Promise.all([
        getPanel(params?.panel_id),
        getWidgets(params?.panel_id).then(retrieveChannelData),
        getChartScales(),
      ])
        .then((resp) => {
          const panel_data = resp[0].data;
          const widgets_data = resp[1];
          const scales_data = resp[2].data;
          setPanel(panel_data);
          setCurrentViewType((prevState) => {
            if (!prevState) {
              return panel_data.type === 'B' ? platformType : panel_data.type;
            }
            return prevState;
          });
          setWidgets(widgets_data);
          hasTimeSeries(widgets_data);

          setScales(scales_data);
          setCurrentScale(getInitialScale(panel_data, scales_data));

          setIsLoading(false);
          setPollingEnabled(true);
        })
        .catch((err) => {
          console.log(err);
          setIsNotFound(true);
          setIsLoading(false);
        });
      return () => {
        setPanel(null);
        setWidgets([]);
        setIsLoading(true);
        setIsNotFound(false);
        setPollingEnabled(false);
      };
    }, [navigation, route, deviceOrientation, platformType])
  );

  useInterval(
    () => {
      retrieveChannelFeeds({
        data: cloneDeep(widgets),
      }).then(setWidgets);
      //console.log('Polling');
    },
    pollingEnabled ? pollingDelay : null
  );

  const savePrefs = ({ selectedScale }) => {
    const { preferences } = panel;
    savePanel(panel.id, {
      ...panel,
      preferences: {
        ...preferences,
        selectedScale,
      },
    });
  };

  const copyAccessURLToClipboard = async () => {
    const accessURL = `${Constants.manifest.extra.webScheme}/panels/${panel.id}/preview`;
    try {
      const result = await Clipboard.setStringAsync(accessURL);
      toast.show({
        placement: 'top',
        description: 'Link copied to clipboard',
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewTypeChange = async () => {
    const values = {
      ...panel,
      type: panel.type !== currentViewType ? ['W', 'M'] : currentViewType,
    };
    progressDialog.current.show();
    await savePanel(values.id, values);
    progressDialog.current.close();
    linkTo(`/panels/${params?.panel_id}/widgets`);
  };

  useLayoutEffect(() => {
    if (!isLoading && !isNotFound) {
      navigation.setOptions({
        title: panel.name + ' - 107HI',
        headerTitle: panel.name,
        headerLeft: (props) => (
          <HeaderBackButton
            {...props}
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else if (isAuthenticated) {
                linkTo('/panels');
              } else {
                linkTo('/signin');
              }
            }}
          />
        ),
        headerRight: (props) => (
          <CustomHeaderRight
            {...props}
            route={route}
            overflowMenu={(rest) => (
              <>
                {visibleScale && (
                  <Menu.Item onPress={() => chartScaleRef.current.onOpen()}>
                    Time selection
                  </Menu.Item>
                )}
                {panel.is_owner && (
                  <Menu.Item
                    onPress={() => {
                      if(Platform.OS !== 'web'){
                        
                        alertDialog.current.show(
                          'Warning',
                          'The panel configuration feature is available only in our web plataform.',
                          <Button
                            colorScheme="danger"
                            onPress={() => {
                              alertDialog.current.close();
                              
                              WebBrowser.openBrowserAsync(`${Constants.manifest.extra.webScheme}`);
                            }}
                          >
                            Visit website
                          </Button>
                        )
                      }else{
                        linkTo(`/panels/${panel.id}/widgets`);
                      }
                      
                    }}
                  >
                    Edit
                  </Menu.Item>
                )}
                {panel.is_owner && !panel.is_private && (
                  <Menu.Item onPress={copyAccessURLToClipboard}>
                    Copy link
                  </Menu.Item>
                )}
              </>
            )}
          />
        ),
      });
    }
  }, [
    isLoading,
    isNotFound,
    navigation,
    panel,
    currentScale,
    isAuthenticated,
    currentViewType,
  ]);

  if (isNotFound) {
    return <NotFoundScreen />;
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <WinDimensions />
      <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
        <VStack>
          <Hidden till="md">
            <HStack py={2} alignItems="center" justifyContent="flex-end">
              <Header
                panel={panel}
                savePrefs={savePrefs}
                chartScaleRef={chartScaleRef}
                scales={scales}
                visibleScale={visibleScale}
                currentScale={currentScale}
                setCurrentScale={setCurrentScale}
                copyAccessURLToClipboard={copyAccessURLToClipboard}
                onViewTypeChange={handleViewTypeChange}
              />
            </HStack>
          </Hidden>
          {visibleScale && !isLargeScreen && (
            <ChartScale
              ref={chartScaleRef}
              savePrefs={savePrefs}
              scales={scales}
              currentScale={currentScale}
              setCurrentScale={setCurrentScale}
            />
          )}
          <VStack>
            {widgets.length === 0 ? (
              <Center flex={{ base: 1, md: 0 }} mt={{ base: 0, md: 10 }}>
                <Text fontSize="lg" color="secondary.300">
                  You don't have any widget yet
                </Text>
              </Center>
            ) : (
              <GridLayout
                ref={gridLayout}
                panel={panel}
                widgets={widgets}
                setWidgets={setWidgets}
                currentScale={currentScale}
                pollingDelay={pollingDelay}
                pollingEnabled={pollingEnabled}
                gridItemFieldName={gridItemFieldName}
              />
            )}
          </VStack>
        </VStack>
      </Container>
    </>
  );
}

export { WidgetPreviewScreen };
