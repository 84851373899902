import { isBlank } from '@utils/stringHelper';
import { Button, FormControl, Input, Modal } from 'native-base';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import sleep from 'sleep-promise';

const EditTitle = forwardRef(({ onPanelNameChange, panel, setPanel }, ref) => {
  const [title, setTitle] = useState(panel.name);
  const [show, setShow] = useState(false);
  const initialRef = useRef(null);

  const handleClose = () => setShow(false);
  const onShow = () => setShow(true);
  useImperativeHandle(ref, () => ({
    close: handleClose,
    show: onShow,
  }));

  const handleSubmit = () => {
    onPanelNameChange(title);
    handleClose();
    sleep(100).then(() =>
      setPanel((currentPanel) => ({
        ...currentPanel,
        name: isBlank(title) ? 'Untitled' : title,
      }))
    );
  };

  return (
    <Modal isOpen={show} onClose={handleClose} initialFocusRef={initialRef}>
      <Modal.Content placement="center">
        <Modal.CloseButton />
        <Modal.Header>Edit Panel</Modal.Header>
        <Modal.Body>
          <FormControl>
            <FormControl.Label>Name</FormControl.Label>
            <Input
              ref={initialRef}
              placeholder="Untitled"
              defaultValue={title}
              onChangeText={(text) => setTitle(text)}
              onSubmitEditing={handleSubmit}
              maxLength={64}
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" mr="1" onPress={handleClose}>
              Cancel
            </Button>
            <Button onPress={handleSubmit}>Save</Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
});

export { EditTitle };
