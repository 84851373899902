import { Container } from '@components/Container';
import { DeleteItemMenu } from '@components/DeleteItemMenu';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { presetColors } from '@components/Widget/TimeSeries/common';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { customMessages } from '@utils/customMessages';
import { isEditMode } from '@utils/screenHelper';
import { getSources } from '@utils/source';
import { getWidget } from '@utils/widget';
import {
  createWidgetField,
  deleteWidgetField,
  getWidgetField,
  saveWidgetField,
} from '@utils/widgetField';
import { Formik } from 'formik';
import { sample } from 'lodash';
import { Hidden, HStack, ScrollView, VStack } from 'native-base';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import * as Yup from 'yup';
import { CustomHeaderRight } from '@components/CustomHeaderRight';
import { Form } from './form';
import { Header } from './header';
import { bodyBgColor } from '@utils/customTheme';
import { useAuth } from '@hooks/useAuth';
import { toNumeric } from '@utils/numberHelper';

const validationSchema = Yup.object().shape({
  source: Yup.string().required(customMessages.isRequiredField),
  field: Yup.string().required(customMessages.isRequiredField),
  type: Yup.string().required(customMessages.isRequiredField),
});

const getSelectItemTypes = ({ subtype_tag }) => {
  const items = [
    { value: '1', label: 'Line' },
    { value: '2', label: 'Area' },
    { value: '3', label: 'Stacked' },
  ];
  if (subtype_tag === 'TL') return items.filter((i) => i.value === '1');
  else if (subtype_tag === 'TA') return items.filter((i) => i.value === '2');
  else if (subtype_tag === 'TS') return items.filter((i) => i.value === '3');
  return items.filter((i) => i.value !== '3');
};

const WidgetFieldDetailScreen = ({ route, navigation }) => {
  const { params } = route;
  const linkTo = useLinkTo();
  const [types, setTypes] = useState([]);
  const [sources, setSources] = useState([]);
  const [widget, setWidget] = useState(null);
  const [field, setField] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useAuth();

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      return () => {
        setIsLoading(false);
        global.newConnectionId = null;
      };
    }, [navigation])
  );

  useEffect(() => {
    if (isLoading) {
      Promise.all([
        getSources(),
        getWidget(params?.panel_id, params?.widget_id),
      ]).then((resp) => {
        setSources(resp[0].data);
        setWidget(resp[1].data);
        setTypes(getSelectItemTypes(resp[1].data));
      });
    }
  }, [params?.panel_id, params?.widget_id, isLoading]);

  useEffect(() => {
    if (widget) {
      if (isEditMode(route)) {
        getWidgetField(params?.panel_id, params?.widget_id, params?.id).then(
          (resp) => {
            setField(resp.data);
            setIsLoading(false);
          }
        );
      } else {
        setIsLoading(false);
      }
    }
  }, [widget]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: (props) => (
        <CustomHeaderRight
          {...props}
          route={route}
          overflowMenu={(rest) => (
            <DeleteItemMenu
              {...rest}
              onPress={() =>
                deleteWidgetField(
                  params?.panel_id,
                  params?.widget_id,
                  params?.id
                ).then(() =>
                  linkTo(
                    `/panels/${params?.panel_id}/widgets/${params?.widget_id}`
                  )
                )
              }
            />
          )}
        />
      ),
    });
  }, [navigation]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const handleFormSubmit = async (values) => {
    values = {
      ...values,
      scale_min: toNumeric(values.scale_min),
      scale_max: toNumeric(values.scale_max),
    };
    if (isEditMode(route)) {
      await saveWidgetField(
        params?.panel_id,
        params?.widget_id,
        values.id,
        values
      );
    } else {
      await createWidgetField(params?.panel_id, params?.widget_id, values);
    }
    linkTo(`/panels/${params?.panel_id}/widgets/${params?.widget_id}`);
  };

  return (
    
      <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
        <VStack>
          <Hidden till="md">
            <HStack py={2} alignItems="center" justifyContent="space-between">
              <Header />
            </HStack>
          </Hidden>
          <VStack
            _light={{ bg: { md: 'containerBgColorLight' } }}
            _dark={{ bg: { md: 'containerBgColorDark' } }}
            shadow={{ md: '1' }}
            borderRadius={{ md: '4' }}
            py={{ base: 2, md: 5 }}
            px={{ base: 2, md: 5 }}
            mb={{ base: 0, md: 5 }}
          >
            <Formik
              initialValues={{
                display_name: null,
                decimal_places: null,
                unit: null,
                color: sample(presetColors),
                ...field,
                type: field?.type ? field?.type.toString() : types[0].value,
                thickness: field?.thickness ? field?.thickness.toString() : '1',
                transparency: field?.transparency
                  ? field?.transparency.toString()
                  : '1',
                source:
                  global?.newConnectionId?.toString() ||
                  field?.source.toString() ||
                  userData?.user_last_source?.id.toString() ||
                  sources[0].id.toString(),
                field: field?.field ? field.field.toString() : '',
                y_scale_is_active: field?.y_scale_is_active,
                scale_min: field?.scale_min?.toString(),
                scale_max: field?.scale_max?.toString(),
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
              component={(rest) => (
                <Form
                  {...rest}
                  sources={sources}
                  widget={widget}
                  types={types}
                />
              )}
            />
          </VStack>
        </VStack>
      </Container>
  );
};

export { WidgetFieldDetailScreen };
