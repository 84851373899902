import axios from 'axios';
import axiosRetry from 'axios-retry';
import { add, format, parseJSON, sub } from 'date-fns';
import _ from 'lodash';
import Papa from 'papaparse';
import Constants from 'expo-constants';
import { tsDateFormat } from './dateTimeHelper';
import { getRoundedLimit } from './numberHelper';

const thingSpeakAPIUrl = Constants.manifest.extra.thingSpeakAPIUrl;

const instance = axios.create({
  baseURL: thingSpeakAPIUrl,
});
axiosRetry(instance, { retries: 3 });

const prepareEntryData = (obj = {}) => {
  for (const key in obj) {
    if (key.substring(0, 5) === 'field') {
      obj[key] = _.isNaN(obj[key]) || _.isNil(obj[key]) || _.isUndefined(obj[key]) ? null: _.toNumber(obj[key]);
    } else if (key === 'created_at') {
      obj[key] = parseJSON(obj[key]);
    }
  }
  return obj;
};

const fetchChannelData = async ({ channels, params }) => {
  const chs = _.uniqWith(channels, _.isEqual);
  const promises = chs
    .filter((channel) => channel.channelId)
    .map((channel) => {
      return instance.get(`/channels/${channel.channelId}/feeds.json`, {
        params: {
          api_key: channel.apiKey,
          results: 0,
          ...params,
        },
      });
    });
  const result = await Promise.allSettled(promises);
  const ret = result.reduce((acc, resp) => {
    if (resp.status === 'fulfilled') {
      return [...acc, resp.value.data?.channel];
    }
    return acc;
  }, []);
  return ret;
};

const fetchChannelLastEntry = async ({ channels, params }) => {
  const chs = _.uniqWith(channels, _.isEqual);
  const promises = chs
    .filter((channel) => channel.channelId)
    .map((channel) => {
      return instance.get(`/channels/${channel.channelId}/feeds/last.json`, {
        params: {
          api_key: channel.apiKey,
          results: 0,
          ...params,
        },
        extraParams: {
          id: channel.channelId,
        },
      });
    });
  const result = await Promise.allSettled(promises);
  const ret = result.reduce((acc, resp) => {
    if (resp.status === 'fulfilled') {
      const obj = {
        ...resp.value.config?.extraParams,
        ...prepareEntryData(resp.value.data),
      };
      return [...acc, obj];
    }
    return acc;
  }, []);
  return ret;
};

const fetchChannelDataAndLastEntry = async ({ channels }) => {
  const result = await Promise.all([
    fetchChannelData({ channels }),
    fetchChannelLastEntry({ channels }),
  ]);
  const ret = result[0].map((data) => {
    const lastEntry = result[1].find((f) => f.id === data.id);
    return {
      data,
      lastEntry,
    };
  });
  return ret;
};

const fetchChannelFeeds = async ({ channels, params }) => {
  const chs = _.uniqWith(channels, _.isEqual);
  const promises = chs
    .filter((channel) => channel.channelId)
    .map((channel) => {
      return instance.get(`/channels/${channel.channelId}/feeds.csv`, {
        params: {
          api_key: channel.apiKey,
          start: channel.start,
          end: channel.end,
          ...params,
        },
        extraParams: {
          id: channel.channelId,
        },
      });
    });
  const result = await Promise.allSettled(promises);
  const ret = result.reduce((acc, resp) => {
    if (resp.status === 'fulfilled') {
      const csv = Papa.parse(resp.value.data, {
        dynamicTyping: true,
        skipEmptyLines: true,
        header: true,
        transform: (v, k) => (k === 'created_at' ? parseJSON(v) : v),
      });
      const data = _.orderBy(csv.data, ['created_at'], ['asc']);
      const obj = {
        ...resp.value.config?.extraParams,
        data,
      };
      return [...acc, obj];
    }
    return acc;
  }, []);
  return ret;
};

const fetchChannelAgg = async ({ channels }) => {
  const result = await fetchChannelFeeds({
    channels,
    params: {
      results: 8000,
      minutes: 1440,
      start: null,
      end: null,
    },
  });
  const ret = result.map(({ id, data }) => {
    const fields = {};
    Array.from({ length: 8 }, (_, i) => i + 1).forEach((i) => {
      const min = _.minBy(data, `field${i}`);
      const max = _.maxBy(data, `field${i}`);
      let me = {
        min: min ? min[`field${i}`] : null,
        max: max ? max[`field${i}`] : null,
      };
      me = {
        ...me,
        roundedMin: getRoundedLimit(me.min, true),
        roundedMax: getRoundedLimit(me.max),
      };
      fields[`field${i}`] = me;
    });
    return {
      id,
      ...fields,
    };
  });
  return ret;
};

const iterateFetchChannelFeeds = async ({
  channels,
  options,
  onRead = async () => {},
}) => {
  const iterateCall = async (resolve, reject, channel, next) => {
    try {
      const reqCounter = (next.reqCounter || 0) + 1;
      const params = {
        results: _.min([next.recordsLimit, 8000]),
        start: format(next.start, tsDateFormat),
        end: format(next.end, tsDateFormat),
        timescale: options.timescale,
        round: options.round || 1,
      };
      const [{ data }] = await fetchChannelFeeds({
        channels: [channel],
        params,
      });
      await onRead({
        id: channel.channelId,
        data,
      });
      const minEntry = _.minBy(data, 'created_at');
      const lastEntry =
        reqCounter === 1 ? _.maxBy(data, 'created_at') : next.lastEntry;
      const ret = {
        ...next,
        reqCounter,
        recordsLimit: next.recordsLimit - data.length,
        end: sub(minEntry?.created_at || next.end, { seconds: 1 }),
        minEntry,
        lastEntry,
      };
      if (ret.start < ret.end && data.length > 0 && ret.recordsLimit > 0) {
        iterateCall(resolve, reject, channel, ret);
      } else {
        resolve({
          channel,
          ret,
        });
      }
    } catch (err) {
      reject(err);
    }
  };
  const chs = _.uniqWith(channels, _.isEqual);
  const promises = chs
    .filter((channel) => channel.channelId)
    .map((channel) => {
      return new Promise((resolve, reject) =>
        iterateCall(resolve, reject, channel, {
          id: channel.channelId,
          recordsLimit: options.recordsLimit || 1000,
          start: options.start,
          end: options.end,
        })
      );
    });
  const result = await Promise.allSettled(promises);
  const ret = result.reduce((acc, resp) => {
    if (resp.status === 'fulfilled') {
      return [...acc, resp.value];
    }
    return acc;
  }, []);
  return ret;
};

const retrieveChannelData = async ({ data }) => {
  const inChannels = [];
  data.forEach((record) => {
    if (record?.channel_id) {
      inChannels.push({
        channelId: record.channel_id,
        apiKey: record.read_api_key,
      });
    }
    if (record?.target_channel_id) {
      inChannels.push({
        channelId: record.target_channel_id,
        apiKey: record.target_read_api_key,
      });
    }
    const chFields = record.fields.map((field) => ({
      channelId: field.channel_id,
      apiKey: field.read_api_key,
    }));
    if (chFields.length > 0) {
      inChannels.push(...chFields);
    }
  });
  const outChannels = await (async () => {
    const results = await Promise.all([
      fetchChannelDataAndLastEntry({ channels: inChannels }),
      fetchChannelAgg({ channels: inChannels }),
    ]);
    const data = _.keyBy(results[0], ({ data }) => data.id);
    results[1].forEach((result) => (data[result.id].agg = result));
    return data;
  })();
  const ret = data.map((record) => {
    const fields = record.fields.map((field) => ({
      ...field,
      ...outChannels[field.channel_id],
    }));
    const target = outChannels[record.target_channel_id];
    return {
      ...record,
      ...outChannels[record.channel_id],
      fields,
      targetData: target?.data,
      targetLastEntry: target?.lastEntry,
    };
  });
  return ret;
};

const retrieveChannelFeeds = async ({ data }) => {
  const inChannels = [];
  data.forEach((record) => {
    if (record.isMounted) {
      if (record?.channel_id) {
        inChannels.push({
          channelId: record.channel_id,
          apiKey: record.read_api_key,
          start: record.lastEntry?.created_at
            ? format(
                add(record.lastEntry?.created_at, { seconds: 1 }),
                tsDateFormat
              )
            : null,
        });
      }
      if (record?.target_channel_id) {
        inChannels.push({
          channelId: record.target_channel_id,
          apiKey: record.target_read_api_key,
          start: record?.targetLastEntry
            ? format(
                add(record?.targetLastEntry.created_at, { seconds: 1 }),
                tsDateFormat
              )
            : null,
        });
      }
      const chFields = record.fields.map((field) => ({
        channelId: field.channel_id,
        apiKey: field.read_api_key,
        start: field?.lastEntry
          ? format(
              add(field?.lastEntry.created_at, { seconds: 1 }),
              tsDateFormat
            )
          : null,
      }));
      if (chFields.length > 0) {
        inChannels.push(...chFields);
      }
    }
  });
  const outChannels = await (async () => {
    const results = await Promise.all([
      fetchChannelFeeds({ channels: inChannels }),
      fetchChannelAgg({ channels: inChannels }),
    ]);
    const data = _.keyBy(results[0], 'id');
    results[1].forEach((result) => (data[result.id].agg = result));
    return data;
  })();
  const ret = data.map((record) => {
    if (record.isMounted) {
      const fields = record.fields.map((field) => {
        const feeds = outChannels[field.channel_id]?.data || [];
        const lastEntry = _.maxBy([...feeds, field.lastEntry], 'created_at');
        return {
          ...field,
          lastEntry,
          feeds,
        };
      });
      const feeds = outChannels[record.channel_id]?.data || [];
      const agg = outChannels[record.channel_id]?.agg;
      const lastEntry = _.maxBy([...feeds, record.lastEntry], 'created_at');
      const targetFeeds = outChannels[record.target_channel_id]?.data || [];
      const targetLastEntry = _.maxBy(
        [...targetFeeds, record.targetLastEntry],
        'created_at'
      );
      return {
        ...record,
        lastEntry,
        feeds,
        fields,
        targetFeeds,
        targetLastEntry,
        agg,
      };
    } else {
      return record;
    }
  });
  return ret;
};

export {
  fetchChannelData,
  fetchChannelLastEntry,
  fetchChannelDataAndLastEntry,
  fetchChannelFeeds,
  fetchChannelAgg,
  iterateFetchChannelFeeds,
  retrieveChannelData,
  retrieveChannelFeeds,
};
