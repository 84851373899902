import { ChartScale } from '@components/ChartScale';
import Feather from '@expo/vector-icons/Feather';
import { useGlobalContext } from '@hooks/useGlobalContext';
import { useLinkTo } from '@react-navigation/native';
import { Button, Divider, HStack, Icon, Text } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

function Header({
  panel,
  savePrefs,
  chartScaleRef,
  scales,
  currentScale,
  setCurrentScale,
  copyAccessURLToClipboard,
  onViewTypeChange,
  visibleScale
}) {
  const linkTo = useLinkTo();

  return (
    <HStack space={2}>
      {visibleScale && <ChartScale
        ref={chartScaleRef}
        savePrefs={savePrefs}
        scales={scales}
        currentScale={currentScale}
        setCurrentScale={setCurrentScale}
      />}
      {panel.is_owner && (
        <Button
          variant="outline"
          leftIcon={<Icon as={Feather} name="edit-3" />}
          onPress={onViewTypeChange}
        >
          Edit
        </Button>
      )}
      {panel.is_owner && !panel.is_private && (
        <Button
          variant="outline"
          leftIcon={<Icon as={Feather} name="link" />}
          onPress={copyAccessURLToClipboard}
        >
          Copy link
        </Button>
      )}
    </HStack>
  );
}

export { Header };
