import { AlertDialog, Button, useBreakpointValue } from 'native-base';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Platform } from 'react-native';

const styles = {
  top: {
    marginBottom: 'auto',
    marginTop: 0,
  },
  bottom: {
    marginBottom: 0,
    marginTop: 'auto',
  },
  left: {
    marginLeft: 0,
    marginRight: 'auto',
  },
  right: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  center: {},
};

const ModalDialog = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertBody, setAlertBody] = useState('');
  const [actionButton, setActionButton] = useState(null);
  const cancelRef = useRef(null);


  const handleClose = () => setShow(false);
  const onShow = (header, body, actButton) => {
    setShow(true);
    setAlertHeader(header);
    setAlertBody(body);
    setActionButton(actButton || <></>);
  };

  useImperativeHandle(ref, () => ({
    close: handleClose,
    show: onShow,
  }));

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={show}
      onClose={handleClose}
      size={Platform.OS === 'web' ? 'lg' : 'xl'}
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{alertHeader}</AlertDialog.Header>
        <AlertDialog.Body>{alertBody}</AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button
              variant="unstyled"
              colorScheme="coolGray"
              onPress={handleClose}
              ref={cancelRef}
            >
              Cancel
            </Button>
            {actionButton}
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
});

export { ModalDialog };