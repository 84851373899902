import { useFormikContext } from 'formik';
import { Button } from 'native-base';
import React from 'react';

function SubmitButton({ ...props }) {
  const { isSubmitting, handleSubmit } = useFormikContext();
  return (
    <Button
      colorScheme="primary"
      onPress={handleSubmit}
      {...props}
      isLoading={isSubmitting}
      isLoadingText="Saving..."
      flex="1"
      flexGrow={{ base: '1', lg: '2' }}
    >
      SAVE
    </Button>
  );
}

export { SubmitButton };
