import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import { apiClient } from './apiClient';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axios from 'axios';
import {Platform} from 'react-native';

const axiosInstance = axios.create({
  baseURL: Constants.manifest.extra.apiBaseURL,
});

const login = (username, password) =>
  apiClient.post(
    '/o/token/',
    {
      grant_type: 'password',
      username: username.trim(),
      password,
    },
    {
      auth: {
        username: Constants.manifest.extra.clientId,
        password: Constants.manifest.extra.clientSecret,
      },
      skipAuthRefresh: true,
    }
  );

const logoff = async () => {
  global.loginSocial = null;
  const access_token = await AsyncStorage.getItem('@access_token');
  try {
    apiClient.post(
      '/o/revoke_token/',
      {
        token: access_token,
      },
      {
        auth: {
          username: Constants.manifest.extra.clientId,
          password: Constants.manifest.extra.clientSecret,
        },
        skipAuthRefresh: true,
      }
    );
  } catch (err) {
    throw err;
  }
  await AsyncStorage.removeItem('@access_token');
  await AsyncStorage.removeItem('@refresh_token');
};

const refreshToken = async () => {
  const refresh_token = await AsyncStorage.getItem('@refresh_token');
  if (!refresh_token) return Promise.reject();
  try {
    const { data } = await apiClient.post(
      '/o/token/',
      {
        grant_type: 'refresh_token',
        refresh_token: refresh_token,
      },
      {
        auth: {
          username: Constants.manifest.extra.clientId,
          password: Constants.manifest.extra.clientSecret,
        },
        skipAuthRefresh: true,
      }
    );
    await AsyncStorage.setItem('@access_token', data.access_token);
    await AsyncStorage.setItem('@refresh_token', data.refresh_token);
    return Promise.resolve();
  } catch (err) {
    await AsyncStorage.removeItem('@access_token');
    await AsyncStorage.removeItem('@refresh_token');
    throw err;
  }
};
createAuthRefreshInterceptor(apiClient, refreshToken);

const registerUser = (params) =>
  axiosInstance.post('register', params).catch((error) => {
    return Promise.reject(error);
  });

const passwordresetUser = (params) => {
  return axiosInstance.post('passwordreset', params).catch((error) => {
    return Promise.reject(error);
  });
};
const passwordresetcompUser = (params) =>
  axiosInstance.post('passwordresetcomp', params).catch((error) => {
    return Promise.reject(error);
  });

const passwordresettknUser = (params) => {
  return axiosInstance
    .get('passwordresettkn', { params: params })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const oauthorize = async () => {
  try {
    if (Platform.OS === "web"){
      let seracParams = "";      
      seracParams = window.location.search ;
      const access_token = await AsyncStorage.getItem('@access_token');
      const apiBaseURL = Constants.manifest.extra.apiBaseURL
      if(access_token){
        seracParams +=`&tkn=${access_token}`
      }
      window.location = `${apiBaseURL}alexa/authorize${seracParams}`       

      return Promise.resolve();
    }
    return Promise.reject();
  } catch (error) {
    return Promise.reject(error);
  }
}
const getUserPicture = (token, backend) => {
  if (backend === 'google') {
    return axiosInstance
    .get(
      'https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=' +
        token
    )
    .then((resp) => {
      return resp.data.picture;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  } else if (backend === 'facebook') {
    return axiosInstance
    .get(
      'https://graph.facebook.com/v2.3/me?alt=json&fields=picture&access_token=' +
        token
    )
    .then(async (resp) => {
      return resp.data.picture.data.url;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  } else {
    return null
  }
};

const convertToken = ({ access_token, backend }) =>
  axiosInstance
    .post('/auth/convert-token', {
      grant_type: 'convert_token',
      backend: backend == 'google' ? 'google-oauth2' : backend,
      client_id: Constants.manifest.extra.clientId,
      client_secret: Constants.manifest.extra.clientSecret,
      token: access_token,
    })
    .catch((error) => {
      console.log(error.response.data);
      return Promise.reject(error);
    });

const updateAccountUser = (params) => {
  return apiClient.put('/account', params).catch((error) => {
    return Promise.reject(error);
  });
};

const accountPasswordUser = (params) => {
  return apiClient.put('/accountpassword', params).catch((error) => {
    return Promise.reject(error);
  });
};

const removeAccountUser = (params) => {
  return apiClient.delete('/account', params).catch((error) => {
    return Promise.reject(error);
  });
};

const getUser = async () => {
  const access_token = await AsyncStorage.getItem('@access_token');
  const getFullName = (user) => {
    let fullName = user?.first_name;
    fullName = fullName ? fullName + ' ' + user?.last_name : user.username;
    return fullName;
  };
  const getLetter = (user) => {
    let fullName = getFullName(user).split(' ');
    fullName =
      fullName.length > 1
        ? fullName[0]?.charAt(0) + fullName[1].charAt(0)
        : fullName[0]?.charAt(0) + fullName[0]?.charAt(1);
    return fullName.toUpperCase();
  };
  return apiClient
    .get(
      '/user',
      {
        token: access_token,
      },
      {
        auth: {
          username: Constants.manifest.extra.clientId,
          password: Constants.manifest.extra.clientSecret,
        },
      }
    )
    .then(async (resp) => {
      let user = resp.data;
      user.fullName = getFullName(user);
      user.letter = getLetter(user);
      return user;
    })
    .catch(async () => {
      return {};
    });
};

export {
  login,
  logoff,
  refreshToken,
  convertToken,
  registerUser,
  updateAccountUser,
  accountPasswordUser,
  removeAccountUser,
  getUser,
  passwordresetUser,
  passwordresetcompUser,
  passwordresettknUser,
  getUserPicture,
  oauthorize
};
