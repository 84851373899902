import { GridStack } from 'gridstack';
import { isNil, orderBy, toInteger } from 'lodash';
import React, { useEffect, useRef } from 'react';

const useGridStack = (items, options, config) => {
  const gridEl = useRef(null);
  const gridRef = useRef(null);

  useEffect(() => {
    gridRef.current =
      gridRef.current || GridStack.init(options, gridEl.current);
    const grid = gridRef.current;
    if (!options.staticGrid) {
      grid.off(
        'added removed change dragstart dragstop resizestart resizestop'
      );
      grid.on('added removed change', config.onChange);
      grid.on('dragstart resizestart', config.onResizeStart);
      grid.on('dragstop resizestop', config.onResizeStop);
    }
  }, []);

  useEffect(() => {
    if (!options.staticGrid && gridRef.current && items.length > 0) {
      const { nodes } = gridRef.current.engine;
      for (const item of items) {
        if (!nodes.some((node) => toInteger(node.id) === item.id)) {
          makeWidget(item.id);
        }
      }
    }
  }, [items]);

  const getEl = (id) => document.querySelector(`div[gs-id="${id}"]`);

  const serializeData = () => {
    const values = [];
    const serializedData = orderBy(gridRef.current.save(), ['x', 'y']);
    const sameSortOrder = !items.some(
      (item) => item.mobile_sort_order !== item.web_sort_order
    );
    const gridItemFieldName =
      config.currentViewType === 'M' ? 'mobile_grid_item' : 'web_grid_item';
    for (const [index, data] of serializedData.entries()) {
      const item = items.find((item) => item.id === toInteger(data.id));
      if (item) {
        values.push({
          ...item,
          web_sort_order: index + 1,
          mobile_sort_order: sameSortOrder ? index + 1 : item.mobile_sort_order,
          [gridItemFieldName]: {
            x: data.x,
            y: data.y,
            h: data.h,
            w: data.w,
            configured: true,
          },
        });
      }
    }
    return values;
  };

  const addWidget = (id) => gridRef.current.addWidget(getEl(id));

  const removeWidget = (id, removeDOM = false) =>
    gridRef.current.removeWidget(getEl(id), removeDOM);

  const makeWidget = (id) => gridRef.current.makeWidget(getEl(id));

  return {
    gridEl,
    gridRef,
    serializeData,
    addWidget,
    removeWidget,
    makeWidget,
  };
};

export { useGridStack };
