import { useRoute } from '@react-navigation/native';
import { isEditMode } from '@utils/screenHelper';
import { Button, Menu } from 'native-base';
import React from 'react';

function DeleteItemMenu({ alertDialog, onPress, route, ...props }) {
  return (
    <Menu.Item
      isDisabled={!isEditMode(route)}
      onPress={() => {
        alertDialog.current.show(
          'Delete',
          'Are you sure that you want to delete this record?',
          <Button
            colorScheme="danger"
            onPress={() => {
              alertDialog.current.close();
              onPress();
            }}
          >
            Delete
          </Button>
        );
      }}
      {...props}
    >
      Delete
    </Menu.Item>
  );
}

export { DeleteItemMenu };
