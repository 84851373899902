import { ChartWrapper } from '@components/ChartWrapper';
import React from 'react';
import ReactFC from 'react-fusioncharts';

const SimpleCylinder = ({ forceUpdate, dataSource, size }) => {
  return (
    <ChartWrapper
      render={({ adjustedHeight }) => {
        return (
          <div className="fc-container">
            <ReactFC
              key={forceUpdate}
              type="cylinder"
              width="100%"
              height={adjustedHeight}
              dataFormat="json"
              dataSource={{
                ...dataSource,
                chart: {
                  ...dataSource.chart,
                  bgAlpha: '0',
                },
              }}
            />
          </div>
        );
      }}
    />
  );
};

export { SimpleCylinder };
