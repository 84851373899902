import Ionicons from '@expo/vector-icons/Ionicons';
import { differenceInMinutes, format } from 'date-fns';
import { cloneDeep } from 'lodash';
import { Button, Divider, HStack, Icon, Menu } from 'native-base';
import React, { forwardRef, Fragment, useRef } from 'react';
import { ModalForm } from './form';

const ChartScale = forwardRef(
  ({ savePrefs, scales, currentScale, setCurrentScale }, ref) => {
    const customScale = scales[0];
    const modalRef = useRef(null);

    const handleScaleChange = (selectedScale) => {
      savePrefs({
        selectedScale,
      });
      setCurrentScale(selectedScale);
    };

    const handleCustomChange = (from, to) => {
      const deep = cloneDeep(customScale);
      const interval = differenceInMinutes(to, from);
      const selectedScale = {
        ...deep,
        from,
        to,
      };
      scales.forEach((scale) => {
        if (scale.interval <= interval) {
          selectedScale.timescale = scale.timescale;
        }
      });
      savePrefs({
        selectedScale,
      });
      setCurrentScale(selectedScale);
      modalRef.current.onClose();
    };

    return (
      <HStack>
        <Menu
          w="160"
          placement="bottom right"
          trigger={(triggerProps) => {
            return (
              <HStack
                space="2"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  alignSelf="center"
                  variant="ghost"
                  _text={{
                    fontWeight: 'medium',
                  }}
                  rightIcon={
                    <Icon as={Ionicons} name="calendar-sharp" size="5" />
                  }
                  {...triggerProps}
                >
                  {currentScale.id === customScale.id
                    ? // prettier-ignore
                      `${format(currentScale.from, 'MMMM dd, yyyy')} - ${format(currentScale.to, 'MMMM dd, yyyy')}`
                    : currentScale.name}
                </Button>
              </HStack>
            );
          }}
        >
          <Fragment>
            {scales
              .filter((scale) => scale.id !== customScale.id)
              .map((scale) => (
                <Menu.Item
                  key={scale.id}
                  onPress={() => handleScaleChange(scale)}
                >
                  {scale.name}
                </Menu.Item>
              ))}
            <Divider my="1" w="100%" />
            <Menu.Item
              key={customScale.id}
              onPress={() => modalRef.current.onOpen()}
            >
              {customScale.name}
            </Menu.Item>
          </Fragment>
        </Menu>
        <ModalForm
          ref={modalRef}
          currentScale={currentScale}
          onCustomChange={handleCustomChange}
        />
      </HStack>
    );
  }
);

export { ChartScale };
