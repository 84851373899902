import { customMessages } from '@utils/customMessages';
import { LoadingIndicator } from '@components/LoadingIndicator';

import { Container } from '@components/Container';
import { useAuth } from '@hooks/useAuth';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  FormControl,
  Heading,
  Input,
  Link,
  VStack,
  View,
  useToast
} from 'native-base';
import {
  bodyBgColor
} from '@utils/customTheme';
import * as Yup from 'yup';

import React from 'react';
import { useLinkTo } from '@react-navigation/native';

const validationSchema = Yup.object().shape({
  password: Yup.string().required(customMessages.isRequiredField),
  password2: Yup.string().required(customMessages.isRequiredField),
  email: Yup.string().required(customMessages.isRequiredField),
});

function ForgetPasswordScreen({ route, navigation }) {
  const linkTo = useLinkTo();
  const toast = useToast();
  const { params } = route;
  const { passwordreset, passwordresetcomp, passwordresettkn } = useAuth();
  const [newPass, setNewPass] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidTkn, setIsValidTkn] = useState(true);

  useEffect(() => {
    const checkresettkn = async () => {
      setNewPass(true);
      await passwordresettkn(params).catch((error) => {
        setIsValidTkn(false);
        setErrors(error.response.data);
        setNewPass(false);
      });
      setIsLoading(false);
    };

    if (params?.tkn) {
      if (isLoading) checkresettkn();
    } else {
      setIsLoading(false);
    }
  }, [isLoading]);

  const clearErrors = async (field) => {
    if (errors[field]) {
      const NewErrors = { ...errors };
      delete NewErrors[field];
      setErrors(NewErrors);
    }
    toast.closeAll();
  };

  const handleFormSubmit = async () => {
    setSubmitting(true);
    let values = {
      password: password,
      password2: password2,
      email: email,
      ...params,
    };
    if (newPass) {
      return await passwordresetcomp(values)
        .then(() => {
          toast.show({
            placement: 'top',

            render: () => {
              return (
                <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
                  You password change successfully.Please login to continue.
                </Box>
              );
            },
          });

          setSubmitting(false);
        })
        .catch((error) => {
          toast.show({
            title: 'Reset password Failed',
            placement: 'top',
            description: error.response.data.error_description,
          });
          setErrors(error.response.data);
          setSubmitting(false);
        });
    } else {
      return await passwordreset(values)
        .then((resp) => {
          toast.show({
            placement: 'top',

            render: () => {
              return (
                <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
                  {resp.data.message}
                </Box>
              );
            },
          });

          setSubmitting(false);
        })
        .catch((error) => {
          toast.show({
            title: 'Reset password Failed',
            placement: 'top',
            description: error.response.data.error_description,
          });
          setErrors(error.response.data);
          setSubmitting(false);
        });
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
      <View
        flex="1"
        _light={{ bg: 'bodyBgColorLight' }}
        _dark={{ bg: 'bodyBgColorDark' }}
      >
        <Center w="100%">
          {!isValidTkn && (
            <Box bg="red.500" px="2" py="1" rounded="sm" mb={5}>
              {errors.error}
            </Box>
          )}
          <Box p="2" py="8" w="90%" maxW="290">
            <Heading size="lg" fontWeight="600">
              Welcome!
            </Heading>
            <Heading mt="1" mb="4" fontWeight="medium" size="xs">
              {newPass ? 'Reset password' : 'Forget password'} to continue!
            </Heading>
            <VStack space={3} mt="1">
              {!newPass && (
                <FormControl isInvalid={'email' in errors}>
                  <FormControl.Label>E-mail</FormControl.Label>
                  <Input
                    placeholder="email"
                    value={email}
                    autoCapitalize="none"
                    autoComplete="off"
                    size="md"
                    onChangeText={(text) => setEmail(text)}
                    onFocus={() => clearErrors('email')}
                  />
                  <FormControl.ErrorMessage>
                    {errors.email}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
              {newPass && (
                <FormControl isInvalid={'password' in errors}>
                  <FormControl.Label>Password</FormControl.Label>
                  <Input
                    placeholder="Password"
                    type="password"
                    value={password}
                    size="md"
                    onChangeText={(text) => setPassword(text)}
                    onFocus={() => clearErrors('password')}
                  />
                  <FormControl.ErrorMessage>
                    {errors.password}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
              {newPass && (
                <FormControl isInvalid={'password2' in errors}>
                  <FormControl.Label>Confirm Password</FormControl.Label>
                  <Input
                    placeholder="Password"
                    type="password"
                    value={password2}
                    size="md"
                    onChangeText={(text) => setPassword2(text)}
                    onFocus={() => clearErrors('password2')}
                  />
                  <FormControl.ErrorMessage>
                    {errors.password2}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
              <Link
                _text={{
                  fontSize: 'xs',
                  fontWeight: '500',
                  color: 'primary.500',
                }}
                alignSelf="flex-end"
                mt="1"
                onPress={() => linkTo('/signin')}
              >
                Back sign in
              </Link>

              <Button mt="2" onPress={handleFormSubmit} isLoading={submitting}>
                {newPass ? 'Reset password' : 'Forget password'}
              </Button>
            </VStack>
          </Box>
        </Center>
      </View>
    </Container>
  );
}

export { ForgetPasswordScreen };
