import { useComponentSize } from '@hooks/useComponentSize';
import fitty from 'fitty';
import { Text } from 'native-base';
import React, { memo, useEffect, useRef } from 'react';

function FitText({ txt, fontFamily, color, textAlign, maxSize = 16, style }) {
  const [size, onLayout] = useComponentSize();
  const txtRef = useRef(null);
  const fitties = useRef(null);

  useEffect(() => {
    fitties.current =
      fitties.current ||
      fitty(txtRef.current, {
        minSize: 8,
        maxSize,
        multiLine: false,
      });
    fitties.current.fit();
  }, [size, txt]);

  return (
    <Text
      fontFamily={fontFamily}
      color={color}
      textAlign={textAlign}
      noOfLines={1}
      w="100%"
      onLayout={onLayout}
      fontSize={maxSize}
      style={style}
    >
      <div ref={txtRef}>{txt}</div>
    </Text>
  );
}

const MemoizedFitText = memo(FitText);

export { MemoizedFitText as FitText };
