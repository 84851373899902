import { Container } from '@components/Container';
import { LoadingIndicator } from '@components/LoadingIndicator';
import Feather from '@expo/vector-icons/Feather';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useGlobalContext } from '@hooks/useGlobalContext';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { bodyBgColor } from '@utils/customTheme';
import { createPanel, getPanels } from '@utils/panel';
import { Platform } from 'expo-modules-core';
import {
  Box,
  Fab,

  HStack,
  Hidden,
  Icon,
  IconButton,
  Text,
  VStack,
  useBreakpointValue
} from 'native-base';
import { FlashList } from "@shopify/flash-list";
import React, { useEffect, useRef, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { Header } from './header';
import { NewPanelDialog } from './NewPanelDialog';
import AntDesign from '@expo/vector-icons/AntDesign';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';


const PanelList = ({ route, navigation }) => {
  const linkTo = useLinkTo();
  const isFocused = useIsFocused();
  const [panels, setPanels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const newPanelDialogRef = useRef(null);
  const { progressDialog, setCurrentViewType } = useGlobalContext();
  const platformType = Platform.OS === 'web' ? 'W' : 'M';
  const isLargeScreen = useBreakpointValue({
    md: true,
  }) & Platform.OS === 'web';
  
  useEffect(() => {
    if (isFocused) {
      getPanels().then((resp) => {
        const panels_data = resp.data;
        setPanels(
          Platform.OS === 'web'
            ? panels_data
            : panels_data.filter((panel) => ['B', 'M'].includes(panel.type))
        );
        setIsLoading(false);
      });
    }
  }, [isFocused]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const ListEmptyComponent = () => (
    <Text textAlign="center" color="secondary.300" mt={{ base: 6, md: 4 }}>
      You don't have any panel yet
    </Text>
  );

  const handlePanelAdd = async (viewTypes) => {
    newPanelDialogRef.current.close();
    progressDialog.current.show();
    const resp = await createPanel({ name: 'New panel', viewTypes });
    progressDialog.current.close();
    const { data: newPanel } = resp;
    setCurrentViewType(viewTypes.length > 1 ? platformType : viewTypes[0]);
    linkTo(`/panels/${newPanel.id}/widgets`);
  };

  const handlePanelPreview = (panel) => {
    setCurrentViewType(Platform.OS === 'web' ? 'W' : 'M');
    linkTo(`/panels/${panel.id}/preview`);
  };

  return (
    <>
      <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
        <VStack>
        { Platform.OS === 'web' && (<Hidden till="md">
            <HStack py={2} alignItems="center" justifyContent="space-between">
              <Header
                onNewPanel={() => newPanelDialogRef.current.show()}
                isSubmitting={isSubmitting}
              />
            </HStack>
          </Hidden>)
        }
          <VStack>
            <FlashList
              data={panels}            
              estimatedItemSize={20}
              keyExtractor={(item) => item.id}
              ListEmptyComponent={ListEmptyComponent}
              renderItem={({ item }) => (
                <HStack
                  px="1"
                  mx="1.5"
                  borderBottomWidth={{ base: 0.5, md: 1 }}
                  _dark={{ borderBottomColor: '#353942' }}
                  borderBottomColor="gray.300"
                  alignItems="center"
                >
                  <Box flex={1}>
                    <TouchableOpacity onPress={() => handlePanelPreview(item)}>
                      <Text fontSize="sm" py={4}>
                        {item.name}
                      </Text>
                    </TouchableOpacity>
                  </Box>
                  {Platform.OS === 'web' && (
                    <HStack>
                      {['B', 'W'].includes(item?.type) && (
                        <IconButton
                          icon={
                            <Icon
                              as={MaterialCommunityIcons}
                              name="monitor"
                              size="lg"
                              _dark={{ color: 'warmGray.50' }}
                            />
                          }
                          onPressOut={() => {
                            setCurrentViewType('W');
                            linkTo(`/panels/${item.id}/preview`);
                          }}
                        />
                      )}
                      {['B', 'M'].includes(item?.type) && (
                        <IconButton
                          icon={
                            <Icon
                              as={AntDesign}
                              name="mobile1"
                              size="lg"
                              _dark={{ color: 'warmGray.50' }}
                            />
                          }
                          onPressOut={() => {
                            setCurrentViewType('M');
                            linkTo(`/panels/${item.id}/preview`);
                          }}
                        />
                      )}
                    </HStack>
                  )}
                </HStack>
              )}
            />
          </VStack>
        </VStack>
      </Container>
      { Platform.OS !== 'web' && (
        <Fab
          renderInPortal={false}
          icon={<Icon as={Feather} name="plus" size="lg" />}
          onPress={() => linkTo('/panels/wizard')}
        />)
      }
      { !isLargeScreen &&  Platform.OS === 'web' && (
          <Fab
            renderInPortal={false}
            icon={<Icon as={Feather} name="plus" size="lg" />}
            onPress={() =>  newPanelDialogRef.current.show()}
            isSubmitting={isSubmitting}
          />
        )
      }
      <NewPanelDialog ref={newPanelDialogRef} onConfirm={handlePanelAdd} />
    </>
  );
};

export { PanelList };
