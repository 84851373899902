import { BottomBar } from '@components/Container';
import { DiscardButton } from '@components/DiscardButton';
import { SubmitButton } from '@components/SubmitButton';
import {
  FormControl,
  HStack,
  Input,
  Select,
  Text,
  VStack,
  WarningOutlineIcon,
} from 'native-base';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

function Form({
  values,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  handleSubmit,
  origins,
}) {
  const debounced = useDebouncedCallback((setFieldValue, field, text) => {
    setFieldValue(field, text);
  }, 200);

  return (
    <>
      <VStack space={3}>
        <FormControl isRequired isInvalid={'origin' in errors}>
          <FormControl.Label>Origin</FormControl.Label>
          <Select
            placeholder="Please select..."
            onValueChange={handleChange('origin')}
            onBlur={handleBlur('origin')}
            selectedValue={values.origin}
          >
            {origins.map((o) => (
              <Select.Item label={o.name} key={o.id} value={o.id.toString()} />
            ))}
          </Select>
          <FormControl.ErrorMessage>{errors.origin}</FormControl.ErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={'channel_id' in errors}>
          <FormControl.Label>Channel ID</FormControl.Label>
          <Input
            onChangeText={(text) =>
              debounced(setFieldValue, 'channel_id', text)
            }
            onBlur={handleBlur('channel_id')}
            defaultValue={values.channel_id}
            maxLength={32}
          />
          <FormControl.ErrorMessage>
            {errors.channel_id}
          </FormControl.ErrorMessage>
        </FormControl>
        <FormControl isInvalid={'read_api_key' in errors}>
          <FormControl.Label>Read API Key</FormControl.Label>
          <Input
            onChangeText={(text) =>
              debounced(setFieldValue, 'read_api_key', text)
            }
            onBlur={handleBlur('read_api_key')}
            defaultValue={values.read_api_key}
            maxLength={64}
          />
          <FormControl.ErrorMessage>
            {errors.read_api_key}
          </FormControl.ErrorMessage>
        </FormControl>
        <FormControl>
          <FormControl.Label>Write API Key</FormControl.Label>
          <Input
            onChangeText={handleChange('write_api_key')}
            onBlur={handleBlur('write_api_key')}
            value={values.write_api_key}
            maxLength={64}
          />
          {values.channel_id && !values.write_api_key ? (
            <FormControl.HelperText>
              <HStack space="1" alignItems="center">
                <WarningOutlineIcon
                  _dark={{ color: 'warning.400' }}
                  _light={{ color: 'warning.700' }}
                  size="xs"
                />
                <Text
                  _dark={{ color: 'warning.400' }}
                  _light={{ color: 'warning.700' }}
                  fontSize="xs"
                >
                  {
                    'In order to allow ThingSpeak to write fields values, it’s mandatory to provide Write API Key'
                  }
                </Text>
              </HStack>
            </FormControl.HelperText>
          ) : null}
        </FormControl>
        <FormControl>
          <FormControl.Label>Name</FormControl.Label>
          <Input
            onChangeText={handleChange('channel_name')}
            onBlur={handleBlur('channel_name')}
            value={values.channel_name}
            isDisabled
          />
        </FormControl>
        <FormControl isRequired isInvalid={'display_name' in errors}>
          <FormControl.Label>Display name</FormControl.Label>
          <Input
            onChangeText={handleChange('display_name')}
            onBlur={handleBlur('display_name')}
            value={values.display_name}
            maxLength={64}
            onSubmitEditing={handleSubmit}
          />
          <FormControl.ErrorMessage>
            {errors.display_name}
          </FormControl.ErrorMessage>
        </FormControl>
      </VStack>
      <BottomBar>
        <DiscardButton />
        <SubmitButton />
      </BottomBar>
    </>
  );
}

export { Form };
