import { Ionicons } from '@expo/vector-icons';
import { useGlobalContext } from '@hooks/useGlobalContext';
import {
  Box,
  Button,
  Center,
  FormControl,
  HStack,
  Input, 
  Text,
  VStack,
  View,
  useToast,
  Hidden,
} from 'native-base';
import { useState } from 'react';
import { StyleSheet } from 'react-native';
import { useAuth } from '@hooks/useAuth';
import { Header } from './header';
import { Container } from '@components/Container';
import { customMessages } from '@utils/customMessages';
import * as Yup from 'yup';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { useCallback } from 'react';
import { bodyBgColor } from '@utils/customTheme';
import sleep from 'sleep-promise';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required(customMessages.isRequiredField),
  last_name: Yup.string().required(customMessages.isRequiredField),
});

function AccountScreen({ navigation, route }) {
  const { userData, account, signout, removeaccount, provider } = useAuth();
  const linkTo = useLinkTo();
  const { alertDialog } = useGlobalContext();
  const toast = useToast();
  const [first_name, setFirst_name] = useState(userData?.first_name);
  const [last_name, setLast_name] = useState(userData?.last_name);
  const [username, setUsername] = useState(userData?.username);
  const [email, setEmail] = useState(userData?.email);
  const [id, setId] = useState(userData?.id);
  const [errors, setErrors] = useState({});
  const [submitting1, setSubmitting1] = useState(false);
  const [submitting2, setSubmitting2] = useState(false);
  const [submitting3, setSubmitting3] = useState(false);
  const [submitting4, setSubmitting4] = useState(false);

  const clearErrors = async (field) => {
    if (errors[field]) {
      const NewErrors = { ...errors };
      delete NewErrors[field];
      setErrors(NewErrors);
    }
    toast.closeAll();
  };

  const handleDiscardButton = async () => {
    navigation.goBack();
  };

  const handleButtonSave = async () => {
    setSubmitting1(true);
    let values = {
      id: id,
      first_name: first_name,
      last_name: last_name,
      username: username
    };

    return await account(values)
      .then(() => {
        toast.show({
          placement: 'top',

          render: () => {
            return (
              <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
                You have been updated successfully.
              </Box>
            );
          },
        });

        setSubmitting1(false);
      })
      .catch((error) => {
        toast.show({
          title: 'Account Update Failed',
          placement: 'top',
        });
        setSubmitting1(false);
      });
  };

  const handleButtonRemoveAccount = async () => {
    alertDialog.current.show(
      'Remove account',
      `If you remove this account, all your data will be permanently deleted. Do you want to proceed?`,
      <Button
        onPress={() => {
          alertDialog.current.close();
          removeAccount();
          sleep(100)
            .then(signout)
            .then(() => linkTo('/signin'));
        }}
      >
        Yes
      </Button>
    );
  };

  const removeAccount = async () => {
    setSubmitting4(true);
    let values = {
      id: id,
    };

    return await removeaccount(values)
      .then(() => {
        toast.show({
          placement: 'top',

          render: () => {
            return (
              <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
                <Text>Your account </Text>
                {provider && <Text>{email}</Text>}
                {!provider && (
                  <Text>
                    {username} ({email})
                  </Text>
                )}
                has been successfully removed.
              </Box>
            );
          },
        });
        setSubmitting4(false);
      })
      .catch((error) => {
        toast.show({
          title: 'Remove Account Failed',
          placement: 'top',
        });
        setSubmitting4(false);
      });
  };

  useFocusEffect(
    useCallback(() => {
      setSubmitting1(false);
      setSubmitting2(false);
      setSubmitting3(false);
      setSubmitting4(false);
    }, [navigation])
  );

  const layoutLoginSocial = () => {
    return (
      <>
        <Center>
          <Text style={{ width: '390px', textAlign: 'center' }}>
            Account linked to{' '}
            {provider.includes('google') ? 'Google' : provider.includes('facebook') ? 'Facebook' : ''} for
            the user {userData?.fullName} ({userData?.email})
          </Text>
        </Center>
        <Box style={{ height: 30 }}></Box>
      </>
    );
  };

  const layoutNoLoginSocial = () => {
    return (
      <>
        <Box w="290">
          <VStack space={3} mt="1">
            <FormControl isInvalid={'first_name' in errors}>
              <FormControl.Label>First name</FormControl.Label>
              <Input
                placeholder="First name"
                value={first_name}
                autoCapitalize="none"
                autoComplete="off"
                size="md"
                onChangeText={(text) => setFirst_name(text)}
                onFocus={() => clearErrors('first_name')}
              />
              <FormControl.ErrorMessage>
                {errors.first_name}
              </FormControl.ErrorMessage>
            </FormControl>
            <FormControl isInvalid={'last_name' in errors}>
              <FormControl.Label>Last name</FormControl.Label>
              <Input
                placeholder="Last name"
                value={last_name}
                autoCapitalize="none"
                autoComplete="off"
                size="md"
                onChangeText={(text) => setLast_name(text)}
                onFocus={() => clearErrors('last_name')}
              />
              <FormControl.ErrorMessage>
                {errors.last_name}
              </FormControl.ErrorMessage>
            </FormControl>
            <FormControl isInvalid={'username' in errors}>
              <FormControl.Label>Username</FormControl.Label>
              <Input
                placeholder="Username"
                value={username}
                autoCapitalize="none"
                autoComplete="off"
                size="md"
                onFocus={() => clearErrors('username')}
              />
              <FormControl.ErrorMessage>
                {errors.username}
              </FormControl.ErrorMessage>
            </FormControl>
            <FormControl isInvalid={'email' in errors}>
              <FormControl.Label>E-mail</FormControl.Label>
              <Input
                placeholder="email"
                value={email}
                autoCapitalize="none"
                autoComplete="off"
                size="md"
                onFocus={() => clearErrors('email')}
              />
              <FormControl.ErrorMessage>
                {errors.email}
              </FormControl.ErrorMessage>
            </FormControl>
          </VStack>
        </Box>
        <Box style={{ height: 10 }}></Box>

        <View style={{ flexDirection: 'row' }}>
          <View width={110}>
            <Button
              leftIcon={
                <Ionicons name="md-save-outline" size={22} color="white" />
              }
              width={100}
              mt="2"
              onPress={handleButtonSave}
              isLoading={submitting1}
            >
              Save
            </Button>
          </View>
          <View>
            <Button
              leftIcon={
                <Ionicons
                  name="arrow-back-circle-outline"
                  size={22}
                  color="white"
                />
              }
              colorScheme="coolGray"
              width={100}
              mt="2"
              onPress={handleDiscardButton}
              isLoading={submitting2}
            >
              Discard
            </Button>
          </View>
        </View>
        <Button
          width={160}
          colorScheme="coolGray"
          mt="2"
          onPress={() => linkTo('/AccountPassword')}
          isLoading={submitting3}
        >
          Change password
        </Button>
      </>
    );
  };

  return (

      <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
        <VStack>
          <Hidden till="md">
            <HStack py={2} alignItems="center" justifyContent="space-between">
              <Header />
            </HStack>
          </Hidden>
          <VStack
            _light={{ bg: { md: 'containerBgColorLight' } }}
            _dark={{ bg: { md: 'containerBgColorDark' } }}
            shadow={{ md: '1' }}
            borderRadius={{ md: '4' }}
            py={{ base: 2, md: 5 }}
            px={{ base: 2, md: 5 }}
            mb={{ base: 0, md: 5 }}
          >
            <Center>
              <Box w="290">
                <VStack space={3} mt="1">
                  <View
                    flex={1}
                    p={{ md: 2 }}
                    contentContainerStyle={{ alignItems: 'center' }}
                  >
                    <Center flex={1}>
                      {provider && layoutLoginSocial()}
                      {!provider && layoutNoLoginSocial()}

                      <Button
                        width={160}
                        leftIcon={
                          <Ionicons name="md-trash" size={22} color="red" />
                        }
                        style={styles.button}
                        mt="2"
                        onPress={handleButtonRemoveAccount}
                        isLoading={submitting4}
                      >
                        Remove account
                      </Button>
                    </Center>
                  </View>
                </VStack>
              </Box>
            </Center>
          </VStack>
        </VStack>
      </Container>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#222',
  },
});

export { AccountScreen };
