import { ChartWrapper } from '@components/ChartWrapper';
import React from 'react';
import ReactFC from 'react-fusioncharts';
import { View } from 'react-native';

const SimpleLinear = ({ forceUpdate, dataSource }) => {
  return (
    <ChartWrapper
      render={({ adjustedHeight }) => {
        return (
          <div
            className="fc-container"
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 13,
            }}
          >
            <ReactFC
              key={forceUpdate}
              type="vbullet" // Usando o tipo "vbullet" para criar um efeito visual semelhante ao "hlineargauge" (não existe nativamente no Fusion Charts um "vlineargauge")
              width={105}
              height={adjustedHeight - 20}
              dataFormat="json"
              dataSource={{
                ...dataSource,
                chart: {
                  ...dataSource.chart,
                },
              }}
            />
          </div>
        );
      }}
    />
  );
};

export { SimpleLinear };
