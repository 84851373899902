import { isBlank } from '@utils/stringHelper';
import { Switch as NBSwitch, Text, VStack } from 'native-base';
import React, { memo } from 'react';
import { StyleSheet } from 'react-native';

const Switch = memo(
  ({ widget, currentValue, onCurrentValueChange, isDisabled }) => {
    const { bool_value0, bool_value1 } = widget;

    const handleValueChange = (boolValue) => {
      onCurrentValueChange(boolValue ? 1 : 0);
    };

    const BoolValueText = () => {
      const boolValue = currentValue > 0 ? bool_value1 : bool_value0;
      if (!isBlank(boolValue)) {
        return (
          <Text
            pb="2"
            bottom="0"
            isTruncated
            maxW="80%"
            color={currentValue > 0 ? 'primary.500' : 'coolGray.400'}
            fontWeight="medium"
            fontSize="sm"
            style={styles.text}
          >
            {boolValue}
          </Text>
        );
      }
      return null;
    };

    return (
      <VStack
        flex={1}
        space={3}
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
      >
        <NBSwitch
          isChecked={currentValue > 0}
          onValueChange={handleValueChange}
          height="32px"
          _android={{
            transform: [{ scaleX: 1.3 }, { scaleY: 1.3 }],
          }}
          _ios={{
            transform: [{ scaleX: 1.3 }, { scaleY: 1.3 }],
          }}
          textAlign="center"
          alignSelf="center"
          isDisabled={isDisabled}
        />
        <BoolValueText />
      </VStack>
    );
  }
);

const styles = StyleSheet.create({
  text: {
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    textShadowColor: '#171717',
  },
});

export { Switch };
