import { apiClient } from './apiClient';

const getWidgetOptions = (panel_id, widget_id) =>
  apiClient.get(`panels/${panel_id}/widgets/${widget_id}/options`);

const saveWidgetOptions = (panel_id, widget_id, data) =>
  apiClient.post(`panels/${panel_id}/widgets/${widget_id}/options`, data);

const deleteWidgetOption = (panel_id, widget_id, option_id) =>
  apiClient.delete(
    `panels/${panel_id}/widgets/${widget_id}/options/${option_id}`
  );

const copyWidgetOptions = (panel_id, widget_id, target_widget_id) =>
  apiClient.post(
    `panels/${panel_id}/widgets/${widget_id}/options/copy/${target_widget_id}`
  );

export {
  getWidgetOptions,
  saveWidgetOptions,
  deleteWidgetOption,
  copyWidgetOptions,
};
