import { PanelHeader } from '@components/PanelHeader';
import { StaticGridItem, StaticGridLayout } from '@components/StaticGridLayout';
import { Widget } from '@components/Widget';
import React, { forwardRef } from 'react';

const GridLayout = forwardRef(
  (
    {
      panel,
      widgets,
      setWidgets,
      currentScale,
      pollingDelay,
      pollingEnabled,
      gridItemFieldName,
    },
    ref
  ) => {
    return (
      <StaticGridLayout
        widgets={widgets}
        header={<PanelHeader {...panel} isReadOnly />}
        render={(rest) =>
          widgets.map((widget) => (
            <StaticGridItem
              key={widget.id}
              {...rest}
              {...widget[gridItemFieldName]}
            >
              <Widget
                widget={widget}
                setWidgets={setWidgets}
                currentScale={currentScale}
                pollingDelay={pollingDelay}
                pollingEnabled={pollingEnabled}
              />
            </StaticGridItem>
          ))
        }
      />
    );
  }
);

export { GridLayout };
