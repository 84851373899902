import { HStack, Spinner } from 'native-base';
import React from 'react';

function LoadingIndicator() {
  return (
    <HStack space={8} justifyContent="center" mt="20">
      <Spinner size="lg" />
    </HStack>
  );
}

export { LoadingIndicator };
