import { useIsFocused } from '@react-navigation/native';
import React from 'react';
import { WidgetDetail } from './WidgetDetail';

function WidgetDetailScreen({ route, navigation }) {
  const isFocused = useIsFocused();

  if (!isFocused) {
    return null;
  }

  return <WidgetDetail route={route} navigation={navigation} />;
}

export { WidgetDetailScreen };
