
/*Necessário manipular o css para redimensionamento correto*/
const CustomGridStack24 = ({ children }) => {
    const style24 =`/*Css para 24 colunas*/
    .grid-stack > .grid-stack-item {
        width: 4.167%;
      }
      .grid-stack > .grid-stack-item[gs-w="1"] {
        width: 4.167%;
      }
      .grid-stack > .grid-stack-item[gs-x="1"] {
        left: 4.167%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="1"] {
        min-width: 4.167%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="1"] {
        max-width: 4.167%;
      }
      .grid-stack > .grid-stack-item[gs-w="2"] {
        width: 8.333%;
      }
      .grid-stack > .grid-stack-item[gs-x="2"] {
        left: 8.333%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="2"] {
        min-width: 8.333%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="2"] {
        max-width: 8.333%;
      }
      .grid-stack > .grid-stack-item[gs-w="3"] {
        width: 12.5%;
      }
      .grid-stack > .grid-stack-item[gs-x="3"] {
        left: 12.5%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="3"] {
        min-width: 12.5%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="3"] {
        max-width: 12.5%;
      }
      .grid-stack > .grid-stack-item[gs-w="4"] {
        width: 16.667%;
      }
      .grid-stack > .grid-stack-item[gs-x="4"] {
        left: 16.667%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="4"] {
        min-width: 16.667%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="4"] {
        max-width: 16.667%;
      }
      .grid-stack > .grid-stack-item[gs-w="5"] {
        width: 20.833%;
      }
      .grid-stack > .grid-stack-item[gs-x="5"] {
        left: 20.833%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="5"] {
        min-width: 20.833%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="5"] {
        max-width: 20.833%;
      }
      .grid-stack > .grid-stack-item[gs-w="6"] {
        width: 25%;
      }
      .grid-stack > .grid-stack-item[gs-x="6"] {
        left: 25%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="6"] {
        min-width: 25%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="6"] {
        max-width: 25%;
      }
      .grid-stack > .grid-stack-item[gs-w="7"] {
        width: 29.167%;
      }
      .grid-stack > .grid-stack-item[gs-x="7"] {
        left: 29.167%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="7"] {
        min-width: 29.167%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="7"] {
        max-width: 29.167%;
      }
      .grid-stack > .grid-stack-item[gs-w="8"] {
        width: 33.333%;
      }
      .grid-stack > .grid-stack-item[gs-x="8"] {
        left: 33.333%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="8"] {
        min-width: 33.333%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="8"] {
        max-width: 33.333%;
      }
      .grid-stack > .grid-stack-item[gs-w="9"] {
        width: 37.5%;
      }
      .grid-stack > .grid-stack-item[gs-x="9"] {
        left: 37.5%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="9"] {
        min-width: 37.5%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="9"] {
        max-width: 37.5%;
      }
      .grid-stack > .grid-stack-item[gs-w="10"] {
        width: 41.667%;
      }
      .grid-stack > .grid-stack-item[gs-x="10"] {
        left: 41.667%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="10"] {
        min-width: 41.667%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="10"] {
        max-width: 41.667%;
      }
      .grid-stack > .grid-stack-item[gs-w="11"] {
        width: 45.833%;
      }
      .grid-stack > .grid-stack-item[gs-x="11"] {
        left: 45.833%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="11"] {
        min-width: 45.833%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="11"] {
        max-width: 45.833%;
      }
      .grid-stack > .grid-stack-item[gs-w="12"] {
        width: 50%;
      }
      .grid-stack > .grid-stack-item[gs-x="12"] {
        left: 50%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="12"] {
        min-width: 50%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="12"] {
        max-width: 50%;
      }
      .grid-stack > .grid-stack-item[gs-w="13"] {
        width: 54.167%;
      }
      .grid-stack > .grid-stack-item[gs-x="13"] {
        left: 54.167%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="13"] {
        min-width: 54.167%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="13"] {
        max-width: 54.167%;
      }
      .grid-stack > .grid-stack-item[gs-w="14"] {
        width: 58.333%;
      }
      .grid-stack > .grid-stack-item[gs-x="14"] {
        left: 58.333%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="14"] {
        min-width: 58.333%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="14"] {
        max-width: 58.333%;
      }
      .grid-stack > .grid-stack-item[gs-w="15"] {
        width: 62.5%;
      }
      .grid-stack > .grid-stack-item[gs-x="15"] {
        left: 62.5%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="15"] {
        min-width: 62.5%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="15"] {
        max-width: 62.5%;
      }
      .grid-stack > .grid-stack-item[gs-w="16"] {
        width: 66.667%;
      }
      .grid-stack > .grid-stack-item[gs-x="16"] {
        left: 66.667%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="16"] {
        min-width: 66.667%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="16"] {
        max-width: 66.667%;
      }
      .grid-stack > .grid-stack-item[gs-w="17"] {
        width: 70.833%;
      }
      .grid-stack > .grid-stack-item[gs-x="17"] {
        left: 70.833%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="17"] {
        min-width: 70.833%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="17"] {
        max-width: 70.833%;
      }
      .grid-stack > .grid-stack-item[gs-w="18"] {
        width: 75%;
      }
      .grid-stack > .grid-stack-item[gs-x="18"] {
        left: 75%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="18"] {
        min-width: 75%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="18"] {
        max-width: 75%;
      }
      .grid-stack > .grid-stack-item[gs-w="19"] {
        width: 79.167%;
      }
      .grid-stack > .grid-stack-item[gs-x="19"] {
        left: 79.167%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="19"] {
        min-width: 79.167%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="19"] {
        max-width: 79.167%;
      }
      .grid-stack > .grid-stack-item[gs-w="20"] {
        width: 83.333%;
      }
      .grid-stack > .grid-stack-item[gs-x="20"] {
        left: 83.333%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="20"] {
        min-width: 83.333%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="20"] {
        max-width: 83.333%;
      }
      .grid-stack > .grid-stack-item[gs-w="21"] {
        width: 87.5%;
      }
      .grid-stack > .grid-stack-item[gs-x="21"] {
        left: 87.5%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="21"] {
        min-width: 87.5%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="21"] {
        max-width: 87.5%;
      }
      .grid-stack > .grid-stack-item[gs-w="22"] {
        width: 91.667%;
      }
      .grid-stack > .grid-stack-item[gs-x="22"] {
        left: 91.667%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="22"] {
        min-width: 91.667%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="22"] {
        max-width: 91.667%;
      }
      .grid-stack > .grid-stack-item[gs-w="23"] {
        width: 95.833%;
      }
      .grid-stack > .grid-stack-item[gs-x="23"] {
        left: 95.833%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="23"] {
        min-width: 95.833%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="23"] {
        max-width: 95.833%;
      }
      .grid-stack > .grid-stack-item[gs-w="24"] {
        width: 100%;
      }
      .grid-stack > .grid-stack-item[gs-x="24"] {
        left: 100%;
      }
      .grid-stack > .grid-stack-item[gs-min-w="24"] {
        min-width: 100%;
      }
      .grid-stack > .grid-stack-item[gs-max-w="24"] {
        max-width: 100%;
      }`
      return <><style>{style24}</style>{children}</>;
  }

const CustomGridStack12 =({ children }) => {
  const style12 = `
  .grid-stack > .grid-stack-item {
    width: 8.333%;
  }
  .grid-stack > .grid-stack-item[gs-w="1"] {
    width: 8.333%;
  }
  .grid-stack > .grid-stack-item[gs-x="1"] {
    left: 8.333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="1"] {
    min-width: 8.333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="1"] {
    max-width: 8.333%;
  }
  .grid-stack > .grid-stack-item[gs-w="2"] {
    width: 16.667%;
  }
  .grid-stack > .grid-stack-item[gs-x="2"] {
    left: 16.667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="2"] {
    min-width: 16.667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="2"] {
    max-width: 16.667%;
  }
  .grid-stack > .grid-stack-item[gs-w="3"] {
    width: 25%;
  }
  .grid-stack > .grid-stack-item[gs-x="3"] {
    left: 25%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="3"] {
    min-width: 25%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="3"] {
    max-width: 25%;
  }
  .grid-stack > .grid-stack-item[gs-w="4"] {
    width: 33.333%;
  }
  .grid-stack > .grid-stack-item[gs-x="4"] {
    left: 33.333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="4"] {
    min-width: 33.333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="4"] {
    max-width: 33.333%;
  }
  .grid-stack > .grid-stack-item[gs-w="5"] {
    width: 41.667%;
  }
  .grid-stack > .grid-stack-item[gs-x="5"] {
    left: 41.667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="5"] {
    min-width: 41.667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="5"] {
    max-width: 41.667%;
  }
  .grid-stack > .grid-stack-item[gs-w="6"] {
    width: 50%;
  }
  .grid-stack > .grid-stack-item[gs-x="6"] {
    left: 50%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="6"] {
    min-width: 50%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="6"] {
    max-width: 50%;
  }
  .grid-stack > .grid-stack-item[gs-w="7"] {
    width: 58.333%;
  }
  .grid-stack > .grid-stack-item[gs-x="7"] {
    left: 58.333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="7"] {
    min-width: 58.333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="7"] {
    max-width: 58.333%;
  }
  .grid-stack > .grid-stack-item[gs-w="8"] {
    width: 66.667%;
  }
  .grid-stack > .grid-stack-item[gs-x="8"] {
    left: 66.667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="8"] {
    min-width: 66.667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="8"] {
    max-width: 66.667%;
  }
  .grid-stack > .grid-stack-item[gs-w="9"] {
    width: 75%;
  }
  .grid-stack > .grid-stack-item[gs-x="9"] {
    left: 75%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="9"] {
    min-width: 75%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="9"] {
    max-width: 75%;
  }
  .grid-stack > .grid-stack-item[gs-w="10"] {
    width: 83.333%;
  }
  .grid-stack > .grid-stack-item[gs-x="10"] {
    left: 83.333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="10"] {
    min-width: 83.333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="10"] {
    max-width: 83.333%;
  }
  .grid-stack > .grid-stack-item[gs-w="11"] {
    width: 91.667%;
  }
  .grid-stack > .grid-stack-item[gs-x="11"] {
    left: 91.667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="11"] {
    min-width: 91.667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="11"] {
    max-width: 91.667%;
  }
  `
    return <><style>{style12}</style>{children}</>;
  }
    
export { CustomGridStack12, CustomGridStack24 };
  