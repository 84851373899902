import { fcFormatDate } from './dateTimeHelper';

// prettier-ignore
const lotsOfDataPeriod = (a, b) => `Ops! Your data range returned way too many values for the selected period.
We are currently presenting data from ${fcFormatDate(a)} until ${fcFormatDate(b)}.
Try chosing another time filter.`

const mandatoryReadAPIKey = (channelID) =>
  `Channel ${channelID} is not public, therefore it’s mandatory to provide Read API Key`;

const panelAccessChanged = (isPrivate) =>
  `Panel access has been changed to ${isPrivate ? 'private' : 'public'}`;

const customMessages = {
  isRequiredField: 'This field is required',
  isRequiredArrayItem: 'At least one item is required!',
  saveToContinue:
    'You must save to continue. Do you want to save and continue?',
  unsavedChanges:
    'There are unsaved changes. Do you want to save and continue?',
  greaterThan: 'must be greater than',
  lotsOfDataPeriod,
  channelNotFound: 'Channel not found',
  invalidAPIKey: 'Invalid API Key',
  alreadyBeenAdded: 'has already been added',
  mandatoryReadAPIKey,
  panelAccessChanged,
};

export { customMessages };
