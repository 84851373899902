import { DeleteIconButton } from '@components/DeleteIconButton';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { isEditMode } from '@utils/screenHelper';
import { deleteSource } from '@utils/source';
import { Text } from 'native-base';
import React from 'react';

function Header() {
  const linkTo = useLinkTo();
  const route = useRoute();

  return (
    <>
      <Text fontSize="xl">
        {isEditMode(route) ? 'Edit Connection' : 'New Connection'}
      </Text>
      {isEditMode(route) ? (
        <DeleteIconButton
          tip="Delete Connection"
          onPress={() =>
            deleteSource(route.params?.id).then(() => linkTo('/connections'))
          }
        />
      ) : null}
    </>
  );
}

export { Header };
