import { widgetBgColor } from '@utils/customTheme';
import {
  fcFormatDate,
  fcOutTimeFormat,
  minutesToDays,
  toTimestamp,
} from '@utils/dateTimeHelper';
import { colord } from 'colord';
import { endOfDay, isToday, minutesToHours, sub } from 'date-fns';
import _ from 'lodash';
import { Center, HStack, Heading, Spinner, Text } from 'native-base';
import randomColor from 'randomcolor';
import React from 'react';

const disableAllUserEvents = (dom, events) => {
  const handler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    return false;
  };
  for (let i = 0, l = events.length; i < l; i++) {
    dom.addEventListener(events[i], handler, true);
  }
  return () => {
    for (let i = 0, l = events.length; i < l; i++) {
      dom.removeEventListener(events[i], handler, true);
    }
  };
};

const Loading = () => (
  <Center
    flex={1}
    contentContainerStyle={{ alignItems: 'center' }}
    flexGrow="1"
    w="100%"
    _light={{ bg: 'widgetBgColorLight' }}
    _dark={{ bg: 'widgetBgColorDark' }}
    my={4}
  >
    <Spinner size="lg" />
  </Center>
);

const LoadingData = ({ isLoadingData }) => {
  return isLoadingData ? (
    <HStack
      position="absolute"
      top="0.5"
      space={2}
      bgColor={colord(widgetBgColor.dark).darken(0.014).toHex()}
      alignItems="center"
      justifyContent="space-between"
      p="1"
    >
      <Spinner size="sm" color="secondary.500" />
      <Heading fontSize="12" fontWeight="normal" color="secondary.400">
        Loading...
      </Heading>
    </HStack>
  ) : null;
};

const NoFields = () => (
  <Center flex={1}>
    <Text fontWeight="medium">No Fields</Text>
  </Center>
);

const getSchema = (series) => {
  const schema = [
    {
      name: 'Time',
      type: 'date',
      format: fcOutTimeFormat,
    },
    ...series.map((serie) => {
      return {
        name: serie.display_name || serie?.data[`field${serie.field}`],
        type: 'number',
      };
    }),
  ];
  return schema;
};

const getYAxis = (series) => {
  const plots = {};
  const yAxis = [];
  series.forEach((serie) => {
    const {
      unit,
      display_name,
      type,
      thickness,
      transparency,
      decimal_places,
      scale_min,
      scale_max,
    } = serie;
    const isLine = type === 1;
    const name = serie?.data[`field${serie.field}`];
    const key = unit || display_name || name;
    if (!plots[key]) plots[key] = [];
    plots[key].push({
      value: display_name || name,
      type: isLine ? 'line' : 'area',
      aggregation: 'Last',
      scale_min,
      scale_max,
      style: {
        line: {
          'stroke-width': thickness + (isLine ? 1 : 0),
        },
        'plot.null': {
          'stroke-width': thickness + (isLine ? 1 : 0),
          'stroke-opacity':
            transparency - (!isLine && transparency === 1 ? 0.25 : 0),
        },
        plot: {
          'stroke-width': thickness + (isLine ? 1 : 0),
          'stroke-opacity':
            transparency - (!isLine && transparency === 1 ? 0.25 : 0),
          'fill-opacity':
            transparency - (!isLine && transparency === 1 ? 0.25 : 0),
        },
      },
      decimal_places: decimal_places ?? 1,
    });
  });
  Object.keys(plots).forEach((plot) => {
    const obj = {
      plot: plots[plot],
      title: plot,
      format: {
        round: _.maxBy(plots[plot], 'decimal_places').decimal_places,
      },
      min: _.maxBy(plots[plot], 'scale_min')?.scale_min,
      max: _.maxBy(plots[plot], 'scale_max')?.scale_max,
    };
    yAxis.push(obj);
  });
  return yAxis;
};

const getXAxis = (currentScale, now = new Date(), minX) => {
  const { interval, from: scaleFrom, to: scaleTo, timescale } = currentScale;
  const scaleEndDay = scaleTo ? endOfDay(scaleTo) : now;
  const to = isToday(scaleTo) ? now : scaleEndDay;
  const from = scaleFrom || sub(to, { minutes: interval });
  const binning = {
    year: [],
    month: [],
    day: [],
    hour: [],
    minute: [],
    second: [],
    millisecond: [],
  };
  if (timescale < 60) binning.minute.push(timescale);
  else if (timescale < 1440) binning.hour.push(minutesToHours(timescale));
  else if (timescale >= 1440) binning.day.push(minutesToDays(timescale));

  return {
    plot: 'Time',
    initialInterval: {
      from: fcFormatDate(toTimestamp(minX ? minX : from)),
      to: fcFormatDate(toTimestamp(to)),
    },
    // binning,
  };

};

const getInitialData = (start, end, xaxis) => {
  return [[fcFormatDate(start)], [xaxis.initialInterval.to]];
};

const presetColors = [
  '#5D62B5',
  '#29C3BE',
  '#F2726F',
  '#FFC533',
  '#62B58F',
  '#BC95DF',
  '#67CDF2',
];

const getPaletteColors = (series) => {
  return series.map(
    (serie, index) => serie.color || presetColors[index] || randomColor()
  );
};

const getTimemarker = (
  timemarker = [],
  data,
  fieldId,
  options,
  isRealTime = false
) => {
  const sortedData = _.orderBy(
    data,
    ['created_at'],
    [isRealTime ? 'asc' : 'desc']
  );
  let found = false;
  let createdatPriorRecord;
  
  sortedData.forEach((record) => {
    const option = options.find(
      (option) => option.value === record[`field${fieldId}`]
    );
    const createdat = record.created_at;
    const formattedCreatedat = fcFormatDate(createdat);   
    if (!createdatPriorRecord){
      createdatPriorRecord = createdat
    }

    if (option) {
      if (!found) {
        found = true;
        timemarker.push({
          value: option.value,
          createdat,
          start: isRealTime ? fcFormatDate(createdatPriorRecord): formattedCreatedat,
          end: isRealTime ? formattedCreatedat: fcFormatDate(createdatPriorRecord), 
          timeFormat: fcOutTimeFormat,
          label: option.text,
          type: 'full',
          style: {
            marker: {
              fill: option.color,
            },
            'marker:hover': {
              fill: option.color,
              'fill-opacity': 0.75,
            },
          },
        });
      } else {
        const currentTimemarker = timemarker[timemarker.length-1];
        currentTimemarker[isRealTime ? 'end' : 'start'] = formattedCreatedat;
      }
    } else {
      if (record[`field${fieldId}`]){
        found = false;
        if(found){
          const currentTimemarker = timemarker[timemarker.length-1];
          currentTimemarker[isRealTime ? 'end' : 'start'] = formattedCreatedat;
        }
        
      }
        
    }
    createdatPriorRecord = record.created_at;
  });

  return _.orderBy(timemarker, ['createdat'], ['asc']);
};

export {
  disableAllUserEvents,
  getInitialData,
  getPaletteColors,
  getSchema,
  getXAxis,
  getYAxis,
  getTimemarker,
  Loading,
  LoadingData,
  NoFields,
  presetColors,
};
