import { fcFormatDate, fcOutTimeFormat } from '@utils/dateTimeHelper';

const getSchema = (series) => [
  {
    name: 'Time',
    type: 'date',
    format: fcOutTimeFormat,
  },
  {
    name: 'Field',
    type: 'string',
  },
  {
    name: 'Value',
    type: 'number',
  },
];

const getYAxis = () => [
  {
    plot: [
      {
        value: 'Value',
        type: 'area',
        aggregation: 'Last',
        style: { plot: { 'fill-opacity': '0.60' } },
      },
    ],
    title: '',
  },
];

const getInitialData = (start, end, xaxis, series) => {
  return [
    ...series.map((serie) => [
      fcFormatDate(start),
      serie.display_name || serie?.data[`field${serie.field}`],
      null,
    ]),
    ...series.map((serie) => [
      xaxis.initialInterval.to,
      serie.display_name || serie?.data[`field${serie.field}`],
      null,
    ]),
  ];
};

export { getInitialData, getSchema, getYAxis };
