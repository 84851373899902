import { DeleteItemMenu } from '@components/DeleteItemMenu';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { WinDimensions } from '@components/WinDimensions';
import Feather from '@expo/vector-icons/Feather';
import { useGlobalContext } from '@hooks/useGlobalContext';
import { HeaderTitle } from '@react-navigation/elements';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { customMessages } from '@utils/customMessages';
import { deletePanel, getPanel, savePanel } from '@utils/panel';
import { getSources } from '@utils/source';
import { isBlank } from '@utils/stringHelper';
import { copyWidget, getWidgets, saveWidgets } from '@utils/widget';
import {
  Button,
  Center,
  Fab,
  HStack,
  Hidden,
  Icon,
  Link,
  Menu,
  Text,
  Tooltip,
  VStack,
  ZStack,
} from 'native-base';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import sleep from 'sleep-promise';
import { useDebouncedCallback } from 'use-debounce';
import { CustomHeaderRight } from '@components/CustomHeaderRight';
import { EditTitle } from './EditTitle';
import { GridLayout } from './GridLayout';
import { Header } from './header';
import { NotFoundScreen } from '@screens/NotFoundScreen';
import { cloneDeep } from 'lodash';
import { getChartScales } from '@utils/chartscale';
import useInterval from 'use-interval';
import { Container } from '@components/Container';
import { bodyBgColor } from '@utils/customTheme';
import {
  retrieveChannelData,
  retrieveChannelFeeds,
} from '@utils/thingSpeakAPI_2';
import { Platform } from 'react-native';

const getInitialScale = (panel, scales) => {
  return scales.find((scale) => scale.name === '1H');
};

function WidgetListScreen({ route, navigation }) {
  const { params } = route;
  const linkTo = useLinkTo();
  const [panel, setPanel] = useState(null);
  const [widgets, setWidgets] = useState([]);
  const [sources, setSources] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scales, setScales] = useState([]);
  const [currentScale, setCurrentScale] = useState(null);
  const gridRef = useRef(null);
  const editTitle = useRef(null);
  const {
    progressDialog,
    alertDialog,
    toast,
    currentViewType,
    setCurrentViewType,
  } = useGlobalContext();
  const [pollingDelay] = useState(5000);
  const [pollingEnabled, setPollingEnabled] = useState(false);
  const platformType = Platform.OS === 'web' ? 'W' : 'M';

  useFocusEffect(
    useCallback(() => {
      Promise.all([
        getPanel(params?.panel_id),
        getWidgets(params?.panel_id).then(retrieveChannelData),
        getChartScales(),
        getSources(),
      ])
        .then((resp) => {
          const panel_data = resp[0].data;
          const widgets_data = resp[1];
          const scales_data = resp[2].data;
          const sources_data = resp[3].data;
          setPanel(panel_data);
          setCurrentViewType((prevState) => {
            if (!prevState) {
              return panel_data.type === 'B' ? platformType : panel_data.type;
            }
            return prevState;
          });
          setWidgets(widgets_data);
          setScales(scales_data);
          setCurrentScale(getInitialScale(panel_data, scales_data));
          setSources(sources_data);
          setIsLoading(false);
          setPollingEnabled(true);
        })
        .catch((err) => {
          console.log(err);
          setIsNotFound(true);
          setIsLoading(false);
        });
      return () => {
        setPanel(null);
        setSources([]);
        setWidgets([]);
        setIsLoading(true);
        setIsNotFound(false);
        setPollingEnabled(false);
      };
    }, [navigation, route, platformType])
  );

  useInterval(
    () => {
      retrieveChannelFeeds({
        data: cloneDeep(widgets),
      }).then(setWidgets);
      console.log('Polling');
    },
    pollingEnabled ? pollingDelay : null
  );

  useLayoutEffect(() => {
    if (!isLoading && !isNotFound) {
      navigation.setOptions({
        headerTitle: (rest) => (
          <HeaderTitle {...rest} onPress={() => editTitle.current.show()}>
            {panel.name}
          </HeaderTitle>
        ),
        headerRight: (props) => (
          <CustomHeaderRight
            {...props}
            route={route}
            overflowMenu={(rest) => (
              <>
                <Menu.Item
                  onPress={() => linkTo(`/panels/${panel.id}/preview`)}
                >
                  View
                </Menu.Item>
                <Menu.Item
                  onPress={async () => {
                    const result = await handleSaveChanges();
                    if (result)
                      toast.show({
                        description: 'Your changes are saved successfully',
                        placement: 'top',
                      });
                  }}
                >
                  Save
                </Menu.Item>
                <DeleteItemMenu
                  {...rest}
                  isDisabled={false}
                  onPress={() => {
                    deletePanel(panel.id, currentViewType).then(() =>
                      linkTo('/panels')
                    );
                  }}
                />
                <Menu.Item onPress={handleAccessToggle}>
                  {`Turn ${!panel.is_private ? 'private' : 'public'} access`}
                </Menu.Item>
              </>
            )}
          />
        ),
      });
    }
  }, [isLoading, navigation, panel, currentViewType]);

  const debouncedPanelName = useDebouncedCallback((value) => {
    const values = {
      ...panel,
      name: value.substr(0, 64),
    };
    setPanel(values);
    savePanel(values.id, values);
  }, 500);

  const handleSaveChanges = async () => {
    if (sources.length === 0) {
      alertDialog.current.show(
        'No connection',
        `Before you add your first Widget, you need to set up a Connection.
Do you want to do this now?`,
        <Button
          onPress={() => {
            alertDialog.current.close();
            linkTo('/connections');
          }}
        >
          Yes
        </Button>
      );
      return false;
    }
    setIsSubmitting(true);
    const valuesPanel = { ...panel
      , is_wizard: panel.is_wizard && platformType == 'W' ? false : panel.is_wizard
    };
    setPanel(valuesPanel);
    savePanel(valuesPanel.id, valuesPanel);
      
    const values = gridRef.current ? gridRef.current.serializeData() : widgets;
    await saveWidgets(panel.id, values);
    setIsSubmitting(false);
    return true;
  };

  const handlePanelNameChange = (text) => {
    const value = isBlank(text) ? 'Untitled' : text;
    debouncedPanelName(value);
  };

  const handleAccessToggle = () => {
    const toggled = !panel.is_private;
    const values = { ...panel
        , is_private: toggled 
        , is_wizard: panel.is_wizard && platformType == 'W' ? false : panel.is_wizard
      };
    setPanel(values);
    savePanel(values.id, values);
    sleep(300).then(() => {
      toast.closeAll();
      toast.show({
        description: customMessages.panelAccessChanged(values.is_private),
        placement: 'top',
      });
    });
  };

  const handleViewTypeChange = async (viewType) => {
    const values = {
      ...panel,
      type: panel.type !== viewType ? ['W', 'M'] : viewType,
      is_wizard: panel.is_wizard && platformType == 'W' ? false : panel.is_wizard
    };
    progressDialog.current.show();
    await savePanel(values.id, values);
    await handleSaveChanges();
    progressDialog.current.close();
    setCurrentViewType(viewType);
    linkTo(`/panels/${params?.panel_id}/widgets`);
  };

  const editItem = async (widgetId) => {
    const result = await handleSaveChanges();
    if (result) {
      linkTo(`/panels/${params?.panel_id}/widgets/${widgetId}`);
    }
  };

  const removeItem = (widgetId) => {
    setWidgets((currentWidgets) =>
      currentWidgets.filter((f) => f.id !== widgetId)
    );
  };

  const copyItem = async (widgetId) => {
    const result = await handleSaveChanges();
    if (result)
      copyWidget(params?.panel_id, widgetId).then(() =>
        getWidgets(params?.panel_id).then(retrieveChannelData).then(setWidgets)
      );
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isNotFound || !panel?.is_owner) {
    return <NotFoundScreen />;
  }

  return (
    <>
      <WinDimensions />
      <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
        <Hidden till="md">
          <HStack py={2} alignItems="center" justifyContent="flex-end">
            <Header
              panel={panel}
              setIsLoading={setIsLoading}
              isSubmitting={isSubmitting}
              onSaveChanges={handleSaveChanges}
              onAccessToggle={handleAccessToggle}
            />
          </HStack>
        </Hidden>
        <VStack>
          {widgets.length === 0 ? (
            <Center flex={{ base: 1, md: 0 }} mt={{ base: 0, md: 20 }}>
              <HStack space={1}>
                <Text fontSize="lg">Add new widget,</Text>
                <Link 
                  _text={{
                    color: 'primary.400',
                    fontSize: 'lg',
                  }}
                  onPress={async () => {
                    const result = await handleSaveChanges();
                    if (result) {
                      linkTo(`/panels/${panel.id}/types`);
                    }
                  }}
                >
                  click here.
                </Link>
              </HStack>
            </Center>
          ) : (
            <GridLayout
              ref={gridRef}
              panel={panel}
              onPanelNameChange={handlePanelNameChange}
              onViewTypeChange={handleViewTypeChange}
              widgets={widgets}
              setWidgets={setWidgets}
              currentScale={currentScale}
              pollingDelay={pollingDelay}
              pollingEnabled={pollingEnabled}
              setPollingEnabled={setPollingEnabled}
              editItem={editItem}
              removeItem={removeItem}
              copyItem={copyItem}
              onSaveChanges={handleSaveChanges}
            />
          )}
        </VStack>
      </Container>
      <Hidden from="md">
        <Tooltip label="New Widget" placement="left">
          <Fab
            renderInPortal={false}
            icon={<Icon as={Feather} name="plus" size="lg" />}
            onPress={async () => {
              const result = await handleSaveChanges();
              if (result) {
                linkTo(`/panels/${panel.id}/types`);
              }
            }}
          />
        </Tooltip>
      </Hidden>
      <EditTitle
        ref={editTitle}
        onPanelNameChange={handlePanelNameChange}
        panel={panel}
        setPanel={setPanel}
      />
    </>
  );
}

export { WidgetListScreen };
