import 'gridstack/dist/gridstack-extra.min.css';
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/h5/gridstack-dd-native';
import '@assets/fc.css';
import '@assets/flexbox.css';
import '@assets/styles.css';
import Constants from 'expo-constants';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import CarbonTheme from 'fusioncharts/themes/fusioncharts.theme.carbon';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
import OceanTheme from 'fusioncharts/themes/fusioncharts.theme.ocean';
import ZuneTheme from 'fusioncharts/themes/fusioncharts.theme.zune';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactFC from 'react-fusioncharts';

Widgets(FusionCharts);
ReactFC.fcRoot(
  FusionCharts,
  Charts,
  FusionTheme,
  GammelTheme,
  CandyTheme,
  ZuneTheme,
  OceanTheme,
  CarbonTheme
);

FusionCharts.options.license({
  key: Constants.manifest.extra.fcKey,
  creditLabel: false,
});

function CustomStyles({ children }) {
  return <>{children}</>;
}

export { CustomStyles };
