import Feather from '@expo/vector-icons/Feather';
import { useAuth } from '@hooks/useAuth';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import { useLinkTo } from '@react-navigation/native';
import { Box, Button, HStack, Icon, Text, VStack } from 'native-base';
import React from 'react';
import sleep from 'sleep-promise';

function NavigationItem({ name, icon, onPress }) {
  return (
    <>
      <Button
        variant="ghost"
        mx="3"
        px="5"
        py="4"
        justifyContent="flex-start"
        onPress={onPress}
      >
        <HStack space="4" alignItems="center">
          {icon ? icon : null}
          <Text fontWeight="medium" fontSize="md">
            {name}
          </Text>
        </HStack>
      </Button>
    </>
  );
}

function CustomDrawerContent(props) {
  const linkTo = useLinkTo();
  const { signout } = useAuth();

  return (
    <>
      <DrawerContentScrollView
        contentContainerStyle={{
          paddingTop: 0,
          width: '100%',
          flex: 1,
        }}
        {...props}
      >
        <Box
          pt="5"
          pb="2"
          flex={1}
          _light={{ bg: 'appBarBgColorLight' }}
          _dark={{ bg: 'appBarBgColorDark' }}
        >
          <VStack justifyContent="flex-start" flexGrow={1}>
            <NavigationItem
              name="My Panels"
              onPress={() => linkTo('/panels')}
            />
          </VStack>
          <Box
            marginTop="auto"
            mb="1"
            _light={{ bg: 'appBarBgColorLight' }}
            _dark={{ bg: 'appBarBgColorDark' }}
          >
            <NavigationItem
              name="Sign Out"
              icon={<Icon size="5" as={Feather} name="log-out" />}
              onPress={() =>
                sleep(100)
                  .then(signout)
                  .then(() => linkTo('/signin'))
              }
            />
          </Box>
        </Box>
      </DrawerContentScrollView>
    </>
  );
}

export { CustomDrawerContent };
