import axios from 'axios';
import { parseJSON } from 'date-fns';
import Constants from 'expo-constants';
import { isNil, toNumber } from 'lodash';
import Papa from 'papaparse';
import { toTimestamp } from './dateTimeHelper';
import { getWidgets } from './widget';

const thingSpeakAPIUrl = Constants.manifest.extra.thingSpeakAPIUrl;

const axiosInstance = axios.create({
  baseURL: thingSpeakAPIUrl,
});

const updateChannelData = (api_key, field_id, value, fields = []) =>
  axiosInstance.get('update.json', {
    params: {
      api_key,
      ...fields,
      [`field${field_id}`]: value,
    },
  });

const readChannelStatus = (channel_id, api_key, results = 0) =>
  axiosInstance.get(`channels/${channel_id}/status.json`, {
    params: {
      api_key,
      results,
    },
  });

const readDataFromAllFields = async (channel_id, api_key, results = 0) => {
  try {
    const { data } = await axiosInstance.get(
      `channels/${channel_id}/feeds.json`,
      {
        params: {
          api_key,
          results,
        },
      }
    );
    const allFields = [];
    let channel = data['channel'];
    for (let key in channel) {
      if (key.substr(0, 5) === 'field') {
        allFields.push({
          id: key.slice(-1),
          name: channel[key],
        });
      }
    }
    channel = {
      allFields,
      ...channel,
      channel_name: channel.name,
    };
    return channel;
  } catch (e) {
    console.info(e);
  }
};

const readCSVDataFromSingleField = async (
  channel_id,
  api_key,
  field_id,
  params
) => {
  try {
    const { data } = await axiosInstance.get(
      `channels/${channel_id}/fields/${field_id}.csv`,
      {
        params: {
          api_key,
          results: 0,
          ...params,
        },
      }
    );
    const results = Papa.parse(data, {
      dynamicTyping: true,
      skipEmptyLines: true,
      header: true,
      transform: (v, k) => (k === 'created_at' ? toTimestamp(v) : v),
    });
    return results;
  } catch (e) {
    console.info(e);
  }
};

const readLastFieldEntry = async (channel_id, api_key, field_id) => {
  try {
    const { data } = await axiosInstance.get(
      `channels/${channel_id}/fields/${field_id}/last.json`,
      {
        params: {
          api_key,
        },
      }
    );
    return {
      ...data,
      date: parseJSON(data.created_at),
      channel_id,
      field_id,
    };
  } catch (e) {
    console.info(e);
  }
};

const readLastChannelEntry = (channel_id, api_key) => {
  return axiosInstance.get(`channels/${channel_id}/feeds/last.json`, {
    params: {
      api_key,
    },
  });
};

const readLastFieldEntryChannel = async (channel_id, api_key, field_id) => {
  try {
    const { data } = await axiosInstance.get(
      `channels/${channel_id}/feeds/last.json`,
      {
        params: {
          api_key,
        },
      }
    );

    return {
      ...data,
      date: parseJSON(data.created_at),
      channel_id,
      field_id,
    };
  } catch (e) {
    console.info(e);
  }
};

export {
  readChannelStatus,
  readCSVDataFromSingleField,
  readDataFromAllFields,
  readLastChannelEntry,
  readLastFieldEntry,
  readLastFieldEntryChannel,
  updateChannelData,
};
