import { DeleteIconButton } from '@components/DeleteIconButton';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { isEditMode } from '@utils/screenHelper';
import { deleteWidget } from '@utils/widget';
import { Text } from 'native-base';
import React from 'react';

function Header({ subtype }) {
  const linkTo = useLinkTo();
  const route = useRoute();
  return (
    <>
      <Text fontSize="xl">
        {`${isEditMode(route) ? '' : 'New '}${subtype?.type_name} Widget`}
      </Text>
      {isEditMode(route) ? (
        <DeleteIconButton
          tip="Delete Widget"
          onPress={() =>
            deleteWidget(route.params?.panel_id, route.params?.id).then(() =>
              linkTo(`/panels/${route.params?.panel_id}/widgets`)
            )
          }
        />
      ) : null}
    </>
  );
}

export { Header };
