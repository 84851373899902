import Feather from '@expo/vector-icons/Feather';
import { useGlobalContext } from '@hooks/useGlobalContext';
import { Button, IconButton, Tooltip } from 'native-base';
import React from 'react';

function DeleteIconButton({ onPress, tip = 'Delete', ...props }) {
  const { alertDialog } = useGlobalContext();

  return (
    <Tooltip label={tip} placement="bottom right">
      <IconButton
        colorScheme="danger"
        variant="ghost"
        {...props}
        _icon={{
          as: Feather,
          name: 'trash-2',
          ...props._icon,
        }}
        onPressOut={() =>
          alertDialog.current.show(
            'Delete',
            'Are you sure that you want to delete this record?',
            <Button
              colorScheme="danger"
              onPress={() => {
                alertDialog.current.close();
                onPress();
              }}
            >
              Delete
            </Button>
          )
        }
      />
    </Tooltip>
  );
}

export { DeleteIconButton };
