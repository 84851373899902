import { ChartWrapper } from '@components/ChartWrapper';
import React from 'react';
import ReactFC from 'react-fusioncharts';

const SimpleHorizontal = ({ forceUpdate, dataSource, size }) => {
  return (
    <ChartWrapper
      middle
      maxH={size.height}
      render={() => {
        return (
          <div 
            className="fc-container"
            style={{
              marginTop: 13,
            }}
          >
            <ReactFC
              key={forceUpdate}
              type="hlineargauge"
              width={size.width * 0.98}
              height={size.height * 0.7}
              dataFormat="json"
              dataSource={{
                ...dataSource,
                chart: {
                  ...dataSource.chart,
                  bgAlpha: '0',
                },
              }}
            />
          </div>
        );
      }}
    />
  );
};

export { SimpleHorizontal };
