import { maxlengthContentEditable } from 'maxlength-contenteditable';
import pasteAsPlainText from 'paste-as-plain-text';

const configContentEditable = () => {
  const editableElements = document.querySelectorAll(
    'div[contenteditable="true"]'
  );
  Array.from(editableElements).forEach((element) => {
    pasteAsPlainText(element);
    element.spellcheck = false;
  });
  maxlengthContentEditable();
};

export { configContentEditable };
