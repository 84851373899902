import React from 'react';
import Constants from 'expo-constants';
import { Button, Center, AlertDialog, Text, useDisclose } from 'native-base';
import { Platform } from 'react-native';
import { isMobile } from 'react-device-detect';
import { launchAppOnDevice } from '@utils/deepLinkFromBrowser';

const isMobileBrowser = Platform.OS === 'web' && isMobile;

function OpenInAppDialog({ navigationRef }) {
  const { isOpen, onClose } = useDisclose(true);

  if (!isMobileBrowser) {
    return null;
  }

  if (Platform.OS === 'web'){
    if(window.location.pathname === '/authorize'){

      return null;
    }
  }

  const openInApp = () => {
    const appScheme = Constants.manifest.extra.appScheme;
    const appPackage = Constants.manifest.extra.playStoreId;
    const { path } = navigationRef.current.getCurrentRoute();
    launchAppOnDevice(appScheme, appPackage, path);
  };

  return (
    <AlertDialog isOpen={isOpen} avoidKeyboard onClose={onClose} size="full">
      <AlertDialog.Content
        style={{
          marginBottom: 0,
          marginTop: 'auto',
        }}
      >
        <AlertDialog.Header _text={{ textAlign: 'center' }}>
          Use the app for the best experience.
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button size="sm" onPress={openInApp}>
              Open in App
            </Button>
            <Button variant="unstyled" size="sm" mr="1" onPress={onClose}>
              Not now
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
}

export { OpenInAppDialog };
