import { BottomBar, Container } from '@components/Container';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { useLinkTo } from '@react-navigation/native';
import { bodyBgColor } from '@utils/customTheme';
import { getTypes } from '@utils/widget';
import { sortBy } from 'lodash';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  Button,
  CheckIcon,
  Fab,
  Hidden,
  HStack,
  Radio,
  VStack,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { Header } from './header';

const SelectWidgetTypeScreen = ({ route }) => {
  const linkTo = useLinkTo();
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getTypes().then((resp) => {
      setTypes(sortBy(resp.data, ['name']));
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const getSelectedType = () => types.find((type) => type.id === selectedType);

  const handleNextClick = () => {
    if (getSelectedType().subtypes.length === 1) {
      linkTo(
        `/panels/${route.params?.id}/widgets/new?subtype=${
          getSelectedType().subtypes[0].id
        }`
      );
    } else {
      linkTo(`/panels/${route.params?.id}/types/${selectedType}/subtypes`);
    }
  };

  return (
    <>
        <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
          <VStack>
            <Hidden till="md">
              <HStack py={2} alignItems="center" justifyContent="space-between">
                <Header />
              </HStack>
            </Hidden>
            <VStack
              _light={{ bg: { md: 'containerBgColorLight' } }}
              _dark={{ bg: { md: 'containerBgColorDark' } }}
              shadow={{ md: '1' }}
              borderRadius={{ md: '4' }}
              py={{ base: 2, md: 5 }}
              px={{ base: 2, md: 5 }}
              mb={{ base: 0, md: 5 }}
            >
              <Radio.Group
                onChange={(nextValue) => {
                  setSelectedType(nextValue);
                }}
              >
                {types.map((item) => (
                  <Radio key={item.id} value={item.id} my={1.5}>
                    {item.name}
                  </Radio>
                ))}
              </Radio.Group>
              <Hidden till="md">
                <BottomBar>
                  <Button
                    colorScheme="secondary"
                    onPress={() =>
                      linkTo(`/panels/${route.params?.id}/widgets`)
                    }
                    startIcon={<ArrowBackIcon size="4" />}
                  >
                    Previous
                  </Button>
                  <Button
                    onPress={handleNextClick}
                    endIcon={<ArrowForwardIcon size="4" />}
                    isDisabled={!selectedType}
                  >
                    Next
                  </Button>
                </BottomBar>
              </Hidden>
            </VStack>
          </VStack>
        </Container>
      <Hidden from="md">
        <Fab
          renderInPortal={false}
          icon={<CheckIcon />}
          onPress={handleNextClick}
          isDisabled={!selectedType}
        />
      </Hidden>
    </>
  );
};

export { SelectWidgetTypeScreen };
