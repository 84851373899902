import * as Linking from 'expo-linking';
import Constants from 'expo-constants';

const config = {
  screens: {
    SignIn: 'signin',
    Authorize: 'authorize',
    Home: {
      screens: {
        PanelList: { path: 'panels', exact: true },
        ConnectionList: { path: 'connections', exact: true },
      },
    },   
    Account: { path: 'Account', exact: true },
    AccountPassword: { path: 'AccountPassword', exact: true },
    PanelWizard: { path: 'panels/wizard', exact: true },
    PanelWizardStepOne: { path: 'panels/wizard/one', exact: true },
    PanelWizardStepTwo: { path: 'panels/wizard/two', exact: true },
    SelectWidgetType: { path: 'panels/:id/types', exact: true },
    SelectWidgetSubType: {
      path: 'panels/:panel_id/types/:id/subtypes',
      exact: true,
    },
    WidgetList: { path: 'panels/:panel_id/widgets', exact: true },
    PanelPreview: { path: 'panels/:panel_id/preview', exact: true },
    WidgetDetail: { path: 'panels/:panel_id/widgets/:id', exact: true },

    WidgetNew: { path: 'panels/:panel_id/widgets/new', exact: true },
    WidgetFieldsDetail: {
      path: 'panels/:panel_id/widgets/:id/fields',
      exact: true,
    },
    WidgetFieldsNew: {
      path: 'panels/:panel_id/widgets/fields/new',
      exact: true,
    },
    WidgetFieldDetail: {
      path: 'panels/:panel_id/widgets/:widget_id/fields/:id',
      exact: true,
    },
    WidgetFieldNew: {
      path: 'panels/:panel_id/widgets/:widget_id/fields/new',
      exact: true,
    },
    ConnectionDetail: { path: 'connections/:id', exact: true },
    ConnectionNew: { path: 'connections/new', exact: true },
    ForgetPasswordScreen: { path: 'passwordreset', exact: true },
    passwordresetcomp: { path: 'passwordresetcomp', exact: true },
    passwordresettkn: { path: 'passwordresettkn/:code/:tkn', exact: true },
    Redirecting: 'redirecting',
    NotFound: '*',
  },
};

const appScheme = `${Constants.manifest.extra.appScheme}://`;
const webScheme = `${Constants.manifest.extra.webScheme}/`;
const linking = {
  prefixes: [Linking.createURL('/'), appScheme, webScheme],
  config,
};

export { linking };
