import { useLinkTo, useRoute } from '@react-navigation/native';
import { HStack, Text } from 'native-base';
import React from 'react';

const Header = () => {
  const linkTo = useLinkTo();
  const route = useRoute();

  return (
    <>
      <Text fontSize="xl">Account / Change Password</Text>
    </>
  );
};

export { Header };
