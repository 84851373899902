import { useLinkTo } from '@react-navigation/native';
import { Link } from 'native-base';
import React from 'react';
import { Image } from 'react-native';

function LogoTitle() {
  const linkTo = useLinkTo();
  return (
    <Link
      isUnderlined={false}
      style={{ textTransform: 'uppercase' }}
      _text={{
        selectable: false,
        fontSize: '10',
      }}
      onPress={() => linkTo('/panels')}
    >
      <Image
        style={{ width: 139, height: 54 }}
        source={require('@assets/107hi_topbar.png')}
        alt="107HI"
      />
    </Link>
  );
}

export { LogoTitle };
