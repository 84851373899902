import { Container } from '@components/Container';
import { DeleteItemMenu } from '@components/DeleteItemMenu';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { customMessages } from '@utils/customMessages';
import { getOrigins } from '@utils/origin';
import { isEditMode } from '@utils/screenHelper';
import {
  createSource,
  deleteSource,
  getSource,
  saveSource,
} from '@utils/source';
import { isBlank } from '@utils/stringHelper';
import { readChannelStatus } from '@utils/thingSpeakAPI';
import { Formik } from 'formik';
import { Hidden, HStack, VStack } from 'native-base';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { CustomHeaderRight } from '@components/CustomHeaderRight';
import { Form } from './form';
import { Header } from './header';
import { bodyBgColor } from '@utils/customTheme';
import { saveProfile } from '@utils/profile';
import { useAuth } from '@hooks/useAuth';

const validateForm = (values, form) => {
  const errors = {};
  if (isBlank(values.origin)) {
    errors.origin = customMessages.isRequiredField;
  }
  if (isBlank(values.channel_id)) {
    errors.channel_id = customMessages.isRequiredField;
  } else {
    return readChannelStatus(values.channel_id, values.read_api_key)
      .then((resp) => {
        const { data } = resp;
        if (data === '-1') {
          errors.read_api_key = values.read_api_key
            ? `Channel ${values.channel_id} is not public, therefore it’s mandatory to provide Read API Key`
            : customMessages.isRequiredField;
        } else {
          const { setFieldValue } = form.current;
          setFieldValue('channel_name', data.channel.name);
          if (isBlank(values.display_name)) {
            setFieldValue('display_name', `ThingSpeak - ${data.channel.name}`);
          }
        }

        return errors;
      })
      .catch((error) => {
        errors.channel_id = 'Channel not found';
        return errors;
      });
  }
  if (!values.display_name.trim()) {
    errors.display_name = customMessages.isRequiredField;
  }
  return errors;
};

const ConnectionDetailScreen = ({ route, navigation }) => {
  const { params } = route;
  const linkTo = useLinkTo();
  const [origins, setOrigins] = useState([]);
  const [source, setSource] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const form = useRef(null);
  const { fetchUserData } = useAuth();

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      return () => {
        setIsLoading(false);
      };
    }, [navigation])
  );

  useEffect(() => {
    if (isLoading) {
      getOrigins().then((resp) => setOrigins(resp.data));
    }
  }, [params?.id, isLoading]);

  useEffect(() => {
    if (origins.length > 0) {
      if (isEditMode(route)) {
        getSource(params?.id).then((resp) => {
          setSource(resp.data);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    }
  }, [params?.id, origins]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: (props) => (
        <CustomHeaderRight
          {...props}
          route={route}
          overflowMenu={(rest) => (
            <DeleteItemMenu
              {...rest}
              onPress={() =>
                deleteSource(params?.id).then(() => linkTo('/connections'))
              }
            />
          )}
        />
      ),
    });
  }, [navigation]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const handleFormSubmit = async (values) => {
    if (isEditMode(route)) {
      await saveSource(values.id, values);
      navigation.goBack();
    } else {
      const { data: newConnection } = await createSource(values);
      if (params.selectable === '1') {
        await saveProfile({
          user_last_source: newConnection.id,
        });
        await fetchUserData();
        global.newConnectionId = newConnection.id;
      }
      navigation.goBack();
    }
  };

  return (
  
      <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
        <VStack>
          <Hidden till="md">
            <HStack py={2} alignItems="center" justifyContent="space-between">
              <Header />
            </HStack>
          </Hidden>
          <VStack
            _light={{ bg: { md: 'containerBgColorLight' } }}
            _dark={{ bg: { md: 'containerBgColorDark' } }}
            shadow={{ md: '1' }}
            borderRadius={{ md: '4' }}
            py={{ base: 2, md: 5 }}
            px={{ base: 2, md: 5 }}
            mb={{ base: 0, md: 5 }}
          >
            <Formik
              innerRef={form}
              initialValues={{
                origin: '',
                channel_id: '',
                channel_name: '',
                display_name: '',
                ...source,
                origin: source.origin
                  ? source.origin.toString()
                  : origins[0]?.id.toString(),
              }}
              validateOnBlur={true}
              validateOnChange={false}
              validate={() => validateForm(form.current.values, form)}
              onSubmit={handleFormSubmit}
              component={(rest) => <Form {...rest} origins={origins} />}
            />
          </VStack>
        </VStack>
      </Container>
  );
};

export { ConnectionDetailScreen };
