import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Constants from 'expo-constants';

const axiosInstance = axios.create({
  baseURL: Constants.manifest.extra.apiBaseURL,
  withCredentials: true,
  

});

axiosInstance.interceptors.request.use(async (request) => {
  const access_token = await AsyncStorage.getItem('@access_token');
  if (access_token) {
    request.headers.Authorization = `Bearer ${access_token}`;
  }
  return request;
});

const getCsrfToken = async () => {
  try {
    const resp = await axiosInstance.get('csrf', {
      auth: {
        username: Constants.manifest.extra.clientId,
        password: Constants.manifest.extra.clientSecret,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export { axiosInstance as apiClient, getCsrfToken };
