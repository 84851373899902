import { Button, Text, VStack } from 'native-base';
import React, { memo } from 'react';

const ONOFF = memo(
  ({ widget, currentValue, onCurrentValueChange, isDisabled }) => {
    const { display_name, name } = widget;

    const handleValueChange = (boolValue) =>
      onCurrentValueChange(boolValue ? 1 : 0);

    return (
      <VStack
        flex={1}
        space={3}
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Button.Group
          isAttached
          colorScheme="blue"
          mx={{
            base: 'auto',
            md: 0,
          }}
          size="sm"
          isDisabled={isDisabled}
        >
          <Button
            variant={currentValue > 0 ? undefined : 'outline'}
            onPress={() => handleValueChange(true)}
            _text={{
              fontWeight: 'medium',
            }}
            _disabled={{
              opacity: 70,
            }}
          >
            ON
          </Button>
          <Button
            variant={currentValue > 0 ? 'outline' : undefined}
            onPress={() => handleValueChange(false)}
            _text={{
              fontWeight: 'medium',
            }}
            _disabled={{
              opacity: 70,
            }}
          >
            OFF
          </Button>
        </Button.Group>
      </VStack>
    );
  }
);

export { ONOFF };
