import { Ionicons } from '@expo/vector-icons';
import { useGlobalContext } from '@hooks/useGlobalContext';
import {
  Box,
  Button,
  Center,
  FormControl,
  HStack,
  Input,
  VStack,
  View,
  useToast,
  Hidden,
} from 'native-base';
import React from 'react';
import { useEffect, useState } from 'react';
import { Alert, Image, StyleSheet } from 'react-native';
import { useAuth } from '@hooks/useAuth';
import { Header } from './header';
import { Container } from '@components/Container';
import { customMessages } from '@utils/customMessages';
import * as Yup from 'yup';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { useCallback } from 'react';
import { bodyBgColor } from '@utils/customTheme';
import sleep from 'sleep-promise';

const validationSchema = Yup.object().shape({
  password: Yup.string().required(customMessages.isRequiredField),
  password2: Yup.string().required(customMessages.isRequiredField),
});

function AccountPasswordScreen({ navigation, route }) {
  const {
    userData,
    account,
    accountpassword,
    signout,
    removeaccount,
    provider,
  } = useAuth();
  const linkTo = useLinkTo();
  const { alertDialog } = useGlobalContext();
  const toast = useToast();
  const [username, setUsername] = useState(userData?.username);
  const [id, setId] = useState(userData?.id);
  const [password, setPassword] = useState(userData?.password);
  const [password2, setPassword2] = useState(userData?.password2);
  const [currentpassword, setCurrentPassword] = useState(null);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submitting2, setSubmitting2] = useState(false);

  const clearErrors = async (field) => {
    if (errors[field]) {
      const NewErrors = { ...errors };
      delete NewErrors[field];
      setErrors(NewErrors);
    }
    toast.closeAll();
  };

  const handleDiscardButton = async () => {
    navigation.goBack();  
  };

  const handleChangePassword = async () => {
    setSubmitting(true);
    let values = {
      id: id,
      password: password,
      password2: password2,
      currentpassword: currentpassword,
    };

    return await accountpassword(values)
      .then(() => {
        toast.show({
          placement: 'top',

          render: () => {
            sleep(100)
              .then(signout)
              .then(() => linkTo('/signin'));
            return (
              <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
                You have been updated successfully.
              </Box>
            );
          },
        });

        setSubmitting(false);
      })
      .catch((error) => {
        toast.show({
          title: 'Account Update Failed',
          placement: 'top',
        });
        setSubmitting(false);
        setErrors(error.response.data);
      });
  };

  useFocusEffect(
    useCallback(() => {
      setSubmitting(false);
      setSubmitting2(false);
    }, [navigation])
  );

  return (
 
      <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
        <VStack>
          <Hidden till="md">
            <HStack py={2} alignItems="center" justifyContent="space-between">
              <Header />
            </HStack>
          </Hidden>
          <VStack
            _light={{ bg: { md: 'containerBgColorLight' } }}
            _dark={{ bg: { md: 'containerBgColorDark' } }}
            shadow={{ md: '1' }}
            borderRadius={{ md: '4' }}
            py={{ base: 2, md: 5 }}
            px={{ base: 2, md: 5 }}
            mb={{ base: 0, md: 5 }}
          >
            <Center>
              <Box w="290">
                <VStack space={3} mt="1">
                  <View
                    flex={1}
                    p={{ md: 2 }}
                    contentContainerStyle={{ alignItems: 'center' }}
                  >
                    <Center flex={1}>
                      <FormControl isInvalid={'currentpassword' in errors}>
                        <FormControl.Label>Current password</FormControl.Label>
                        <Input
                          placeholder="Current password"
                          type="password"
                          size="md"
                          onChangeText={(text) => setCurrentPassword(text)}
                          onFocus={() => clearErrors('currentpassword')}
                        />
                        <FormControl.ErrorMessage>
                          {errors.currentpassword}
                        </FormControl.ErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={'password' in errors}>
                        <FormControl.Label>New password</FormControl.Label>
                        <Input
                          placeholder="New password"
                          type="password"
                          value={password}
                          size="md"
                          onChangeText={(text) => setPassword(text)}
                          onFocus={() => clearErrors('password')}
                        />
                        <FormControl.ErrorMessage>
                          {errors.password}
                        </FormControl.ErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={'password2' in errors}>
                        <FormControl.Label>Confirm password</FormControl.Label>
                        <Input
                          placeholder="Password"
                          type="password"
                          value={password2}
                          size="md"
                          onChangeText={(text) => setPassword2(text)}
                          onFocus={() => clearErrors('password2')}
                        />
                        <FormControl.ErrorMessage>
                          {errors.password2}
                        </FormControl.ErrorMessage>
                      </FormControl>

                      <View style={{ flexDirection: 'row' }}>
                        <View width={110}>
                          <Button
                            leftIcon={
                              <Ionicons
                                name="md-save-outline"
                                size={22}
                                color="white"
                              />
                            }
                            width={100}
                            mt="2"
                            onPress={handleChangePassword}
                            isLoading={submitting}
                          >
                            Save
                          </Button>
                        </View>
                        <View>
                          <Button
                            leftIcon={
                              <Ionicons
                                name="arrow-back-circle-outline"
                                size={22}
                                color="white"
                              />
                            }
                            colorScheme="coolGray"
                            width={100}
                            mt="2"
                            onPress={handleDiscardButton}
                            isLoading={submitting2}
                          >
                            Discard
                          </Button>
                        </View>
                      </View>
                    </Center>
                  </View>
                </VStack>
              </Box>
            </Center>
          </VStack>
        </VStack>
      </Container>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#222',
  },
});

export { AccountPasswordScreen };
