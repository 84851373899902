import { createDrawerNavigator } from '@react-navigation/drawer';
import { ConnectionListScreen } from '@screens/ConnectionListScreen';
import { CustomDrawerContent } from '@components/CustomDrawerContent';
import { CustomHeaderRight } from '@components/CustomHeaderRight';
import { PanelListScreen } from '@screens/PanelListScreen';
import { headerStyleTheme } from '@utils/customTheme';
import Constants from 'expo-constants';
import { useBreakpointValue, useColorMode } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import { CustomAppBar } from '@components/CustomAppBar';
import { Header } from '@react-navigation/elements';

const Drawer = createDrawerNavigator();

function HomeScreen() {
  const isLargeScreen = useBreakpointValue({
    md: true,
  }) & Platform.OS === 'web';
  const { colorMode } = useColorMode();

  return (
    <Drawer.Navigator
      screenOptions={{
        unmountInactiveRoutes: true,
        unmountOnBlur: true,
        title: Constants.manifest.extra.pageTitle,
        drawerType: Platform.OS === 'web' ? 'back' : 'front',
        drawerStyle: {
          width: 280,
        },
        headerRight: (props) => <CustomHeaderRight {...props} />,
        ...headerStyleTheme({ isLargeScreen, colorMode }),
        // headerShown: !isLargeScreen,
        header: isLargeScreen
          ? (props) => <CustomAppBar {...props} />
          : undefined,
      }}
      
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen
        name="PanelList"
        component={PanelListScreen}
        options={{ headerTitle: 'My Panels' }}
      />
      <Drawer.Screen
        name="ConnectionList"
        component={ConnectionListScreen}
        options={{ headerTitle: 'My Connections' }}
      />
    </Drawer.Navigator>
  );
}

export { HomeScreen };
