import { Center, Text, View } from 'native-base';
import React from 'react';

function NotFoundScreen({ route }) {
  return (
    <View
      flex={1}
      p={{ md: 8 }}
      contentContainerStyle={{ alignItems: 'center' }}
    >
      <Center flex={1}>
        <Text fontSize="lg" bold>
          Sorry, that page doesn&apos;t exist!
        </Text>
        {process.env.NODE_ENV === 'development' && (
          <Text>{JSON.stringify(route)}</Text>
        )}
      </Center>
    </View>
  );
}

export { NotFoundScreen };
