import { View } from 'native-base';
import React, { memo, useEffect, useState } from 'react';
import { Image, Text, StyleSheet } from 'react-native';

const ImageUpload = memo(({ widget, setWidgetPropValue }) => {
  const {
    background_color,
    alignment,
    image_base64,
    image_width,
    image_height,
    image_name,
    image_scale_fit,
    image_keep_proportions,
    margins,
  } = widget;
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const alignmentParts = alignment.split('|');
  const verticalAlignment = alignmentParts[0];
  const horizontalAlignment = alignmentParts[1];

  const handleLayout = (event) => {
    const { width, height } = event.nativeEvent.layout;
    if (image_base64 && width > 0 && height > 0) {
      setContainerSize({ width: width, height: height });
    }
  };

  useEffect(() => {
    setWidgetPropValue('isMounted', true);
    if (image_base64 && containerSize.width > 0 && containerSize.height > 0) {
      getProportionalImageSize(
        image_base64,
        containerSize.width,
        containerSize.height
      );
    }
  }, [containerSize, image_scale_fit, image_keep_proportions]);

  const getProportionalImageSize = (uri, containerWidth, containerHeight) => {
    Image.getSize(
      uri,
      (width, height) => {
        if (image_scale_fit === 'T' && image_keep_proportions === 'T') {
          width = width !== containerWidth ? containerWidth : width;
          height = height !== containerHeight ? containerHeight : height;
        } else if (image_scale_fit === 'T') {
          width = containerWidth;
          height = containerHeight;
        } else {
          width = width > containerWidth ? containerWidth : width;
          height = height > containerHeight ? containerHeight : height;
        }
        setImageSize({ width, height });
      },
      (error) => {
        console.error('Failure to obtain the image size:', error);
      }
    );
  };

  const getHorizontalAlignment = () => {
    switch (horizontalAlignment) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  };

  const customBackground = {
    backgroundColor:
      background_color === undefined ? '#262a33' : background_color,
  };

  const customImageArea = {
    alignItems: getHorizontalAlignment(),
    justifyContent: !verticalAlignment ? 'center' : verticalAlignment,
  };

  return (
    <>
      <View p={margins} style={[styles.mainArea, customBackground]}>
        <View
          onLayout={handleLayout}
          style={[styles.imageArea, customBackground, customImageArea]}
        >
          {image_base64 ? (
            <>
              <Image
                source={{ uri: image_base64 }}
                style={{
                  width: imageSize.width ? imageSize.width : image_width,
                  height: imageSize.height ? imageSize.height : image_height,
                  resizeMode:
                    image_keep_proportions === 'T'
                      ? 'contain'
                      : image_scale_fit === 'T'
                      ? 'stretch'
                      : 'cover',
                }}
              />
            </>
          ) : (
            <Text style={{ color: '#FFF' }}>
              {image_base64 ? 'Loading image...' : 'Image not uploaded'}
            </Text>
          )}
        </View>
      </View>
    </>
  );
});

const styles = StyleSheet.create({
  mainArea: {
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    width: '100%',
    height: '100%',
  },
  imageArea: {
    cursor: 'default',
    width: '100%',
    height: '100%',
  },
});

export { ImageUpload };
