import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  AlertDialog,
  Button,
  Checkbox,
  Heading,
  HStack,
  Spinner,
  Text,
  useDisclose,
  VStack,
} from 'native-base';

const ProgressDialog = forwardRef((props, ref) => {
  const { isOpen, onClose, onOpen } = useDisclose(false);

  useImperativeHandle(ref, () => ({
    close: onClose,
    show: onOpen,
  }));

  return (
    <AlertDialog
      isOpen={isOpen}
      size="sm"
      avoidKeyboard
      closeOnOverlayClick={false}
    >
      <AlertDialog.Content p={5}>
        <HStack space={4} alignItems="center">
          <Spinner color="secondary.200" />
          <Heading fontSize="sm" color="secondary.200">
            Please wait...
          </Heading>
        </HStack>
      </AlertDialog.Content>
    </AlertDialog>
  );
});

export { ProgressDialog };
