import { Center, Spinner, View } from 'native-base';
import React from 'react';

function NavigationFallback() {
  return (
    <Center flex={1} bgColor="bodyBgColorDark">
      <Spinner size="lg" />
    </Center>
  );
}

export { NavigationFallback };
