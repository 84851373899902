import { parseISO } from 'date-fns';
import { get, set } from 'lodash';
import { apiClient } from './apiClient';

const getPanels = () => apiClient.get('panels');

const getPanel = (panel_id) =>
  apiClient.get(`panels/${panel_id}`).then((resp) => {
    const panel = resp.data;
    if (
      panel.preferences?.selectedScale &&
      panel.preferences.selectedScale?.to
    ) {
      const from = parseISO(get(panel, 'preferences.selectedScale.from'));
      const to = parseISO(get(panel, 'preferences.selectedScale.to'));
      set(panel, 'preferences.selectedScale.from', from);
      set(panel, 'preferences.selectedScale.to', to);
    }
    return {
      data: panel,
    };
  });

const createPanel = (data) => apiClient.post('panels/create', data);

const savePanel = (panel_id, data) => apiClient.put(`panels/${panel_id}`, data);

const deletePanel = (panel_id, type = 'W') =>
  apiClient.delete(`panels/${panel_id}?type=${type}`);

const generatePanel = (panel_id, data, type = 'W') =>
  apiClient.post(`panels/${panel_id}/generator?type=${type}`, data);

export {
  getPanels,
  getPanel,
  createPanel,
  savePanel,
  deletePanel,
  generatePanel,
};
