import { Container } from '@components/Container';
import { LoadingIndicator } from '@components/LoadingIndicator';
import Feather from '@expo/vector-icons/Feather';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { bodyBgColor } from '@utils/customTheme';
import { getSources } from '@utils/source';
import {
  Box,
  Fab,
  HStack,
  Hidden,
  Icon,
  IconButton,
  Text,
  VStack,
} from 'native-base';
import { FlashList } from "@shopify/flash-list";
import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { Header } from './header';

const ConnectionListScreen = () => {
  const linkTo = useLinkTo();
  const [sources, setSources] = useState([]);
  const isFocused = useIsFocused();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (isFocused) {
      getSources().then((resp) => {
        setSources(resp.data);
        setIsLoading(false);
      });
    }
  }, [isFocused]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const ListEmptyComponent = () => (
    <Text textAlign="center" color="secondary.300" mt={{ base: 6, md: 4 }}>
      You don't have any connection yet
    </Text>
  );

  return (
    <>
      <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
        <VStack>
          <Hidden till="md">
            <HStack py={2} alignItems="center" justifyContent="space-between">
              <Header />
            </HStack>
          </Hidden>          
          <VStack >
            <FlashList
              data={sources}
              keyExtractor={(item) => item.id}
              estimatedItemSize={20}
              ListEmptyComponent={ListEmptyComponent}
              renderItem={({ item }) => (
                <Box
                  px="1"
                  mx="1.5"
                  py={{ base: 3, md: 2 }}
                  borderBottomWidth={{ base: 0.5, md: 1 }}
                  _dark={{ borderBottomColor: '#353942' }}
                  borderBottomColor="gray.300"
                >
                  <TouchableOpacity
                    onPress={() => linkTo(`/connections/${item.id}`)}
                  >
                    <HStack justifyContent="space-between" alignItems="center">
                      <Text fontSize="sm" flex={1}>
                        {item.display_name}
                      </Text>
                      <HStack>
                        <IconButton
                          icon={
                            <Icon
                              as={Feather}
                              name="edit-3"
                              _light={{ color: 'coolGray.800' }}
                              _dark={{ color: 'warmGray.50' }}
                            />
                          }
                          onPressOut={() => linkTo(`/connections/${item.id}`)}
                        />
                      </HStack>
                    </HStack>
                  </TouchableOpacity>
                </Box>
              )}
            />
          </VStack>
        </VStack>
      </Container>
      <Hidden from="md">
        <Fab
          renderInPortal={false}
          icon={<Icon as={Feather} name="plus" size="lg" />}
          onPress={() => linkTo('/connections/new')}
        />
      </Hidden>
    </>
  );
};

export { ConnectionListScreen };
