import { useLinkTo } from '@react-navigation/native';
import { Button, Text } from 'native-base';
import React from 'react';

function Header() {
  const linkTo = useLinkTo();
  return (
    <>
      <Text fontSize="xl">My Connections</Text>
      <Button onPress={() => linkTo('/connections/new')}>New Connection</Button>
    </>
  );
}

export { Header };
