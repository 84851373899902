import { apiClient } from './apiClient';

const getTypes = () => apiClient.get('widgets/types');

const getSubTypes = () => apiClient.get(`widgets/types/subtypes`);

const getWidgets = (panel_id) => apiClient.get(`panels/${panel_id}/widgets`);

const createWidget = (panel_id, data, type = 'W') =>
  apiClient.post(`panels/${panel_id}/widgets/create?type=${type}`, data);

const saveWidget = (panel_id, widget_id, data) =>
  apiClient.put(`panels/${panel_id}/widgets/${widget_id}`, data);

const getWidget = (panel_id, widget_id) =>
  apiClient.get(`panels/${panel_id}/widgets/${widget_id}`);

const saveWidgets = (panel_id, data) =>
  apiClient.post(`panels/${panel_id}/widgets`, data);

const deleteWidget = (panel_id, widget_id) =>
  apiClient.delete(`panels/${panel_id}/widgets/${widget_id}`);

const copyWidget = (panel_id, widget_id, type = 'W') =>
  apiClient.post(`panels/${panel_id}/widgets/${widget_id}/copy?type=${type}`);

export {
  createWidget,
  deleteWidget,
  getSubTypes,
  getTypes,
  getWidget,
  getWidgets,
  saveWidget,
  saveWidgets,
  copyWidget,
};
