import { apiClient } from './apiClient';

const getWidgetRanges = (panel_id, widget_id) =>
  apiClient.get(`panels/${panel_id}/widgets/${widget_id}/ranges`);

const saveWidgetRanges = (panel_id, widget_id, data) =>
  apiClient.post(`panels/${panel_id}/widgets/${widget_id}/ranges`, data);

const deleteWidgetRange = (panel_id, widget_id, range_id) =>
  apiClient.delete(
    `panels/${panel_id}/widgets/${widget_id}/ranges/${range_id}`
  );

const copyWidgetRanges = (panel_id, widget_id, target_widget_id) =>
  apiClient.post(
    `panels/${panel_id}/widgets/${widget_id}/ranges/copy/${target_widget_id}`
  );

export {
  getWidgetRanges,
  saveWidgetRanges,
  deleteWidgetRange,
  copyWidgetRanges,
};
