import { useCallback, useState } from 'react';
import { PixelRatio } from 'react-native';
import { roundTo } from 'round-to';
import { dpBase } from './useWinDimensions';

const calcComponentDims = ({ width, height }) => {
  const dp = PixelRatio.get();
  const dpi = dp * dpBase;
  const w = PixelRatio.getPixelSizeForLayoutSize(width);
  const h = PixelRatio.getPixelSizeForLayoutSize(height);
  return {
    w: {
      px: w,
      in: roundTo(w / dpi, 1),
    },
    h: {
      px: h,
      in: roundTo(h / dpi, 1),
    },
  };
};

const useComponentSize = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const onLayout = useCallback((event) => {
    setSize(event.nativeEvent.layout);
  }, []);

  return [
    {
      ...size,
      ...calcComponentDims(size),
    },
    onLayout,
  ];
};

export { useComponentSize };
