import { DateInput } from '@components/DateInput';
import { isBlank } from '@utils/stringHelper';
import { Button, FormControl, Modal, useDisclose } from 'native-base';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

const ModalForm = forwardRef(({ currentScale, onCustomChange }, ref) => {
  const [from, setFrom] = useState(currentScale.from);
  const [to, setTo] = useState(currentScale.to);
  const { isOpen, onOpen, onClose } = useDisclose();

  useImperativeHandle(ref, () => ({
    onClose,
    onOpen,
  }));

  return (
    <Modal isOpen={isOpen} avoidKeyboard onClose={onClose}>
      <Modal.Content maxWidth="260" placement="center">
        <Modal.CloseButton />
        <Modal.Header>Custom</Modal.Header>
        <Modal.Body>
          <FormControl>
            <FormControl.Label>From</FormControl.Label>
            <DateInput value={from} onChange={setFrom} />
          </FormControl>
          <FormControl mt="3">
            <FormControl.Label>To</FormControl.Label>
            <DateInput value={to} onChange={setTo} />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" mr="1" onPress={onClose}>
              Cancel
            </Button>
            <Button
              onPress={() => onCustomChange(from, to)}
              isDisabled={isBlank(from) || isBlank(to)}
            >
              Confirm
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
});

export { ModalForm };
