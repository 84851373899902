import { Text, Spinner } from 'native-base';
import React, { memo, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Platform } from 'react-native';
import { isBlank } from '@utils/stringHelper';
import { isLoaded } from 'expo-font';
const Label = memo(
  ({ widget, setWidgetPropValue, size, limits: { fillColor } }) => {
    const {
      text,
      text_color,
      background_color,
      alignment,
      font_type,
      font_size,
    } = widget;
    const fonttype = !font_type ? 'Arial' : font_type;
    if (!isLoaded(fonttype)) {
      return <Spinner />;
    }
    let verticalAlignment = 'center';
    let horizontalAlignment = 'center';

    if (!isBlank(alignment)) {
      const alignmentParts = alignment.split('|');
      (verticalAlignment = alignmentParts[0]),
        (horizontalAlignment = alignmentParts[1]);
    }

    useEffect(() => {
      setWidgetPropValue('isMounted', true);
    }, []);

    const isWeb = Platform.OS === 'web';

    const customViewStyle = {
      backgroundColor:
        background_color === undefined ? '#262a33' : background_color,
      justifyContent: verticalAlignment,
    };

    const customTextStyle = {};

    if (isWeb) {
      customViewStyle.lineHeight = 'auto';
      customTextStyle.lineHeight = '90%';
    }
    
    const fontSizeNumber =
      font_size == 'small' ? 0.7 : font_size == 'large' ? 0.1 : 0.5;

    const calculateFontSize = () => {
      const letterWidthReference = 0.5476;
      let calculateWidth = size.width / (text.length * letterWidthReference);
      let result = calculateWidth - calculateWidth * fontSizeNumber;

      if (result > size.height) {
        result = size.height;
      }

      return result;
    };

    return (
      <View style={[styles.container, customViewStyle]}>
        <Text
          fontSize={calculateFontSize()}
          color={!text_color ? '#3b82f6' : text_color}
          fontFamily={fonttype}
          textAlign={horizontalAlignment}
          style={[styles.text, customTextStyle]}
        >
          {text}
        </Text>
      </View>
    );
  }
);

export { Label };

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#262a33',
    width: '100%',
    height: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  text: {
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 3,
    textShadowColor: '#171717',
    position: 'relative',
  },
});
