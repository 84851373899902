import { configContentEditable } from '@utils/contenteditableHelper';
import { Box, HStack, Icon, IconButton, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';
import AntDesign from '@expo/vector-icons/AntDesign';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { useRoute } from '@react-navigation/native';
import { useGlobalContext } from '@hooks/useGlobalContext';

function PanelHeader({
  name: currentName,
  type,
  isReadOnly,
  onPanelNameChange,
  onViewTypeChange,
}) {
  const [name, setName] = useState(currentName);
  const route = useRoute();
  const { currentViewType } = useGlobalContext();

  useEffect(() => {
    configContentEditable();
  }, []);

  if (isReadOnly) {
    return <Text fontSize="xl">{name}</Text>;
  }

  return (
    <>
      <Box flex={1}>
        <Text fontSize="xl" flex={1}>
          <ContentEditable
            html={name}
            onChange={(ev) => {
              const value = sanitizeHtml(ev.target.value);
              onPanelNameChange(value);
              setName(value);
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                ev.target.blur();
                ev.preventDefault();
              }
            }}
            data-max-length="64"
            data-placeholder="Untitled"
            style={{
              padding: '2px 60px 2px 2px',
              minWidth: 230,
            }}
          />
        </Text>
      </Box>
      <HStack ml={4} space={1}>
        <IconButton
          icon={<Icon as={MaterialCommunityIcons} name="monitor" size="lg" />}
          colorScheme={currentViewType !== 'M' ? 'primary' : 'secondary'}
          onPressOut={() => onViewTypeChange('W')}
        />
        <IconButton
          icon={<Icon as={AntDesign} name="mobile1" size="lg" />}
          colorScheme={currentViewType === 'M' ? 'primary' : 'secondary'}
          onPressOut={() => onViewTypeChange('M')}
        />
      </HStack>
    </>
  );
}

export { PanelHeader };
