import { SevenSegmentIcon } from '@components/SevenSegmentIcon';
import Entypo from '@expo/vector-icons/Entypo';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { useGlobalContext } from '@hooks/useGlobalContext';
import { useHeaderHeight } from '@react-navigation/elements';
import { StackActions, useLinkTo } from '@react-navigation/native';
import { bodyBgColor, widgetBgColor } from '@utils/customTheme';
import { createPanel, generatePanel } from '@utils/panel';
import { groupBy } from 'lodash';
import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Icon,
  IconButton,
  Modal,
  Radio,
  SectionList,
  StatusBar,
  Text,
  VStack,
  View,
  useDisclose,
} from 'native-base';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const Item = ({ channel_id, field, name, selGauge, selLine, setFields }) => {
  const [selectionGauge, setSelectionGauge] = useState(selGauge);
  const [selectionLine, setSelectionLine] = useState(selLine);

  useEffect(() => {
    setFields((currentFields) =>
      currentFields.map((i) => {
        if (i.channel_id === channel_id && i.field === field) {
          return {
            ...i,
            selGauge: selectionGauge,
            selLine: selectionLine,
          };
        }
        return i;
      })
    );
  }, [selectionGauge, selectionLine]);

  const handleGaugeSelection = useCallback(() => {
    let selection = 0;
    if (selectionGauge === 0) selection = 1;
    if (selectionGauge === 1) selection = 2;
    setSelectionGauge(selection);
  }, [selectionGauge]);

  const handleLineSelection = useCallback(() => {
    const selection = selectionLine === 0 ? 1 : 0;
    setSelectionLine(selection);
  }, [selectionLine]);

  return (
    <HStack
      pl={3}
      pr={1}
      py={1}
      space={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <HStack space={1} flex={1} flexGrow={1} alignItems="center">
        <Text>{field}</Text>
        <Text>-</Text>
        <Text>{name}</Text>
      </HStack>
      <HStack>
        <IconButton
          colorScheme={selectionGauge > 0 ? 'info' : 'secondary'}
          isHovered={false}
          isPressed={false}
          isFocused={false}
          isFocusVisible={false}
          icon={
            selectionGauge === 2 ? (
              <SevenSegmentIcon size="lg" />
            ) : (
              <Icon as={Entypo} size="lg" name="gauge" />
            )
          }
          onPressOut={handleGaugeSelection}
        />
        <IconButton
          colorScheme={selectionLine > 0 ? 'info' : 'secondary'}
          isHovered={false}
          isPressed={false}
          isFocused={false}
          isFocusVisible={false}
          icon={
            <Icon as={MaterialCommunityIcons} size="lg" name="chart-line" />
          }
          onPressOut={handleLineSelection}
        />
      </HStack>
    </HStack>
  );
};

const SectionHeader = ({ channel_id, channel_name }) => {
  return (
    <VStack space={1} px={2} py={5} bgColor={widgetBgColor.dark}>
      <Text color="secondary.400">{`Channel ID: ${channel_id}`}</Text>
      <Text color="secondary.400">{channel_name}</Text>
    </VStack>
  );
};

const PanelWizardStepTwo = ({
  navigation,
  routes,
  panel,
  fields,
  setFields,
}) => {
  const headerHeight = useHeaderHeight();
  const { isOpen, onOpen, onClose } = useDisclose();
  const linkTo = useLinkTo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSingleLine, setIsSingleLine] = useState(true);
  const { setCurrentViewType } = useGlobalContext();

  const memoizedItems = useMemo(() => {
    const grouped = groupBy(fields, 'channel_id');
    return Object.keys(grouped).map((key) => {
      const data = grouped[key];
      const { channel_id, channel_name } = data[0];
      return {
        title: {
          channel_id,
          channel_name,
        },
        data,
      };
    });
  }, [fields]);

  const selectedGauges = useMemo(
    () => fields.filter((field) => field.selGauge > 0),
    [fields]
  );

  const selectedLines = useMemo(
    () => fields.filter((field) => field.selLine > 0),
    [fields]
  );

  const handleNextStep = async () => {
    if (!isOpen && selectedLines.length > 1) {
      setIsSingleLine(true);
      return onOpen();
    }
    setIsSubmitting(true);
    const { data: newPanel } = await createPanel({
      ...panel,
      viewTypes: ['M'],
      wizard: true
    });
    await generatePanel(
      newPanel.id,
      {
        isSingleLine,
        fields: fields.filter(
          (field) => field.selGauge > 0 || field.selLine > 0
        ),
      },
      'M'
    );
    StackActions.pop(2);
    navigation.dispatch(
      StackActions.replace('PanelPreview', {
        panel_id: newPanel.id,
      })
    );
  };

  return (
    <View h="100%">
      <SectionList
        mt={`${headerHeight}px`}
        sections={memoizedItems}
        keyExtractor={(item) => `${item.channel_id}-${item.field}`}
        renderItem={({ item }) => <Item {...item} setFields={setFields} key={`${item.channel_id}-${item.field}`} />}
        renderSectionHeader={({ section: { title } }) => (
          <SectionHeader {...title} />
        )}
      />
      <Box px={3} pt={5} mt={3} pb={4} bgColor={widgetBgColor.dark}>
        <Button
          colorScheme="primary"
          onPress={handleNextStep}
          isLoadingText="Submitting"
          isLoading={isSubmitting}
          isDisabled={selectedGauges.length + selectedLines.length === 0}
        >
          SUBMIT
        </Button>
      </Box>
      <Center>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header>What would you prefer?</Modal.Header>
            <Modal.Body>
              <Radio.Group
                size="sm"
                pr={2}
                defaultValue="1"
                onChange={(value) => setIsSingleLine(value === '1')}
              >
                <Radio
                  alignItems="flex-start"
                  _text={{
                    ml: '1',
                    fontSize: 'sm',
                  }}
                  my="1"
                  value="1"
                >
                  One multi-line chart with all the selected fields
                </Radio>
                <Radio
                  alignItems="flex-start"
                  _text={{
                    ml: '1',
                    fontSize: 'sm',
                  }}
                  my="1"
                  value="0"
                >
                  Multiple single-line charts for each selected fields
                </Radio>
              </Radio.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="unstyled" mr="1" onPress={onClose}>
                Cancel
              </Button>
              <Button
                onPress={() => {
                  handleNextStep();
                  onClose();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </Center>
    </View>
  );
};

export { PanelWizardStepTwo };
