import { Platform } from 'react-native';

/*
https://docs.expo.dev/guides/deep-linking/

adb shell dumpsys package d | grep "iot"

adb shell am start -d iothi://

https://10.0.2.2:19006/panels/416/preview

https://107hi.io:9001/panels/416/preview

https://107hi.io/panels/416/preview

iothi://107hi.io/panels/416/preview
*/

const launchAppOnAndroidDevice = (appScheme, appPackage, path) => {
  const url = `intent://${path}/#Intent;scheme=${appScheme};package=${appPackage};end`;
  window.location.replace(url);
  return url;
};

const launchAppOnAppleDevice = () => {};

const launchAppOnDevice = (appScheme, appPackage, path) => {
  if (Platform.OS === 'ios') {
    return launchAppOnAppleDevice();
  } else {
    return launchAppOnAndroidDevice(appScheme, appPackage, path);
  }
};

export { launchAppOnAndroidDevice, launchAppOnAppleDevice, launchAppOnDevice };
