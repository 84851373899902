import { useAuth } from '@hooks/useAuth';
import { useFocusEffect } from '@react-navigation/native';
import {
  TransitionPresets,
  createStackNavigator,
} from '@react-navigation/stack';
import Constants from 'expo-constants';
import { useBreakpointValue } from 'native-base';
import React, { useCallback, useState } from 'react';
import { PanelWizardStepOne } from './PanelWizardStepOne';
import { PanelWizardStepTwo } from './PanelWizardStepTwo';

const TransitionScreenOptions = {
  ...TransitionPresets.SlideFromRightIOS,
};

const Stack = createStackNavigator();

function PanelWizardScreen({ navigation }) {
  const isLargeScreen = useBreakpointValue({
    md: true,
  });
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [panel, setPanel] = useState(null);
  const [fields, setFields] = useState([]);

  useFocusEffect(
    useCallback(() => {
      setCurrentStepIndex(1);
      return () => {
        setPanel(null);
        setFields([]);
      };
    }, [navigation])
  );

  const steps = [
    <Stack.Screen
      key={1}
      name="PanelWizardStepOne"
      options={{
        headerTitle: '',
      }}
    >
      {(props) => (
        <PanelWizardStepOne
          {...props}
          panel={panel}
          setPanel={setPanel}
          fields={fields}
          setFields={setFields}
          onNextStep={handleNextStep}
        />
      )}
    </Stack.Screen>,
    <Stack.Screen
      key={2}
      name="PanelWizardStepTwo"
      options={{
        headerTitle: panel?.name,
      }}
    >
      {(props) => (
        <PanelWizardStepTwo
          {...props}
          panel={panel}
          fields={fields}
          setFields={setFields}
        />
      )}
    </Stack.Screen>,
  ];

  const handleNextStep = () => {
    const stepIndex = currentStepIndex + 1;
    navigation.navigate(steps[stepIndex - 1].props.name);
    setCurrentStepIndex(stepIndex);
  };

  return (
    <Stack.Navigator
      screenOptions={{
        unmountInactiveRoutes: true,
        unmountOnBlur: true,
        title: Constants.manifest.extra.pageTitle,
        headerTransparent: true,
        ...TransitionScreenOptions,
        headerShown: !isLargeScreen,
      }}
      screenListeners={(props) => ({
        state: (e) => {
          setCurrentStepIndex(e.data.state.index + 1);
        },
      })}
    >
      {steps}
    </Stack.Navigator>
  );
}

export { PanelWizardScreen };
