import AntDesign from '@expo/vector-icons/AntDesign';
import { isBlank } from '@utils/stringHelper';
import { colord } from 'colord';
import { Button, Icon, Text, VStack, useColorMode } from 'native-base';
import React, { memo, useEffect } from 'react';

const ButtonGroup = memo(
  ({
    widget,
    currentValue,
    onCurrentValueChange,
    isReadOnly,
    isDisabled,
    setWidgetPropValue,
  }) => {
    const { options } = widget;
    const { colorMode } = useColorMode();

    useEffect(() => {
      if (isReadOnly) {
        setWidgetPropValue('isMounted', true);
      }
    }, [isReadOnly]);

    return options.length > 0 ? (
      <Button.Group
        isAttached
        mx={{
          base: 'auto',
          md: 0,
        }}
        size="sm"
        flexWrap="wrap"
        justifyContent="center"
        isDisabled={isDisabled || isReadOnly}
      >
        {options.map((option) => (
          <Button
            key={option.id}
            backgroundColor={`${option.color || '#3b82f6'}${
              currentValue?.toString() === option?.value?.toString() &&
              !isBlank(currentValue)
                ? ':alpha.20'
                : ''
            }`}
            _disabled={{
              opacity: 100,
            }}
            _text={{
              fontWeight: 'medium',
            }}
            leftIcon={
              currentValue?.toString() === option?.value?.toString() &&
              !isBlank(currentValue) ? (
                <Icon as={AntDesign} name="checkcircleo" />
              ) : null
            }
            onPress={() => onCurrentValueChange(option.value)}
          >
            {option.text || 'No text'}
          </Button>
        ))}
      </Button.Group>
    ) : (
      <Text fontWeight="medium">No options</Text>
    );
  }
);

export { ButtonGroup };
