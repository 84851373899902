import { NavigationFallback } from '@components/NavigationFallback';
import { useAuth } from '@hooks/useAuth';
import { refreshToken } from '@utils/user';
import React, { useEffect } from 'react';
import sleep from 'sleep-promise';
import { Platform } from 'react-native';

function SplashScreen() {
  const { setIsLoading, setIsAuthenticated, fetchUserData, setIsAuthorize, authorize } = useAuth();

  useEffect(() => {
    async function fetchRefreshToken() {
      try {
        let isAuthorize = false;   
        if (Platform.OS === "web"){
          isAuthorize = window.location.pathname.toLocaleLowerCase().indexOf('/authorize') === 0 
          setIsAuthorize(isAuthorize)
         
        }
        // console.log('Redirecting...');
        await refreshToken();
        await fetchUserData();
        await sleep(1000);
        if (!isAuthorize){      
          setIsAuthenticated(true);
        }else{
          await authorize();
        } 
        
      } catch (err) {}
      setIsLoading(false);
    }
    fetchRefreshToken();
  }, []);

  return <NavigationFallback />;
}

export { SplashScreen };
