import { useForceUpdate } from '@hooks/useForceUpdate';
import { useColorMode } from 'native-base';
import React, { useEffect, useState } from 'react';
import { SimpleThermometer } from './Thermometer';

const showTickValuesFn = (size, breakpoints) => size.w.in >= 1.5;

const Thermometer = ({
  widgetRef,
  widget,
  size,
  currentValue,
  setWidgetPropValue,
  limits: { lowerLimit, upperLimit, fillColor },
}) => {
  const { unit, decimal_places, breakpoints } = widget;
  const { colorMode } = useColorMode();
  const [isLoading, setIsLoading] = useState(true);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const showTickValues = showTickValuesFn(size, breakpoints);

  useEffect(() => {
    setWidgetPropValue('isMounted', true);
  }, []);

  const dataSource = {
    chart: {
      showValue: '1',
      lowerLimit: lowerLimit?.toString(),
      upperLimit: upperLimit?.toString(),
      valueFontBold: 0,
      valueFontSize: 12,
      labelFontSize: 12,
      numberSuffix: unit,
      theme: colorMode == 'dark' ? 'candy' : 'fusion',
      adjustTM: '0',
      ticksOnRight: '0',
      tickMarkDistance: '5',
      tickValueDistance: '2',
      majorTMNumber: '9',
      majorTMHeight: '12',
      minorTMNumber: '4',
      minorTMHeight: '7',
      tickValueStep: '2',
      showTickMarks: showTickValues,
      showTickValues: showTickValues,
      chartTopMargin: '20',
      chartBottomMargin: '15',
      thmFillColor: fillColor,
      decimals: decimal_places ?? 1,
      forceDecimals: 1,
      valuePadding: '10',
      gaugeFillAlpha: currentValue === null ? 0 : 100,
      exportEnabled: '1',
      exportShowMenuItem: '0',
    },
    value: currentValue,
  };

  return (
    <SimpleThermometer
      ref={widgetRef}
      forceUpdate={forceUpdate}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      dataSource={dataSource}
      size={size}
    />
  );
};

export { Thermometer };
