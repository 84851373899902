import { apiClient } from './apiClient';
import { readDataFromAllFields } from './thingSpeakAPI';

const getSources = async () => {
  try {
    let { data: sources } = await apiClient.get('sources');

    const promises = sources.map((source) =>
      readDataFromAllFields(source.channel_id, source.read_api_key)
    );
    const channels = await Promise.all(promises);
    sources = sources.map((source) => {
      const channel = channels.find(
        (channel) => channel.id === source.channel_id
      );
      return {
        ...source,
        allFields: channel.allFields,
        channel_name: channel.channel_name,
      };
    });

    return {
      data: sources,
    };
  } catch (e) {
    console.info(e);
  }
};

const getSource = async (source_id) => {
  try {
    const { data: source } = await apiClient.get(`sources/${source_id}`);
    const channel = await readDataFromAllFields(
      source.channel_id,
      source.read_api_key
    );
    return {
      data: {
        ...source,
        allFields: channel.allFields,
        channel_name: channel.channel_name,
      },
    };
  } catch (e) {
    console.info(e);
  }
};

const createSource = (data) => apiClient.post('sources/create', data);

const saveSource = (source_id, data) =>
  apiClient.put(`sources/${source_id}`, data);

const saveSources = (data) => apiClient.post('sources', data);

const deleteSource = (source_id) => apiClient.delete(`sources/${source_id}`);

export {
  getSources,
  getSource,
  createSource,
  saveSource,
  saveSources,
  deleteSource,
};
