import { GridStackContainer } from '@components/GridStackContainer';
import { PanelHeader } from '@components/PanelHeader';
import { Widget } from '@components/Widget';
import { useGridStack } from '@hooks/useGridStack';
import { toInteger } from 'lodash';
import { Button, Icon, IconButton, Tooltip, VStack, ZStack } from 'native-base';
import React, { forwardRef, useImperativeHandle } from 'react';
import Feather from '@expo/vector-icons/Feather';
import { bodyBgColor } from '@utils/customTheme';
import { useGlobalContext } from '@hooks/useGlobalContext';

const getGSItemProps = (subtype_tag) => {
  switch (subtype_tag) {
    case 'CY':
      return { minH: 3, minW: 3 };
    case 'TH':
      return { minH: 3, minW: 1 };
    case 'LA':
      return { minH: 1, minW: 1 };
    case 'DC':
      return { minH: 1, minW: 1 };
    case 'RA':
      return { minH: 1, minW: 1 };
    case 'RR':
      return { minH: 1, minW: 1 };
    default:
      return { minH: 2, minW: 1 };
  }
};

const GridLayout = forwardRef(
  (
    {
      panel,
      onPanelNameChange,
      onViewTypeChange,
      widgets,
      setWidgets,
      currentScale,
      pollingDelay,
      pollingEnabled,
      setPollingEnabled,
      editItem,
      removeItem,
      copyItem,
    },
    ref
  ) => {
    const { alertDialog, currentViewType } = useGlobalContext();
    const gridItemFieldName =
      currentViewType === 'M' ? 'mobile_grid_item' : 'web_grid_item';

    const handleGridChange = (e, items) => {
      if (!items) return;
      setWidgets((currentWidgets) =>
        currentWidgets.map((widget) => {
          const item = items.find((item) => toInteger(item.id) === widget.id);
          if (item) {
            return {
              ...widget,
              [gridItemFieldName]: {
                ...widget[gridItemFieldName],
                x: item.x,
                y: item.y,
                h: item.h,
                w: item.w,
              },
            };
          }
          return widget;
        })
      );
    };

    const handleWidgetDelete = (widget) => {
      alertDialog.current.show(
        'Delete',
        'Are you sure that you want to delete this record?',
        <Button
          onPress={() => {
            alertDialog.current.close();
            removeItem(widget.id);
            removeWidget(widget.id);
          }}
        >
          Yes
        </Button>
      );
    };

    const { gridEl, serializeData, removeWidget } = useGridStack(
      widgets,
      {
        column: currentViewType === 'M' ? 12 : 24, 
        minRow: 1,
        cellHeight: 35,
        disableOneColumnMode: true,
        margin: '5px',
        float: true,
      },
      {
        onChange: handleGridChange,
        onResizeStart: () => setPollingEnabled(false),
        onResizeStop: () => setPollingEnabled(true),
        currentViewType,
      }
    );

    useImperativeHandle(ref, () => ({
      serializeData,
    }));

    return (
      <GridStackContainer
        innerRef={gridEl}
        widgets={widgets}
        header={
          <PanelHeader
            {...panel}
            onPanelNameChange={onPanelNameChange}
            onViewTypeChange={onViewTypeChange}
          />
        }
      >
        {widgets.map((widget) => (
          <div
            key={widget.id}
            className="grid-stack-item gs-item-autohide"
            gs-id={widget.id}
            gs-x={widget[gridItemFieldName]?.x}
            gs-y={widget[gridItemFieldName]?.y}
            gs-h={widget[gridItemFieldName]?.h}
            gs-w={widget[gridItemFieldName]?.w}
            gs-min-h={getGSItemProps(widget.subtype_tag).minH}
            gs-min-w={getGSItemProps(widget.subtype_tag).minW}
          >
            <div className="grid-stack-item-content">
              <ZStack
                _light={{ bg: 'widgetBgColorLight' }}
                _dark={{ bg: 'widgetBgColorDark' }}
                h="100%"
                w="100%"
              >
                <Widget
                  widget={widget}
                  setWidgets={setWidgets}
                  currentScale={currentScale}
                  pollingDelay={pollingDelay}
                  pollingEnabled={pollingEnabled}
                  isDnD={true}
                />
                <div className="action-bar">
                  <VStack
                    space={2}
                    p={1}
                    bgColor={bodyBgColor.dark}
                    opacity={0.8}
                    rounded={2}
                    roundedLeft={0}
                    h="100%"
                  >
                    <Tooltip label="Delete" placement="bottom right">
                      <IconButton
                        size={'sm'}
                        p={0}
                        variant="unstyled"
                        onPressOut={() => handleWidgetDelete(widget)}
                        _icon={{
                          as: Feather,
                          name: 'x',
                          color: 'coolGray.300',
                        }}
                      />
                    </Tooltip>
                    <Tooltip label="Edit" placement="right">
                      <IconButton
                        size={'sm'}
                        p={0}
                        variant="unstyled"
                        onPressOut={() => editItem(widget.id)}
                        _icon={{
                          as: Feather,
                          name: 'edit-3',
                          color: 'coolGray.300',
                        }}
                      />
                    </Tooltip>
                    <Tooltip label="Create copy" placement="right">
                      <IconButton
                        size={'sm'}
                        p={0}
                        variant="unstyled"
                        onPressOut={() => copyItem(widget.id)}
                        _icon={{
                          as: Feather,
                          name: 'copy',
                          color: 'coolGray.300',
                        }}
                      />
                    </Tooltip>
                  </VStack>
                </div>
              </ZStack>
            </div>
          </div>
        ))}
      </GridStackContainer>
    );
  }
);

export { GridLayout };
