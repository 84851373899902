import { isBlank } from '@utils/stringHelper';
import { isInteger, isNaN } from 'lodash';
import { HStack, Slider, Text, VStack } from 'native-base';
import React, { memo, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

const HSlider = memo(
  ({ widget, currentValue = 0, onCurrentValueChange, isDisabled }) => {
    const { unit, range_min, range_max } = widget;
    const [selectedValue, setSelectedValue] = useState(currentValue);

    const handleValueChange = (value) => {
      if (isInteger(value)) onCurrentValueChange(value);
    };

    return (
      <VStack
        flex={1}
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
      >
        <HStack w="90%" alignItems="flex-end" justifyContent="space-between">
          <Text color="muted.500" fontSize={11}>
            {range_min}
          </Text>
          <Text fontSize="sm" fontWeight="medium" mb="5" style={styles.text}>
            {isNaN(selectedValue) || isBlank(selectedValue)
              ? 'NO VALUE'
              : `${selectedValue}${unit || ''}`}
          </Text>
          <Text color="muted.500" fontSize={11}>
            {range_max}
          </Text>
        </HStack>
        <Slider
          value={(selectedValue < range_min) ? range_min : (selectedValue > range_max) ? range_max : (isNaN(selectedValue) || isBlank(selectedValue) ? range_min : selectedValue) }
          minValue={isBlank(range_min) ? selectedValue : range_min}
          maxValue={isBlank(range_max) ? selectedValue : range_max}
          onChange={(v) => setSelectedValue(Math.floor(v))}
          onChangeEnd={handleValueChange}
          w="90%"
          size="md"
          step={1}
          isDisabled={isDisabled}
        >
          <Slider.Track
            _disabled={{
              _light: { bg: 'primary.300' },
            }}
          >
            <Slider.FilledTrack />
          </Slider.Track>
          <Slider.Thumb />
        </Slider>
      </VStack>
    );
  }
);

const styles = StyleSheet.create({
  text: {
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    textShadowColor: '#171717',
  },
});

export { HSlider };
