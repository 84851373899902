import { ChartWrapper } from '@components/ChartWrapper';
import { Flex } from 'native-base';
import React, { useEffect } from 'react';
import ReactFC from 'react-fusioncharts';

const SimpleGauge = ({ forceUpdate, dataSource, size }) => {
  return (
    <ChartWrapper
      middle
      render={({ adjustedHeight }) => {
        return (
          <div className="fc-container">
            <ReactFC
              key={forceUpdate}
              type="angulargauge"
              width="100%"
              height={adjustedHeight}
              dataFormat="json"
              dataSource={{
                ...dataSource,
                chart: {
                  ...dataSource.chart,
                  bgAlpha: '0',
                },
              }}
            />
          </div>
        );
      }}
    />
  );
};

export { SimpleGauge };
