import { useEffect, useState } from 'react';
import { Dimensions, PixelRatio, Platform } from 'react-native';
import { roundTo } from 'round-to';

const dpBase = Platform.OS === 'web' ? 100 : 160;

const calcWinDims = ({ width, height }) => {
  const dp = PixelRatio.get();
  const dpi = dp * dpBase;
  const w = PixelRatio.getPixelSizeForLayoutSize(width);
  const h = PixelRatio.getPixelSizeForLayoutSize(height);
  return {
    w: {
      px: w,
      in: roundTo(w / dpi, 1),
    },
    h: {
      px: h,
      in: roundTo(h / dpi, 1),
    },
  };
};

const orientation = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
};

const getOrientation = ({ width, height }) =>
  height >= width ? orientation.PORTRAIT : orientation.LANDSCAPE;

const initialWinDims = () => Dimensions.get('window');

const useWinDimensions = () => {
  const [winDims, setWinDims] = useState(initialWinDims);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setWinDims(window);
    });
    return () => {
      subscription.remove();
    };
  }, []);

  return {
    ...calcWinDims(winDims),
    deviceOrientation: getOrientation(winDims),
  };
};

export { dpBase, orientation, useWinDimensions };
