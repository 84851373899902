import { useAuth } from '@hooks/useAuth';
import { customMessages } from '@utils/customMessages';
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { useGlobalContext } from '@hooks/useGlobalContext';

import { bodyBgColor } from '@utils/customTheme';
import { Zocial, MaterialIcons } from '@expo/vector-icons';
import { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Center,
  FormControl,
  HStack,
  Heading,
  Input,
  Link,
  Text,
  VStack,
  useToast,
  Icon,
  Divider,
  useBreakpointValue,
  Hidden,
  Flex,
  Pressable,
} from 'native-base';
import React from 'react';
import * as Yup from 'yup';
import Constants from 'expo-constants';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { useCallback } from 'react';
import { Image, Platform } from 'react-native';
import { Container } from '@components/Container';

const validationSchema = Yup.object().shape({
  username: Yup.string().required(customMessages.isRequiredField),
  password: Yup.string().required(customMessages.isRequiredField),
  password2: Yup.string().required(customMessages.isRequiredField),
  first_name: Yup.string().required(customMessages.isRequiredField),
  last_name: Yup.string().required(customMessages.isRequiredField),
  email: Yup.string().required(customMessages.isRequiredField),
});
WebBrowser.maybeCompleteAuthSession();
function SignInScreen({ navigation, route }) {
  const linkTo = useLinkTo();
  const { signin, signUp, signinSocial, authorize, isAuthorize } = useAuth();
  const toast = useToast();
  const { progressDialog } = useGlobalContext();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [typeLoginSocial, setTypeLoginSocial] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [email, setEmail] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [requestGoogle, responseGoogle, handleGoogleSignIn] =
    Google.useAuthRequest({
      expoClientId:
        '633893719460-oiirtdlqvmgl9k6bgreca3jru911p77g.apps.googleusercontent.com',
      iosClientId:
        '633893719460-suflfu1sqqk4pcvam9gt5ldm6dv1dcig.apps.googleusercontent.com',
      androidClientId:
        '633893719460-cq15pdh9l3292lfm44j3b404jp2c0s7b.apps.googleusercontent.com',
      webClientId:
        '633893719460-suflfu1sqqk4pcvam9gt5ldm6dv1dcig.apps.googleusercontent.com',
    });
  const [requestFacebook, responseFacebook, handleFacebookSignIn] =
    Facebook.useAuthRequest({
      clientId: '810690430373107',
      androidClientId: '810690430373107',
      responseType: 'token',
    });
  const redirectScreen = '/panels';
  const isLargeScreen = useBreakpointValue({
    md: true,
  });

  useFocusEffect(
    useCallback(() => {
      setSubmitting(false);
    }, [navigation])
  );

  useEffect(() => {
    if (
      (typeLoginSocial === 'google' &&
        responseGoogle?.type !== undefined &&
        responseGoogle?.type !== 'success') ||
      (typeLoginSocial === 'facebook' &&
        responseFacebook?.type !== undefined &&
        responseFacebook?.type !== 'success')
    ) {
      progressDialog.current.close();
    }

    if (responseGoogle?.type === 'success') {
      const { authentication } = responseGoogle;
      signinSocial({
        access_token: authentication.accessToken,
        backend: 'google',
      })
        .then(async () => {
          await authorizeWeb();
          progressDialog.current.close();
        })
        .catch((error) => {
          progressDialog.current.close();
          toast.show({
            title: 'Sign-in Failed',
            status: 'warning',
            variant: 'subtle',
            placement: 'top',
            description: error.response?.data.error_description,
          });
        });
    }
    if (responseFacebook?.type === 'success') {
      const { authentication } = responseFacebook;
      signinSocial({
        access_token: authentication.accessToken,
        backend: 'facebook',
      })
        .then(async () => {
          await authorizeWeb();
          progressDialog.current.close();
        })
        .catch((error) => {
          progressDialog.current.close();
          toast.show({
            title: 'Sign-in Failed',
            status: 'warning',
            variant: 'subtle',
            placement: 'top',
            description: error.response?.data.error_description,
          });
        });
    }
  }, [responseGoogle, responseFacebook]);

  const clearErrors = async (field) => {
    if (errors[field]) {
      const NewErrors = { ...errors };
      delete NewErrors[field];
      setErrors(NewErrors);
    }
    toast.closeAll();
  };
  const authorizeWeb = async () => {
    if (isAuthorize) {
      await authorize();
    } else {
      linkTo(redirectScreen);
    }
  };

  const handleFormSubmit = async () => {
    setTypeLoginSocial(null);
    setSubmitting(true);
    let values = {
      username: username,
      password: password,
      password2: password2,
      email: email,
      first_name: first_name,
      last_name: last_name,
    };

    if (!newUser) {
      return await signin(values)
        .then(authorizeWeb)
        .catch((error) => {
          toast.show({
            title: 'Sign-in Failed',
            placement: 'top',
            description: error.response.data.error_description,
          });
          setSubmitting(false);
        });
    } else
      return await signUp(values)
        .then(() => {
          toast.show({
            placement: 'top',

            render: () => {
              return (
                <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
                  You have been registered successfully. Please login to
                  continue.
                </Box>
              );
            },
          });

          setNewUser(!newUser);
          setSubmitting(false);
        })
        .catch((error) => {
          toast.show({
            title: 'Sign-up Failed',
            placement: 'top',
          });
          setSubmitting(false);
          setErrors(error.response.data);
        });
  };

  return (
    <>
      <Container
        bgColor={bodyBgColor.dark}
        pb={4}
        mt={{ base: 5, md: Platform.OS == 'web' ? 16 : 2 }}
      >
        {Platform.OS !== 'web' && (
          <Center my="3">
            <Image source={require('@assets/top-bar.png')} alt="107HI" />
          </Center>
        )}
        <Flex
          bg="containerBgColorDark"
          shadow={1}
          borderRadius={4}
          pt={{ base: 4, md: 10 }}
          pb={{ base: 8, md: 10 }}
          px={{ base: 4, md: 14 }}
          mx={{ base: 1.5, md: 0 }}
          justifyContent="space-evenly"
          alignItems={{ base: 'center', md: 'center' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <VStack space={3} w="100%" maxW={340}>
            <Fragment>
              {!newUser && Platform.OS === 'web' && (
                <Hidden till="md">
                  <Heading>Sign in</Heading>
                </Hidden>
              )}
            </Fragment>
            {newUser && (
              <FormControl isInvalid={'first_name' in errors}>
                <FormControl.Label>First name</FormControl.Label>
                <Input
                  placeholder="First name"
                  value={first_name}
                  autoCapitalize="none"
                  autoComplete="off"
                  size="md"
                  onChangeText={(text) => setFirst_name(text)}
                  onFocus={() => clearErrors('first_name')}
                />
                <FormControl.ErrorMessage>
                  {errors.first_name}
                </FormControl.ErrorMessage>
              </FormControl>
            )}
            {newUser && (
              <FormControl isInvalid={'last_name' in errors}>
                <FormControl.Label>Last name</FormControl.Label>
                <Input
                  placeholder="Last name"
                  value={last_name}
                  autoCapitalize="none"
                  autoComplete="off"
                  size="md"
                  onChangeText={(text) => setLast_name(text)}
                  onFocus={() => clearErrors('last_name')}
                />
                <FormControl.ErrorMessage>
                  {errors.last_name}
                </FormControl.ErrorMessage>
              </FormControl>
            )}
            <FormControl isInvalid={'username' in errors}>
              <FormControl.Label>Username</FormControl.Label>
              <Input
                placeholder={newUser ? 'Username' : 'Username or Email'}
                value={username}
                autoCapitalize="none"
                InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />} 
                autoComplete="off"
                size="md"
                py={{ base: 2, md: newUser ? 2 : 4 }}
                onChangeText={(text) => setUsername(text)}
                onFocus={() => clearErrors('username')}
              />
              <FormControl.ErrorMessage>
                {errors.username}
              </FormControl.ErrorMessage>
            </FormControl>
            {newUser && (
              <FormControl isInvalid={'email' in errors}>
                <FormControl.Label>E-mail</FormControl.Label>
                <Input
                  placeholder="email"
                  value={email}
                  autoCapitalize="none"
                  autoComplete="off"
                  size="md"
                  onChangeText={(text) => setEmail(text)}
                  onFocus={() => clearErrors('email')}
                />
                <FormControl.ErrorMessage>
                  {errors.email}
                </FormControl.ErrorMessage>
              </FormControl>
            )}
            <FormControl isInvalid={'password' in errors}>
              <FormControl.Label>Password</FormControl.Label>
              <Input
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                InputRightElement={
                  <Pressable onPress={() => setShowPassword(!showPassword)}>
                    <Icon
                      as={
                        <MaterialIcons
                          name={showPassword ? 'visibility' : 'visibility-off'}
                        />
                      }
                      size={5}
                      mr="2"
                      color="muted.400"
                    />
                  </Pressable>
                }
                value={password}
                size="md"
                py={{ base: 2, md: newUser ? 2 : 4 }}
                onChangeText={(text) => setPassword(text)}                
                onFocus={() => clearErrors('password')}
              />
              <FormControl.ErrorMessage>
                {errors.password}
              </FormControl.ErrorMessage>
            </FormControl>
            {newUser && (
              <FormControl isInvalid={'password2' in errors}>
                <FormControl.Label>Confirm Password</FormControl.Label>
                <Input
                  placeholder="Password"
                  type={showPassword2 ? 'text' : 'password'}
                  InputRightElement={
                    <Pressable onPress={() => setShowPassword2(!showPassword2)}>
                      <Icon
                        as={
                          <MaterialIcons
                            name={
                              showPassword2 ? 'visibility' : 'visibility-off'
                            }
                          />
                        }
                        size={5}
                        mr="2"
                        color="muted.400"
                      />
                    </Pressable>
                  }
                  value={password2}
                  size="md"
                  onChangeText={(text) => setPassword2(text)}
                  onFocus={() => clearErrors('password2')}
                />
                <FormControl.ErrorMessage>
                  {errors.password2}
                </FormControl.ErrorMessage>
              </FormControl>
            )}
            {!newUser && (
              <Link
                _text={{
                  fontSize: 'xs',
                  fontWeight: '500',
                  color: 'primary.500',
                }}
                alignSelf="flex-end"
                mt="1"
                onPress={() => linkTo('/passwordreset')}
              >
                Forget Password?
              </Link>
            )}
            <Button
              mt="2"
              size={newUser ? 'md' : 'lg'}
              onPress={handleFormSubmit}
              isLoading={submitting}
            >
              {!newUser ? 'Sign in' : 'Register'}
            </Button>
            <HStack mt="2" justifyContent="center">
              <Text fontSize="sm">
                {!newUser ? "I'm a new user." : 'Already have an account?'}{' '}
              </Text>
              <Link
                onPress={() => setNewUser(!newUser)}
                _text={{
                  fontWeight: 'medium',
                  fontSize: 'sm',
                  color: 'primary.500',
                }}
              >
                {!newUser ? 'Sign up' : 'Sign in'}
              </Link>
            </HStack>
          </VStack>
          {!newUser && (
            <Fragment>
              <Divider
                orientation={isLargeScreen ? 'vertical' : 'horizontal'}
                my={{ base: 6, md: 0 }}
              />
              <VStack space={{ base: 4, md: 6 }} w="100%" maxW={340}>
                <Button
                  disabled={!requestGoogle}
                  colorScheme="error"
                  size={'lg'}
                  leftIcon={<Icon as={Zocial} name="google" size="sm" />}
                  onPress={() => {
                    setTypeLoginSocial('google');
                    progressDialog.current.show();
                    handleGoogleSignIn();
                  }}
                >
                  Continue with Google
                </Button>
                <Button
                  disabled={!requestFacebook}
                  leftIcon={<Icon as={Zocial} name="facebook" size="sm" />}
                  size={'lg'}
                  onPress={() => {
                    setTypeLoginSocial('facebook');
                    progressDialog.current.show();
                    handleFacebookSignIn();
                  }}
                >
                  Continue with Facebook
                </Button>
              </VStack>
            </Fragment>
          )}
        </Flex>
      </Container>
    </>
  );
}

export { SignInScreen };
