import { useForceUpdate } from '@hooks/useForceUpdate';
import { useColorMode } from 'native-base';
import React, { useEffect, useState } from 'react';
import { SimpleHorizontal } from './Horizontal';
import { Platform } from 'react-native';

const showTickValuesFn = (size) => {
  const sizeLimit = 1.5;
  const sizeReference = Platform.OS === 'web' ? sizeLimit : sizeLimit / 2;
  return size.w.in >= sizeReference;
}

const Horizontal = ({
  widgetRef,
  widget,
  size,
  currentValue,
  setWidgetPropValue,
  limits: { lowerLimit, upperLimit, colorRange },
}) => {
  const { unit, decimal_places } = widget;
  const { colorMode } = useColorMode();
  const [isLoading, setIsLoading] = useState(true);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const showTickValues = showTickValuesFn(size);

  useEffect(() => {
    setWidgetPropValue('isMounted', true);
  }, []);

  const dataSource = {
    chart: {
      showValue: '1',
      lowerLimit: lowerLimit?.toString(),
      upperLimit: upperLimit?.toString(),
      numbersuffix: unit,
      gaugefillmix: '{dark-20},{light+70},{dark-10}',
      theme: colorMode == 'dark' ? 'candy' : 'fusion',
      adjustTM: '0',
      ticksOnRight: '0',
      tickMarkDistance: '5',
      tickValueDistance: '2',
      majorTMNumber: '9',
      majorTMHeight: '12',
      minorTMNumber: '4',
      minorTMHeight: '7',
      tickValueStep: '2',
      chartTopMargin: '12',
      chartBottomMargin: '0',
      showTickMarks: showTickValues,
      showTickValues: showTickValues,
      decimals: decimal_places ?? 1,
      forceDecimals: 1,
      exportEnabled: '1',
      exportShowMenuItem: '0',
    },
    colorRange: {
      color: colorRange.map((range) => ({
        ...range,
        minValue: range.minValue?.toString(),
        maxValue: range.maxValue?.toString(),
      })),
    },
    pointers: {
      pointer: [
        {
          value: currentValue,
          radius: currentValue === null ? '0' : '100%',
        },
      ],
    },
  };

  return (
    <SimpleHorizontal
      ref={widgetRef}
      forceUpdate={forceUpdate}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      dataSource={dataSource}
      size={size}
    />
  );
};

export { Horizontal };
