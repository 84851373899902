import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Modal,
  Button,
  Checkbox,
  Text,
  useDisclose,
  VStack,
} from 'native-base';

const NewPanelDialog = forwardRef(({ onConfirm }, ref) => {
  const { isOpen, onClose, onOpen } = useDisclose(false);
  const [groupValues, setGroupValues] = useState(['W', 'M']);

  useImperativeHandle(ref, () => ({
    close: onClose,
    show: onOpen,
  }));

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>New panel</Modal.Header>
        <Modal.Body>
          <VStack space={2}>
            <Text fontWeight="medium">
              What kind of device this panel is designed to be used at:
            </Text>
            <Checkbox.Group
              onChange={setGroupValues}
              value={groupValues}
              accessibilityLabel="choose option"
            >
              <Checkbox value="W" my={2}>
                Web
              </Checkbox>
              <Checkbox value="M">Mobile</Checkbox>
            </Checkbox.Group>
          </VStack>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" colorScheme="coolGray" onPress={onClose}>
              Cancel
            </Button>
            <Button
              isDisabled={groupValues.length === 0}
              onPress={() => onConfirm(groupValues)}
            >
              Confirm
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
});

export { NewPanelDialog };
