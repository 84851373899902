import React, { useEffect, useState } from 'react';
import { View  } from 'react-native';
import { useFonts } from 'expo-font';
import { Select, Item , Spinner} from 'native-base';

const FontList = [
  { label: 'Arial', value: 'Arial' },
  { label: 'Courier New', value: 'Courier New' },
  { label: 'Calibri', value: 'Calibri' },
  { label: 'Cambria', value: 'Cambria' },
  { label: 'Comic Sans', value: 'Comic Sans' },
  { label: 'Franklin Gothic', value: 'Franklin Gothic' },
  { label: 'Gabriola', value: 'Gabriola' },
  { label: 'Impact Regular', value: 'Impact Regular' },
  { label: 'Ink Free', value: 'Ink Free' },
  { label: 'LCD-Ultra', value: 'LCD-Ultra' },
  { label: 'Segoe Print', value: 'Segoe Print' },
  { label: 'Source Code', value: 'Source Code' },
  { label: 'Verdana', value: 'Verdana' },
  { label: 'Times New Roman', value: 'Times New Roman' },
];

const TextChangeFontType = ({ onChangeText, fontType }) => {
  
  useEffect(() => {
    handleFontType(!fontType ? 'Arial' : fontType);
  }, [fontType]);

  const [selectedFont, setSelectedFont] = useState('Times New Roman');

  const handleFontType = (fontType) => {
    setSelectedFont(fontType);
    onChangeText(fontType);
  };

  return (
    <View>
      <Select
        placeholder="Please select..."
        onValueChange={(itemValue) => handleFontType(itemValue)}
        selectedValue={selectedFont}
      >
        {FontList.map((font, index) => (
          <Select.Item
            label={font.label}
            key={index}
            value={font.value.toString()}
          />
        ))}
      </Select>
    </View>
  );
};

export { TextChangeFontType };
