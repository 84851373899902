import { Button, FormControl, Modal, Select, useDisclose } from 'native-base';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

function CopyOptionDialog({ target, widgets, subtype, onConfirm }, ref) {
  const { isOpen, onOpen, onClose } = useDisclose();
  const [source, setSource] = useState(null);

  const filteredWidgets = widgets.filter(
    (widget) => widget.options.length > 0 && widget.id !== target?.id
  );

  useImperativeHandle(ref, () => ({
    show: onOpen,
    close: onClose,
  }));

  const handleConfirm = () => {
    const selectedSource = widgets.find((i) => i.id.toString() === source);
    onConfirm(selectedSource);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content placement="center">
        <Modal.CloseButton />
        <Modal.Header>Add Option</Modal.Header>
        <Modal.Body>
          <FormControl>
            <FormControl.Label>Copy from widget</FormControl.Label>
            <Select placeholder="Please select..." onValueChange={setSource}>
              {filteredWidgets.map((widget) => (
                <Select.Item
                  key={widget.id}
                  label={
                    widget.display_name ||
                    widget.name ||
                    `${widget.subtype_name} ${
                      widget.type_name === widget.subtype_name
                        ? ''
                        : widget.type_name + ' '
                    }Widget`
                  }
                  value={widget.id.toString()}
                />
              ))}
            </Select>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" mr="1" onPress={onClose}>
              Cancel
            </Button>
            <Button onPress={handleConfirm}>Confirm</Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

const WrappedCopyOptionDialog = forwardRef(CopyOptionDialog);

export { WrappedCopyOptionDialog as CopyOptionDialog };
