import { useIsFocused } from '@react-navigation/native';
import React from 'react';
import { PanelList } from './PanelList';

const PanelListScreen = ({ route, navigation }) => {
  const isFocused = useIsFocused();

  if (!isFocused) {
    return null;
  }

  return <PanelList route={route} navigation={navigation} />;
};

export { PanelListScreen };
