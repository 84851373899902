import { createIcon } from 'native-base';
import React from 'react';
import { Path } from 'react-native-svg';

function SevenSegmentIcon(props) {
  const CustomIcon = createIcon({
    viewBox: '0 0 348 218',
    path: [
      <Path
        d="M367 1992l-77-77 78-78 77-77h590l78 78 77 77-78 78-77 77H445l-78-78zM2097 1992l-77-77 78-78 77-77h590l78 78 77 77-78 78-77 77h-590l-78-78zM177 1802l-77-77v-440l80-80 80-80 75 75 75 74v461l-72 72c-40 40-75 73-78 73s-40-35-83-78zM1142 1807l-72-72v-461l75-74 75-75 80 80 80 80v440l-78 78c-42 42-80 77-82 77-3 0-38-33-78-73zM1907 1802l-77-77v-440l80-80 80-80 75 75 75 74v461l-72 72c-40 40-75 73-78 73s-40-35-83-78zM2872 1807l-72-72v-461l75-74 75-75 80 80 80 80v440l-78 78c-42 42-80 77-82 77-3 0-38-33-78-73zM367 1172l-77-77 78-78 77-77h590l78 78 77 77-78 78-77 77H445l-78-78zM2097 1172l-77-77 78-78 77-77h590l78 78 77 77-78 78-77 77h-590l-78-78zM177 982l-77-77V465l80-80 80-80 75 75 75 74v461l-72 72c-40 40-75 73-78 73s-40-35-83-78zM1142 987l-72-72V454l75-74 75-75 80 80 80 80v440l-78 78c-42 42-80 77-82 77-3 0-38-33-78-73zM1907 982l-77-77V465l80-80 80-80 75 75 75 74v461l-72 72c-40 40-75 73-78 73s-40-35-83-78zM2872 987l-72-72V454l75-74 75-75 80 80 80 80v440l-78 78c-42 42-80 77-82 77-3 0-38-33-78-73zM367 352l-77-77 78-78 77-77h590l78 78 77 77-78 78-77 77H445l-78-78zM2097 352l-77-77 78-78 77-77h590l78 78 77 77-78 78-77 77h-590l-78-78zM1480 353c-92-47-87-180 7-220 102-42 202 58 160 160-28 65-104 93-167 60zM3210 353c-92-47-87-180 7-220 102-42 202 58 160 160-28 65-104 93-167 60z"
        transform="matrix(.1 0 0 -.1 0 218)"
      />,
    ],
  });
  return <CustomIcon {...props} />;
}

export { SevenSegmentIcon };
