import {
  Button,
  Text,
  Spinner,
  View,
} from 'native-base';
import React, { memo, useEffect, useState } from 'react';
import { StyleSheet, Platform } from 'react-native';

const PUSHBUTTON = memo(
  ({
    currentValue,
    onCurrentValueChange,
    widget,
    isProcessing,
    isWritable,
    size,
  }) => {
    const { object_color } = widget;

    const selected_color = !object_color ? '#3b82f6' : object_color;

    function hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const [, r, g, b] = result || shorthandRegex.exec(hex) || [];
      return result
        ? [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)]
        : [parseInt(r + r, 16), parseInt(g + g, 16), parseInt(b + b, 16)];
    }

    const hoverColor = `rgb(${hexToRgb(selected_color)
      .map((c) => Math.floor(c * 0.6))
      .join(',')})`;
    const borderColor = `rgb(${hexToRgb(selected_color)
      .map((c) => Math.floor(c * 1.4))
      .join(',')})`;
    const pressedColor = `rgb(${hexToRgb('#555')
      .map((c) => Math.floor(c * 0.1))
      .join(',')})`;      

    const buttonSize = Math.min(size.height, size.width);
    const [buttonStyle, setButtonStyle] = useState(null);

    const customStylePushButton = {
      width: buttonSize * 0.8,
      height: buttonSize * 0.8,
      borderColor: isProcessing ? pressedColor : borderColor,
      backgroundColor: isProcessing ? hoverColor : selected_color,
      shadowOpacity: isProcessing ? 0.1 : 0.9,
      borderWidth: isProcessing ? 1 : 2,
    };

    const customTextStyle = {
      maxWidth: buttonSize * 0.8,
    };

    const calculateFontSize = () => {
      const letterWidthReference = 0.5476;
      const text = widget.text;
      const buttonWidth = buttonSize * (Platform.OS === "web" ? 0.7 : 0.6);
      let calculateWidth = buttonWidth / (text.length * letterWidthReference);
      let result = calculateWidth - calculateWidth * 0.1;

      if (result > buttonWidth) {
        result = buttonWidth;
      }

      return result;
    };

    const handlePress = async () => {
      if (!isProcessing && isWritable) {
        const currentTime = new Date().getTime();
        onCurrentValueChange(currentTime);
      } else {
        setButtonStyle({
          ...customStylePushButton,
          borderColor: pressedColor,
          backgroundColor: hoverColor,
          shadowOpacity: 0.1,
          borderWidth: 1,
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setButtonStyle(customStylePushButton);
      }
    };

    return (
      <View justifyContent="center" alignItems="center" style={styles.mainArea}>
        <Button
          borderRadius="full"
          variant="unstyled"
          bg={selected_color}
          onPress={handlePress}
          disabled={isProcessing}
          style={[styles.pushButton, (buttonStyle) ? buttonStyle :customStylePushButton]}
        >
          {isProcessing ? (
            <Spinner size="sm" color={'#FFF'} />
          ) : (
            <Text
              fontSize={calculateFontSize()}
              style={[styles.text, customTextStyle]}
              textAlign={'center'}
              color={'#FFF'}
              numberOfLines={1}
            >
              {widget.text.length > 0 ? widget.text : ''}
            </Text>
          )}
        </Button>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  mainArea: {
    flex: 1,
    width: '100%',
  },
  text: {
    padding: 0,
  },
  pushButton: {
    aspectRatio: 1,
    shadowRadius: 20,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 3,
    transitionProperty: 'transform, box-shadow',
    transitionDuration: '0.3s',
    padding: 0,
  },
});

export { PUSHBUTTON };
