import { DeleteIconButton } from '@components/DeleteIconButton';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { isEditMode } from '@utils/screenHelper';
import { deleteWidgetField } from '@utils/widgetField';
import { Text } from 'native-base';
import React from 'react';

const Header = () => {
  const linkTo = useLinkTo();
  const route = useRoute();
  const { params } = route;
  return (
    <>
      <Text fontSize="xl">Widget Field</Text>
      {isEditMode(route) ? (
        <DeleteIconButton
          tip="Delete Field"
          onPress={() =>
            deleteWidgetField(
              params?.panel_id,
              params?.widget_id,
              params?.id
            ).then(() =>
              linkTo(`/panels/${params?.panel_id}/widgets/${params?.widget_id}`)
            )
          }
        />
      ) : null}
    </>
  );
};

export { Header };
