import _ from 'lodash';

const multiplier = (val) =>
  Math.pow(10, Math.abs(parseInt(val, 10)).toString().length);

const getRoundedLimit = (val, isLower) => {
  let limits = [0, val > 100 ? 0.1 : 0, 0.5, 1].map(
    (i) => i * multiplier(val) * (val < 0 ? -1 : 1)
  );
  let result = 0;
  if (isLower) {
    if (val > 0) limits = limits.reverse();
    for (const i of limits) {
      if (val > i) {
        result = i;
        break;
      }
    }
  } else {
    if (val < 0) limits = limits.reverse();
    for (const i of limits) {
      if (val <= i) {
        result = i;
        break;
      }
    }
  }
  return result;
};

const toNumeric = (str) => {
  if (str === null || str === undefined || str.trim().length === 0) {
    return null;
  }
  return _.toNumber(str);
};

export { multiplier, getRoundedLimit, toNumeric };
