import { useFocusEffect } from '@react-navigation/native';
import { customMessages } from '@utils/customMessages';
import { bodyBgColor, widgetBgColor } from '@utils/customTheme';
import { createPanel } from '@utils/panel';
import { createSource } from '@utils/source';
import { isBlank } from '@utils/stringHelper';
import { readChannelStatus, readDataFromAllFields } from '@utils/thingSpeakAPI';
import { capitalize, toInteger } from 'lodash';
import {
  Box,
  Button,
  Center,
  FormControl,
  HStack,
  Heading,
  Input,
  KeyboardAvoidingView,
  Link,
  Spinner,
  StatusBar,
  Text,
  VStack,
  View,
  useToast,
} from 'native-base';
import React, { useCallback, useState } from 'react';
import { Keyboard, Platform } from 'react-native';

const access = {
  UNDEFINED: 'undefined',
  PUBLIC: 'public',
  PRIVATE: 'private',
};

const PanelWizardStepOne = ({
  navigation,
  panel,
  setPanel,
  fields,
  setFields,
  onNextStep,
}) => {
  const [isValidChannel, setIsValidChannel] = useState(false);
  const [channelAccess, setChannelAccess] = useState(access.UNDEFINED);
  const [channelID, setChannelID] = useState('');
  const [channelName, setChannelName] = useState('');
  const [readAPIKey, setReadAPIKey] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const cleanup = () => {
    setIsValidChannel(false);
    setChannelID('');
    setChannelAccess(access.UNDEFINED);
    setChannelName('');
    setReadAPIKey('');
    clearErrors();
  };

  useFocusEffect(
    useCallback(() => {
      return cleanup;
    }, [navigation])
  );

  const clearErrors = () => setErrors({});

  const validateChannel = async () => {
    Keyboard.dismiss();
    if (isBlank(channelID)) {
      setErrors({
        channelID: customMessages.isRequiredField,
      });
    } else if (
      fields.some((field) => field.channel_id === toInteger(channelID))
    ) {
      setErrors({
        channelID: `This channel ${customMessages.alreadyBeenAdded}`,
      });
    } else {
      try {
        const { data } = await readChannelStatus(channelID, readAPIKey);
        if (data === '-1') {
          setChannelAccess(access.PRIVATE);
          if (readAPIKey) {
            setErrors({ readAPIKey: customMessages.invalidAPIKey });
          }
        } else {
          clearErrors();
          setIsValidChannel(true);
          setChannelAccess(readAPIKey ? access.PRIVATE : access.PUBLIC);
          return { isValid: true, ...data };
        }
      } catch (e) {
        setErrors({ channelID: customMessages.channelNotFound });
        setChannelAccess(access.UNDEFINED);
      }
    }
    setIsValidChannel(false);
    return { isValid: false, channel: null };
  };

  const addFields = async () => {
    const channel = await readDataFromAllFields(channelID, readAPIKey);
    const { id: channel_id, channel_name, allFields } = channel;
    const { data: newSource } = await createSource({
      origin: 1,
      channel_id: channelID,
      read_api_key: readAPIKey,
      display_name: channel_name,
    });
    const fields = allFields.map((field) => ({
      source: newSource,
      channel_id,
      channel_name,
      field: field.id,
      name: field.name,
      selGauge: 0,
      selLine: 0,
    }));
    setFields((currentFields) => [...currentFields, ...fields]);
  };

  const handleNextStep = async () => {
    if (isBlank(channelID) && panel) {
      return onNextStep();
    }
    setIsSubmitting(true);
    const { isValid, channel } = await validateChannel();
    if (isValid) {
      await addFields();
      onNextStep();
      if (!panel) {
        setPanel({ name: channel.name });
      }
    }
    setIsSubmitting(false);
  };

  const handleChannelAdd = async () => {
    setIsSubmitting(true);
    const { isValid, channel } = await validateChannel();
    if (isValid) {
      await addFields();
      if (!panel) {
        setPanel({ name: channel.name });
      }
      toast.show({
        description: 'Channel added successfully',
        placement: 'top',
      });
      cleanup();
    }
    setIsSubmitting(false);
  };

  const handleChannelIDEditing = async () => {
    setIsSubmitting(true);
    const { isValid, channel } = await validateChannel();
    if (isValid) {
      setChannelName(channel.name);
    }
    setIsSubmitting(false);
  };

  const handleReadAPIKeyEditing = async () => {
    setIsSubmitting(true);
    const { isValid, channel } = await validateChannel();
    if (isValid) {
      setChannelName(channel.name);
    }
    setIsSubmitting(false);
  };

  const handleChannelIDChange = (text) => {
    setIsValidChannel(false);
    setChannelAccess(access.UNDEFINED);
    setChannelName('');
    setReadAPIKey('');
    setChannelID(text);
  };

  const handleReadAPIKeyChange = (text) => {
    setReadAPIKey(text);
  };

  return (
    <Center flex={1} px="3">
      <KeyboardAvoidingView
        h={{
          base: '400px',
          lg: 'auto',
        }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <Center>
          <VStack flex="1" justifyContent="flex-end" w="320">
            <Heading mb="3" maxW="280">
              Add ThingSpeak Channel
            </Heading>
            <Text color="muted.400">
              Add a Channel to create your Panel. If the Channel is not public,
              it’s mandatory to provide Read API Key.
            </Text>
            <FormControl mb="4" mt="10" isInvalid={'channelID' in errors}>
              <Input
                placeholder="Channel ID"
                keyboardType="numeric"
                value={channelID}
                onChangeText={handleChannelIDChange}
                onSubmitEditing={handleChannelIDEditing}
                onFocus={clearErrors}
                InputRightElement={
                  channelAccess === access.UNDEFINED ? null : (
                    <Text
                      mr="2"
                      color={
                        channelAccess === access.PRIVATE
                          ? 'danger.400'
                          : 'muted.300'
                      }
                      fontSize="12"
                    >
                      {capitalize(channelAccess)}
                    </Text>
                  )
                }
              />
              {channelName ? (
                <FormControl.HelperText>{channelName}</FormControl.HelperText>
              ) : null}
              <FormControl.ErrorMessage>
                {errors?.channelID}
              </FormControl.ErrorMessage>
            </FormControl>
            {channelAccess === access.PRIVATE ? (
              <FormControl mb="4" isInvalid={'readAPIKey' in errors}>
                <Input
                  placeholder="Read API Key"
                  autoCapitalize="characters"
                  value={readAPIKey}
                  onChangeText={handleReadAPIKeyChange}
                  onSubmitEditing={handleReadAPIKeyEditing}
                  onFocus={clearErrors}
                />
                <FormControl.ErrorMessage>
                  {errors?.readAPIKey}
                </FormControl.ErrorMessage>
              </FormControl>
            ) : (
              <></>
            )}
            {panel ? (
              <Box w="100%" alignItems="center">
                <Text
                  fontSize="xs"
                  isTruncated
                  textAlign="center"
                  w="90%"
                  maxW="280"
                  color="muted.400"
                  mb={1}
                >
                  {`Panel name: ${panel.name}`}
                </Text>
              </Box>
            ) : null}
            <Button
              onPress={handleNextStep}
              isLoadingText="Validating"
              isLoading={isSubmitting}
            >
              Proceed
            </Button>
            <Button
              variant="ghost"
              onPress={handleChannelAdd}
              isDisabled={isSubmitting}
              mt={1}
            >
              Add another Channel
            </Button>
          </VStack>
        </Center>
      </KeyboardAvoidingView>
    </Center>
  );
};

export { PanelWizardStepOne };
