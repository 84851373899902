import { ComponentDimensions } from '@components/ComponentDimensions';
import { FitText } from '@components/FitText';
import { useComponentSize } from '@hooks/useComponentSize';
import { fcFormatDate } from '@utils/dateTimeHelper';
import {
  readLastChannelEntry,
  readLastFieldEntryChannel,
  updateChannelData,
} from '@utils/thingSpeakAPI';
import { differenceInMinutes, minutesToHours } from 'date-fns';
import {
  differenceWith,
  isEqual,
  isNil,
  isNumber,
  max,
  maxBy,
  min,
  minBy,
  toNumber,
} from 'lodash';
import { Box, Text, ZStack, useToast } from 'native-base';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Platform, Pressable, StyleSheet } from 'react-native';
import sleep from 'sleep-promise';
import { ButtonGroup } from './ButtonGroup';
import { Display } from './Display';
import { Label } from './Label';
import { ImageUpload } from './ImageUpload';
import { Gauge } from './Gauge';
import { HSlider } from './HSlider';
import { ONOFF } from './ONOFF';
import { PUSHBUTTON } from './PushButton';
import { StackedSeries } from './StackedSeries';
import { Switch } from './Switch';
import { Thermometer } from './Thermometer';
import { Horizontal } from './Horizontal';
import { Linear } from './Linear';
import { Cylinder } from './Cylinder';
import { TimeSeries } from './TimeSeries';
import useInterval from 'use-interval';
import { isBlank } from '@utils/stringHelper';
import { getRoundedLimit } from '@utils/numberHelper';

const getLimits = ({ ranges, currentValue, targetValue = 0, fieldId, agg }) => {
  const ret = {
    lowerLimit: fieldId in agg ? agg[fieldId]?.roundedMin : null,
    upperLimit: fieldId in agg ? agg[fieldId]?.roundedMax : null,
    fillColor: '#3b82f6',
    colorRange: [],
  };
  if (isBlank(currentValue)) {
    ret.colorRange = [
      {
        minValue: 0,
        maxValue: 0,
        code: '#999999',
      },
    ];
    ret.lowerLimit = 0;
    ret.upperLimit = 0;
  } else if (ranges.length > 0) {
    ret.colorRange = ranges.map((r) => {
      return {
        minValue:
          (isBlank(r.range_min)
            ? min([getRoundedLimit(r.range_max, true), currentValue])
            : r.range_min) + targetValue,
        maxValue:
          (isBlank(r.range_max)
            ? max([getRoundedLimit(r.range_min), currentValue])
            : r.range_max) + targetValue,
        code: r.color,
      };
    });
    ret.lowerLimit = minBy(ret.colorRange, 'minValue').minValue;
    ret.upperLimit = maxBy(ret.colorRange, 'maxValue').maxValue;
    const currentRange = ret.colorRange.find((i) => {
      return i.maxValue >= currentValue;
    });
    ret.fillColor = currentRange?.code || ret.fillColor;
  } else {
    ret.colorRange = [
      {
        minValue: ret.lowerLimit,
        maxValue: currentValue,
        code: '#0095f7',
      },
      {
        minValue: currentValue,
        maxValue: ret.upperLimit,
        code: '#999999',
      },
    ];
  }
  return ret;
};

const Widget = ({
  widget,
  setWidgets,
  currentScale,
  isWritable = true,
  isDnD = false,
}) => {
  const {
    id,
    display_name,
    subtype_tag,
    type_tag,
    target_value,
    channel_id,
    read_api_key,
    write_api_key,
    field,
    target_field,
    is_owner,
    lastEntry = {},
    targetLastEntry,
    agg = {},
    ranges = [],
  } = widget;
  const fieldId = `field${field}`;
  const lastValue = lastEntry[fieldId];
  const targetFieldValue = targetLastEntry
    ? targetLastEntry[`field${target_field}`]
    : null;
  const [size, onLayout] = useComponentSize();
  const widgetRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(!is_owner);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentValue, setCurrentValue] = useState(lastValue);
  const [lastUpdated, setLastUpdated] = useState(lastEntry?.created_at);
  const [intvCounter, setIntvCounter] = useState(0);
  const targetValue = isNil(targetFieldValue) ? target_value : targetFieldValue;
  const isWriteLocked = useRef(false);
  const toast = useToast();
  const isOutput =
    ['H', 'D', 'G'].includes(type_tag) || ['RR'].includes(subtype_tag);
  const limits = getLimits({ ranges, currentValue, targetValue, fieldId, agg });

  useEffect(() => {
    setCurrentValue(lastValue);
    setLastUpdated(lastEntry?.created_at);
  }, [lastValue, lastEntry]);

  useInterval(() => {
    setIntvCounter(intvCounter + 1);
  }, 30000);

  const handleCurrentValueChange = async (newValue) => {
    if (isWriteLocked.current) return;
    setCurrentValue(newValue);
    setIsProcessing(true);
    if (isWritable) {
      isWriteLocked.current = true;
      try {
        const { data: channel } = await readLastChannelEntry(
          channel_id,
          read_api_key
        );
        const allFields = {};
        for (let key in channel) {
          if (key.substr(0, 5) === 'field') {
            allFields[key] = channel[key];
          }
        }

        let retry = true;
        sleep(15000).then(() => (retry = false));
        const writeData = async () => {
          try {
            const { data } = await updateChannelData(
              write_api_key,
              field,
              newValue,
              allFields
            );
            if (data === 0) {
              if (retry) {
                setIsDisabled(true);
                return writeData();
              } else {
                toast.closeAll();
                toast.show({
                  description:
                    'Error writing ' +
                    widget.data[`field${field}`] +
                    '. Try again later.',
                  placement: 'top',
                });
              }
            }
          } catch (err) {
            toast.closeAll();
            toast.show({
              description:
                'Please make sure that your Write API key is correct',
              placement: 'top',
            });
          }
          isWriteLocked.current = false;
          setIsDisabled(false);
          setIsProcessing(false);
        };
        writeData();
      } catch (err) {
        isWriteLocked.current = false;
        setIsDisabled(false);
        setIsProcessing(false);
      }
    }
  };

  const setWidgetPropValue = (prop, value) => {
    setWidgets((state) =>
      state.map((st) => {
        if (id === st.id) {
          return {
            ...st,
            [prop]: value,
          };
        }
        return st;
      })
    );
  };

  const widgetProps = {
    widget,
    size,
    currentValue,
    targetValue,
    onCurrentValueChange: handleCurrentValueChange,
    setWidgetPropValue,
    currentScale,
    isDisabled,
    isWritable,
    isDnD,
    limits,
    isProcessing,
  };
  let selectedWidget = <Text>Not implemented</Text>;
  if (subtype_tag === 'BO') {
    selectedWidget = <ONOFF {...widgetProps} />;
  } else if (subtype_tag === 'PB') {
    selectedWidget = <PUSHBUTTON {...widgetProps} />;
  } else if (type_tag === 'W') {
    selectedWidget = <Switch {...widgetProps} />;
  } else if (type_tag === 'S') {
    selectedWidget = <HSlider {...widgetProps} />;
  } else if (type_tag === 'R') {
    selectedWidget = (
      <ButtonGroup {...widgetProps} isReadOnly={subtype_tag === 'RR'} />
    );
  } else if (type_tag === 'D') {
    selectedWidget = <Display widgetRef={widgetRef} {...widgetProps} />;
  } else if (type_tag === 'L') {
    selectedWidget = <Label widgetRef={widgetRef} {...widgetProps} />;
  } else if (type_tag === 'I') {
    selectedWidget = <ImageUpload widgetRef={widgetRef} {...widgetProps} />;
  } else if (subtype_tag === 'TH') {
    selectedWidget = <Thermometer widgetRef={widgetRef} {...widgetProps} />;
  } else if (subtype_tag === 'LN') {
    selectedWidget = <Horizontal widgetRef={widgetRef} {...widgetProps} />;
  } else if (subtype_tag === 'L2') {
    selectedWidget = <Linear widgetRef={widgetRef} {...widgetProps} />;
  } else if (subtype_tag === 'CY') {
    selectedWidget = <Cylinder widgetRef={widgetRef} {...widgetProps} />;
  } else if (type_tag === 'G') {
    selectedWidget = <Gauge widgetRef={widgetRef} {...widgetProps} />;
  } else if (subtype_tag === 'TS') {
    selectedWidget = <StackedSeries ref={widgetRef} {...widgetProps} />;
  } else if (type_tag === 'T') {
    selectedWidget = <TimeSeries ref={widgetRef} {...widgetProps} />;
  }

  const title = (
    <Box position="absolute" top="0.5" left="1" w="90%">
      <FitText
        txt={display_name}
        color="coolGray.300"
        maxSize={11}
        style={styles.text}
      />
    </Box>
  );

  const memoizedLastUpdated = useMemo(() => {
    if (!lastUpdated) return null;
    const hourInMinutes = 60;
    const dayInMinutes = 1440;
    const minutesAgo = differenceInMinutes(new Date(), lastUpdated);
    const hoursAgo = minutesToHours(minutesAgo);
    let updatedText = '';
    if (minutesAgo >= dayInMinutes) {
      const daysAgo = Math.floor(hoursAgo / 24);
      updatedText = `Updated ${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
    } else if (minutesAgo > hourInMinutes) {
      updatedText = `Updated ${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
    } else if (minutesAgo > 0) {
      updatedText = `Updated ${minutesAgo} minute${
        minutesAgo > 1 ? 's' : ''
      } ago`;
    } else {
      return null;
    }
    return (
      <Box position="absolute" top="0.5" right="1" rounded={2} w="90%">
        <FitText
          txt={updatedText}
          color={minutesAgo > hourInMinutes ? 'danger.400' : 'secondary.400'}
          textAlign="right"
          maxSize={10}
          style={styles.text}
        />
      </Box>
    );
  }, [lastUpdated, intvCounter]);

  return (
    <Pressable
      style={styles.MainContainer}
      onLongPress={() => {
        if (Platform.OS !== 'web' && widgetRef.current) {
          widgetRef.current.exportChart();
        }
      }}
    >
      <ZStack
        flex={1}
        alignItems="center"
        justifyContent="center"
        h="100%"
        w="100%"
        onLayout={onLayout}
        paddingLeft={0}
        paddingRight={0}
      >
        {selectedWidget}
        {title}
        {isOutput && memoizedLastUpdated}
        <ComponentDimensions size={size} />
      </ZStack>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  MainContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },

  text: {
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    textShadowColor: '#171717',
  },
});

const propsAreEqual = (prevProps, nextProps) => {
  const noReRenderIfAreEqual = isEqual(prevProps, nextProps);
  return noReRenderIfAreEqual;
};
const MemoizedWidget = memo(Widget, propsAreEqual);

export { MemoizedWidget as Widget };
