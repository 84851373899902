import { useGlobalContext } from '@hooks/useGlobalContext';
import { useLinkTo } from '@react-navigation/native';
import { useFormikContext } from 'formik';
import { Button } from 'native-base';
import React from 'react';
import sleep from 'sleep-promise';

function DiscardButton({ onPress, to, ...props }) {
  const { alertDialog } = useGlobalContext();
  const linkTo = useLinkTo();
  const { dirty, isSubmitting } = useFormikContext();

  const handleDiscardButton = () => {
    if (to) {
      sleep(200).then(() => linkTo(to));
    } else {
      history.back();
    }
  };

  return (
    <Button
      colorScheme="secondary"
      {...props}
      isDisabled={isSubmitting}
      onPress={() => {
        if (dirty) {
          alertDialog.current.show(
            'Discard changes',
            'Do you want to discard the changes?',
            <Button
              colorScheme="danger"
              onPress={() => {
                alertDialog.current.close();
                handleDiscardButton();
              }}
            >
              Discard
            </Button>
          );
        } else {
          handleDiscardButton();
        }
      }}
      flex="1"
      flexGrow={{ base: '1', lg: '2' }}
    >
      DISCARD
    </Button>
  );
}

export { DiscardButton };
