import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Box, Icon, IconButton, Text } from 'native-base';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

const AlignmentButtons = ({ value, onChangeText, title, textAlignment }) => {
  useEffect(() => {
    handleAlignment(!textAlignment ? 'center|center' : textAlignment);
  }, [textAlignment]);

  const [selectedAlignment, setSelectedAlignment] = useState(value);

  {
    /* Top */
  }
  const [hoverTextLeftTop, setHoverTextLeftTop] = useState(false);
  const [hoverTextCenterTop, setHoverTextCenterTop] = useState(false);
  const [hoverTextRightTop, setHoverTextRightTop] = useState(false);
  {
    /* Middle */
  }
  const [hoverTextLeft, setHoverTextLeft] = useState(false);
  const [hoverTextCenter, setHoverTextCenter] = useState(false);
  const [hoverTextRight, setHoverTextRight] = useState(false);
  {
    /* Bottom */
  }
  const [hoverTextLeftBottom, setHoverTextLeftBottom] = useState(false);
  const [hoverTextCenterBottom, setHoverTextCenterBottom] = useState(false);
  const [hoverTextRightBottom, setHoverTextRightBottom] = useState(false);

  const handleAlignment = (alignment) => {
    setSelectedAlignment(alignment);
    onChangeText(alignment);
  };

  const styles = StyleSheet.create({
    mainStyle: {
      flexDirection: 'row',
      width: '40px',
    },
    textTitle: {
      fontSize: '14px',
      fontWeight: 500,
    },
    selectedButton: {
      backgroundColor: '#3b82f61a',
    },
  });

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <Text style={styles.textTitle}>{title}</Text>
      </View>

      {/* Top */}
      <View
        style={{
          flexDirection: 'row',
          width: '150px',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            onPress={() => handleAlignment('flex-start|left')}
            onMouseEnter={() => setHoverTextLeftTop(true)}
            onMouseLeave={() => setHoverTextLeftTop(false)}
            style={[
              styles.mainStyle,
              selectedAlignment === 'flex-start|left' && styles.selectedButton,
              hoverTextLeftTop && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={<Icon as={MaterialCommunityIcons} name="arrow-top-left" />}
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleAlignment('flex-start|left')}
            />
          </TouchableOpacity>
        </View>

        <View style={{ flex: 2, alignItems: 'center' }}>
          <TouchableOpacity
            onPress={() => handleAlignment('flex-start|center')}
            onMouseEnter={() => setHoverTextCenterTop(true)}
            onMouseLeave={() => setHoverTextCenterTop(false)}
            style={[
              styles.mainStyle,
              selectedAlignment === 'flex-start|center' &&
                styles.selectedButton,
              hoverTextCenterTop && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={
                <Icon
                  as={MaterialCommunityIcons}
                  name="format-vertical-align-top"
                />
              }
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleAlignment('flex-start|center')}
            />
          </TouchableOpacity>
        </View>

        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          <TouchableOpacity
            onPress={() => handleAlignment('flex-start|right')}
            onMouseEnter={() => setHoverTextRightTop(true)}
            onMouseLeave={() => setHoverTextRightTop(false)}
            style={[
              styles.mainStyle,
              selectedAlignment === 'flex-start|right' && styles.selectedButton,
              hoverTextRightTop && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={<Icon as={MaterialCommunityIcons} name="arrow-top-right" />}
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleAlignment('flex-start|right')}
            />
          </TouchableOpacity>
        </View>
      </View>

      {/* Middle */}
      <View
        style={{
          flexDirection: 'row',
          width: '150px',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            onPress={() => handleAlignment('center|left')}
            onMouseEnter={() => setHoverTextLeft(true)}
            onMouseLeave={() => setHoverTextLeft(false)}
            style={[
              styles.mainStyle,
              selectedAlignment === 'center|left' && styles.selectedButton,
              hoverTextLeft && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={
                <Icon
                  as={MaterialCommunityIcons}
                  name="format-horizontal-align-left"
                />
              }
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleAlignment('center|left')}
            />
          </TouchableOpacity>
        </View>

        <View style={{ flex: 2, alignItems: 'center' }}>
          <TouchableOpacity
            onPress={() => handleAlignment('center|center')}
            onMouseEnter={() => setHoverTextCenter(true)}
            onMouseLeave={() => setHoverTextCenter(false)}
            style={[
              styles.mainStyle,
              selectedAlignment === 'center|center' && styles.selectedButton,
              hoverTextCenter && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={
                <Icon
                  as={MaterialCommunityIcons}
                  name="format-horizontal-align-center"
                />
              }
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleAlignment('center|center')}
            />
          </TouchableOpacity>
        </View>

        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          <TouchableOpacity
            onPress={() => handleAlignment('center|right')}
            onMouseEnter={() => setHoverTextRight(true)}
            onMouseLeave={() => setHoverTextRight(false)}
            style={[
              styles.mainStyle,
              selectedAlignment === 'center|right' && styles.selectedButton,
              hoverTextRight && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={
                <Icon
                  as={MaterialCommunityIcons}
                  name="format-horizontal-align-right"
                />
              }
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleAlignment('center|right')}
            />
          </TouchableOpacity>
        </View>
      </View>

      {/* Bottom */}
      <View
        style={{
          flexDirection: 'row',
          width: '150px',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            onPress={() => handleAlignment('flex-end|left')}
            onMouseEnter={() => setHoverTextLeftBottom(true)}
            onMouseLeave={() => setHoverTextLeftBottom(false)}
            style={[
              styles.mainStyle,
              selectedAlignment === 'flex-end|left' && styles.selectedButton,
              hoverTextLeftBottom && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={
                <Icon as={MaterialCommunityIcons} name="arrow-bottom-left" />
              }
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleAlignment('flex-end|left')}
            />
          </TouchableOpacity>
        </View>

        <View style={{ flex: 2, alignItems: 'center' }}>
          <TouchableOpacity
            onPress={() => handleAlignment('flex-end|center')}
            onMouseEnter={() => setHoverTextCenterBottom(true)}
            onMouseLeave={() => setHoverTextCenterBottom(false)}
            style={[
              styles.mainStyle,
              selectedAlignment === 'flex-end|center' && styles.selectedButton,
              hoverTextCenterBottom && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={
                <Icon
                  as={MaterialCommunityIcons}
                  name="format-vertical-align-bottom"
                />
              }
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleAlignment('flex-end|center')}
            />
          </TouchableOpacity>
        </View>

        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          <TouchableOpacity
            onPress={() => handleAlignment('flex-end|right')}
            onMouseEnter={() => setHoverTextRightBottom(true)}
            onMouseLeave={() => setHoverTextRightBottom(false)}
            style={[
              styles.mainStyle,
              selectedAlignment === 'flex-end|right' && styles.selectedButton,
              hoverTextRightBottom && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={
                <Icon as={MaterialCommunityIcons} name="arrow-bottom-right" />
              }
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleAlignment('flex-end|right')}
            />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

export { AlignmentButtons };
