import { GlobalContext } from '@hooks/useGlobalContext';
import { useWinDimensions } from '@hooks/useWinDimensions';
import { useToast } from 'native-base';
import React, { useRef, useState } from 'react';
import { Platform } from 'react-native';
import { ModalDialog } from '../ModalDialog';
import { ProgressDialog } from '../ProgressDialog';

function ProvideGlobal({ children }) {
  const alertDialogRef = useRef(null);
  const progressDialogRef = useRef(null);
  const winDims = useWinDimensions();
  const toast = useToast();
  const [currentViewType, setCurrentViewType] = useState(null);

  return (
    <GlobalContext.Provider
      value={{
        winDims,
        alertDialog: alertDialogRef,
        progressDialog: progressDialogRef,
        toast,
        currentViewType,
        setCurrentViewType,
      }}
    >
      {children}
      <ModalDialog ref={alertDialogRef} />
      <ProgressDialog ref={progressDialogRef} />
    </GlobalContext.Provider>
  );
}

export { ProvideGlobal };
