import { useGlobalContext } from '@hooks/useGlobalContext';
import { bodyBgColor } from '@utils/customTheme';
import { colord } from 'colord';
import Constants from 'expo-constants';
import { Box, Text } from 'native-base';
import React, { memo } from 'react';
import { roundTo } from 'round-to';

const WinDimensions = memo(() => {
  const { winDims } = useGlobalContext();
  const { w, h, deviceOrientation } = winDims;
  if (!Constants.manifest.extra.showDimensions) {
    return null;
  }
  return (
    <Box
      position={{ md: 'absolute' }}
      px="1"
      _light={{
        bgColor: colord(bodyBgColor.light).darken(0.03).toHex(),
      }}
      _dark={{
        bgColor: colord(bodyBgColor.dark).darken(0.014).toHex(),
      }}
    >
      {// prettier-ignore
      }
      <Text fontSize="12">{`${roundTo(w.px, 1)} x ${roundTo(h.px, 1)} px  |  ${w.in} x ${h.in} in  |  ${deviceOrientation}`}</Text>
    </Box>
  );
});

export { WinDimensions };
