import AsyncStorage from '@react-native-async-storage/async-storage';
import { DarkTheme, DefaultTheme } from '@react-navigation/native';
import { extendTheme, theme as nbTheme } from 'native-base';
import { Platform } from 'react-native';
const colorModeManager = {
  get: async () => {
    try {
      // let val = await AsyncStorage.getItem('@color-mode');
      // return val === 'light' ? 'light' : 'dark';
    } catch (e) {}
    return 'dark';
  },
  set: async (value) => {
    try {
      await AsyncStorage.setItem('@color-mode', value);
    } catch (e) {
      console.log(e);
    }
  },
};

const bodyBgColor = {
  light: '#EDEDED',
  dark: '#1A1D23',
};

const containerBgColor = {
  light: '#ffffff',
  dark: '#262A33',
};

const appBarBgColor = {
  light: '#ffffff',
  dark: '#262A33',
};

const boxBorderColor = {
  light: '#e4e4e7',
  dark: '#353942',
};

const widgetBgColor = {
  light: '#FFFFFF',
  dark: '#262A33',
};

const navigationContainerTheme = ({ isLargeScreen, colorMode }) => ({
  ...(colorMode === 'dark' ? DarkTheme : DefaultTheme),
  colors: {
    ...(colorMode === 'dark' ? DarkTheme.colors : DefaultTheme.colors),
    background:
      colorMode === 'dark'
        ? isLargeScreen
          ? bodyBgColor.dark
          : bodyBgColor.dark
        : isLargeScreen
        ? bodyBgColor.light
        : bodyBgColor.light,
    border: isLargeScreen
      ? colorMode === 'dark'
        ? boxBorderColor.dark
        : boxBorderColor.light
      : undefined,
  },
});

const headerStyleTheme = ({ colorMode }) => ({
  headerStyle: {
    backgroundColor:
      colorMode === 'dark' ? appBarBgColor.dark : appBarBgColor.light,
    borderColor:
      colorMode === 'dark' ? boxBorderColor.dark : boxBorderColor.light,
  },
  headerTintColor:
    colorMode === 'dark' ? appBarBgColor.light : appBarBgColor.dark,
  headerTitleStyle: {
    fontSize: 14,
  },
});

const customTheme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  colors: {
    primary: nbTheme.colors.blue,
    secondary: nbTheme.colors.coolGray,
    bodyBgColorLight: bodyBgColor.light,
    bodyBgColorDark: bodyBgColor.dark,
    containerBgColorLight: containerBgColor.light,
    containerBgColorDark: containerBgColor.dark,
    appBarBgColorLight: appBarBgColor.light,
    appBarBgColorDark: appBarBgColor.dark,
    widgetBgColorLight: widgetBgColor.light,
    widgetBgColorDark: widgetBgColor.dark,
  },
  components: {
    Icon: {
      baseStyle: () => {
        return {
          _light: {
            color: 'coolGray.800',
          },
          _dark: {
            color: 'warmGray.50',
          },
        };
      },
    },
    FormControlLabel: {
      baseStyle: () => {
        return {
          _light: {
            _text: {
              color: 'coolGray.800',
            },
          },
          _dark: {
            _text: {
              color: 'warmGray.50',
            },
          },
        };
      },
    },
    Input: {
      defaultProps: {
        fontSize: 'sm',
        autoComplete: 'off',
      },
      baseStyle: () => {
        return {
          _dark: {
            borderColor: 'gray.600',
            placeholderTextColor: 'muted.500',
          },
        };
      },
    },
    Divider: {
      baseStyle: () => {
        return {
          _dark: {
            bg: 'gray.600',
          },
        };
      },
    },
    Toast: {
      baseStyle: () => {
        return {
          _title: {
            fontWeight: 600,
          },
        };
      },
    },
    Link: {
      baseStyle: () => {
        if (Platform.OS == 'web'){
          return {
            cursor: 'pointer',
          };
        }
        return {}
      },
    },
  },
});

export {
  colorModeManager,
  bodyBgColor,
  containerBgColor,
  appBarBgColor,
  boxBorderColor,
  widgetBgColor,
  navigationContainerTheme,
  headerStyleTheme,
  customTheme,
};
