import {
  Box,
  Button,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  KeyboardAvoidingView,
  Modal,
  Pressable,
  useDisclose,
} from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import ColorPicker from 'react-native-wheel-color-picker';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { presetColors } from '@components/Widget/TimeSeries/common';
import { colord } from 'colord';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { isHexColor } from '@utils/stringHelper';

const initialColor = '#878787';

const ColorInput = ({ value, onChange, showPreview }) => {
  const [color, setColor] = useState(value || initialColor);
  const [hex, setHex] = useState(value || initialColor);
  const { isOpen, onOpen, onClose } = useDisclose();

  const debounced = useDebouncedCallback((value) => {
    setColor((currentColor) => (isHexColor(value) ? value : currentColor));
  }, 500);

  const handleColorChange = useCallback(
    (c) => {
      setHex(c);
      setColor(c);
    },
    [color]
  );

  return (
    <Box>
      <HStack>
        <IconButton
          variant="unstyled"
          py={{ base: 3, md: 2 }}
          border={1}
          style={{
            backgroundColor: (showPreview ? '#3b82f6' : (value || initialColor)),
            width:'50px',
          }}
          maxW="100"
          onPressOut={onOpen}
          icon={<Icon as={MaterialCommunityIcons} name="format-color-fill" style={{ color: '#FFFFFF' }} />}
        />
        {showPreview && 
          <Box
            w={10}
            h={10}
            borderRadius="full"
            borderWidth={3}
            borderColor="white"
            bg={color}
            ml={5}
          />
        }
      </HStack>
      <Modal isOpen={isOpen} avoidKeyboard onClose={onClose} size="full">
        <Modal.Content placement="center" maxWidth="300">
          <Modal.Body>
            <ColorPicker
              color={color}
              swatchesOnly={false}
              onColorChangeComplete={handleColorChange}
              thumbSize={40}
              sliderSize={16}
              noSnap={true}
              row={false}
              swatchesLast={false}
              swatches={true}
              discrete={false}
              autoResetSlider={true}
              palette={[...presetColors, '#888888']}
            />
          </Modal.Body>
          <Modal.Footer>
            <HStack space={2} w="100%">
              <Input
                size="xs"
                placeholder="HEX"
                value={hex}
                maxLength={7}
                onChangeText={(text) => {
                  setHex(text);
                  debounced(text);
                }}
                style={{ textTransform: 'uppercase' }}
                color="muted.400"
                variant="rounded"
                flex={1}
                flexGrow={1}
              />
              <Button variant="unstyled" mr="1" onPress={onClose}>
                Cancel
              </Button>
              <Button
                onPress={() => {
                  onChange(color);
                  onClose();
                }}
                isDisabled={color.length < 7 || !colord(color).isValid()}
              >
                Confirm
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  );
};

export { ColorInput };
