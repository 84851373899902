import { Button, Text } from 'native-base';
import React from 'react';

function Header({ onNewPanel, isSubmitting }) {
  return (
    <>
      <Text fontSize="xl">My Panels</Text>
      <Button
        isLoading={isSubmitting}
        isLoadingText="Creating..."
        onPress={onNewPanel}
      >
        New Panel
      </Button>
    </>
  );
}

export { Header };
