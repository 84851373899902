import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  convertToken,
  registerUser,
  removeAccountUser,
  updateAccountUser,
  accountPasswordUser,
  getUser,
  passwordresetUser,
  passwordresetcompUser,
  passwordresettknUser,
  refreshToken,
  login,
  logoff,
  oauthorize,
} from '@utils/user';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUserPicture } from '@utils/user';

const AuthContext = createContext();
const useAuth = () => useContext(AuthContext);

function useProvideAuth() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userPicture, setUserPicture] = useState(null);

  const [provider, setProvider] = useState(null);
  const [isAuthorize, setIsAuthorize] = useState(false);

  async function fetchUserData() {
    const user = await getUser();
    setUserData(user);
    setProvider(user.provider);    
  }
  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData();
      AsyncStorage.getItem('@userPicture').then((resp) => {
        setUserPicture(resp);
      });
    } else {
      setUserData(null);
      setUserPicture(null);
    }
  }, [isAuthenticated]);

  async function processSigin(resp) {
    const { data } = resp;
    if (data.access_token) {
      await AsyncStorage.setItem('@access_token', data.access_token);
      await AsyncStorage.setItem('@refresh_token', data.refresh_token);
      if (!isAuthorize) {
        await refreshToken();
        await fetchUserData();
        setIsAuthenticated(true);
      }
    }
  }
  const authorize = async () => await oauthorize();
  const signin = ({ username, password }) =>
    login(username, password).then(processSigin);

  const signUp = (params) => registerUser(params);

  const account = (params) => updateAccountUser(params);

  const accountpassword = (params) => accountPasswordUser(params);

  const removeaccount = (params) => removeAccountUser(params);

  const passwordreset = (params) => passwordresetUser(params);

  const passwordresettkn = (params) => passwordresettknUser(params);

  const passwordresetcomp = (params) => passwordresetcompUser(params);

  const signout = async () => {
    try {
      await logoff();
    } catch (err) {}
    setUserData(null);
    setIsAuthenticated(false);
    
    AsyncStorage.removeItem('@userPicture');

    setProvider(null);
  };

  const signinSocial = ({ access_token, backend }) => {
    getUserPicture(access_token, backend).then((resp) => {
      AsyncStorage.setItem('@userPicture', resp);
    });
    return convertToken({ access_token, backend }).then(processSigin);
  };

  return {
    signin,
    signout,
    signinSocial,
    signUp,
    account,
    accountpassword,
    removeaccount,
    isLoading,
    setIsLoading,
    isAuthenticated,
    setIsAuthenticated,
    setIsAuthorize,
    isAuthorize,
    userData,
    userPicture,
    provider,
    fetchUserData,
    passwordreset,
    passwordresetcomp,
    passwordresettkn,
    authorize,
  };
}

export { AuthContext, useProvideAuth, useAuth };
