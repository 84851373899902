import { formatISO, parseJSON } from 'date-fns';
import { formatDate } from 'fusioncharts/utils';
import { isDate, isString } from 'lodash';

const tsDateFormat = "yyyy-MM-dd'T'HH:mm:ss.000xxx";
const fcOutTimeFormat = '%b %d, %Y %H:%M:%S';
const fcUnixTimeFormat = '%s';

const toTimestamp = (strDate) => {
  if (isString(strDate)) {
    return parseJSON(strDate).getTime();
  } else if (isDate(strDate)) {
    return strDate.getTime();
  }
  return strDate;
};

const fcFormatDate = (date, format = fcOutTimeFormat) => {
  return formatDate(toTimestamp(date), format);
};

const combineDateTime = (date, dateTime) => {
  try {
    const d = formatISO(date);
    const t = formatISO(dateTime);
    const dt = d.substr(0, 11) + t.substr(11);
    return new Date(dt);
  } catch (err) {}
  return null;
};

const minutesToDays = (minutes) => Math.floor(minutes / 1440);

export {
  tsDateFormat,
  fcOutTimeFormat,
  fcUnixTimeFormat,
  toTimestamp,
  fcFormatDate,
  combineDateTime,
  minutesToDays
};
