import Feather from '@expo/vector-icons/Feather';
import { getMonth, getYear } from 'date-fns';
import { range } from 'lodash';
import { Icon, Input, Pressable } from 'native-base';
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

const DateInput = ({ value, onChange, maxDate }) => {
  const years = range(1990, getYear(new Date()) + 1, 1);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <Pressable onPress={onClick}>
        <Input
          ref={ref}
          placeholder="Click to select a date"
          value={value}
          InputRightElement={<Icon as={Feather} name="calendar" mr="2" />}
          isReadOnly
        />
      </Pressable>
    );
  });

  return (
    <DatePicker
      dateFormat="MMMM dd, yyyy"
      selected={value}
      onChange={onChange}
      customInput={<CustomInput />}
      todayButton="Today"
      portalId
      maxDate={maxDate}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {'<'}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {'>'}
          </button>
        </div>
      )}
    />
  );
};

export { DateInput };
