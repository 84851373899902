import { differenceInMinutes, format } from 'date-fns';
import { cloneDeep } from 'lodash';
import { Actionsheet, Box, useDisclose } from 'native-base';
import {
  forwardRef,  
  useImperativeHandle,
  useRef,
} from 'react';
import { FlashList } from "@shopify/flash-list";
import { ModalForm } from './form';
const theme = "dark"
const bgColorChartScale = {
  dark:"#262A33",
  light: "#FFFFFF"
}
const textColorChartScale ={
  dark: "#FFFFFF",
  light: "#000000"
}
const ChartScale = forwardRef(
  ({ savePrefs, scales, currentScale, setCurrentScale }, ref) => {
    const customScale = scales[0];
    const modalRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclose();

    useImperativeHandle(ref, () => ({
      onClose,
      onOpen,
    }));

    const handleScaleChange = (selectedScale) => {
      onClose();
      if (selectedScale.id === customScale.id) {
        modalRef.current.onOpen();
        return;
      }
      savePrefs({
        selectedScale,
      });
      setCurrentScale(selectedScale);
    };

    const handleCustomChange = (from, to) => {
      const deep = cloneDeep(customScale);
      const interval = differenceInMinutes(to, from);
      const selectedScale = {
        ...deep,
        from,
        to,
      };
      scales.forEach((scale) => {
        if (scale.interval <= interval) {
          selectedScale.timescale = scale.timescale;
        }
      });
      savePrefs({
        selectedScale,
      });
      setCurrentScale(selectedScale);
      modalRef.current.onClose();
    };

    return (
      <>
        <Actionsheet isOpen={isOpen} onClose={onClose} >
          <Actionsheet.Content bg={bgColorChartScale[theme]}>
          <Box h={300} width={"100%"}>
            <FlashList            
              estimatedItemSize={16}
              data={[
                ...scales.filter((scale) => scale.id !== customScale.id),
                customScale,
              ]}
              keyExtractor={(item) => item.id.toString()}
              renderItem={({ item }) => (
                <Actionsheet.Item bg={bgColorChartScale[theme]}
                  onPress={() => handleScaleChange(item)}
                  _text={{
                    underline: currentScale.id === item.id,
                    bold: currentScale.id === item.id,
                    color: textColorChartScale[theme]
                  }}
                >
                  {item.name}
                </Actionsheet.Item>
              )}
            />
            </Box>
          </Actionsheet.Content>
        </Actionsheet>
        <ModalForm
          ref={modalRef}
          currentScale={currentScale}
          onCustomChange={handleCustomChange}
        />
      </>
    );
  }
);

export { ChartScale };
