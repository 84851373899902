import { useForceUpdate } from '@hooks/useForceUpdate';
import { isBlank } from '@utils/stringHelper';
import { useColorMode } from 'native-base';
import React, { useEffect, useState } from 'react';
import { SimpleGauge } from './Gauge';

const showValueFn = (currentValue) =>
  !isBlank(currentValue) && !isNaN(currentValue);

const showTickValuesFn = (size, breakpoints) =>
  size.w.in >= breakpoints.showTickValues.w &&
  size.h.in >= breakpoints.showTickValues.h;

const Gauge = ({
  widgetRef,
  widget,
  size,
  currentValue,
  setWidgetPropValue,
  limits: { lowerLimit, upperLimit, colorRange },
}) => {
  const { unit, decimal_places, breakpoints } = widget;
  const { colorMode } = useColorMode();
  const [isLoading, setIsLoading] = useState(true);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const showValue = showValueFn(currentValue);
  const showTickValues = showTickValuesFn(size, breakpoints);

  useEffect(() => {
    setWidgetPropValue('isMounted', true);
  }, []);

  const dataSource = {
    chart: {
      theme: colorMode == 'dark' ? 'candy' : 'fusion',
      valueFontBold: 0,
      valueFontSize: 12,
      labelFontSize: 12,
      lowerLimit: lowerLimit?.toString(),
      upperLimit: upperLimit?.toString(),
      showValue,
      showTickValues: showTickValues,
      numberSuffix: unit,
      decimals: decimal_places ?? 1,
      forceDecimals: 1,
      showToolTip: 0,
      gaugeFillMix: '{light-30},{light-45}',
      gaugeFillRatio: 100,
      gaugeinnerradius: '58%',
      adjustTM: 0,
      tickValueDistance: '4',
      exportEnabled: '1',
      exportShowMenuItem: '0',
    },
    colorRange: {
      color: colorRange.map((range) => ({
        ...range,
        minValue: range.minValue?.toString(),
        maxValue: range.maxValue?.toString(),
      })),
    },
    dials: {
      dial: [
        {
          value: currentValue,
          radius: currentValue === null ? '0' : '100%',
        },
      ],
    },
  };

  return (
    <SimpleGauge
      ref={widgetRef}
      forceUpdate={forceUpdate}
      setForceUpdate={setForceUpdate}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      dataSource={dataSource}
      size={size}
    />
  );
};

export { Gauge };
