
import { Container } from '@components/Container';
import { DeleteItemMenu } from '@components/DeleteItemMenu';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { getSources } from '@utils/source';
import { isBlank } from '@utils/stringHelper';
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';

import {
  createWidget,
  deleteWidget,
  getSubTypes,
  getWidget,
  getWidgets,
  saveWidget,
} from '@utils/widget';
import _ from 'lodash';
import {
  FormControl,
  Hidden,
  HStack,
  Select,
  VStack,
  Stack,
  Radio,
  Input,
  Flex,
  Text,
  IconButton,
  SmallCloseIcon,
  Button,
  AddIcon,
  Icon,
  Checkbox,
  ArrowDownIcon,
  ArrowUpIcon,
  Spinner,
  Center,
  View,
} from 'native-base';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CustomHeaderRight } from '@components/CustomHeaderRight';
import { Header } from './header';
import { bodyBgColor } from '@utils/customTheme';
import { useFastForm } from '@hooks/useFastForm';
import { Widget } from '@components/Widget';
import { ColorInput } from '@components/ColorInput';

import { AlignmentButtons } from '@components/AlignmentButtons';
import { TextChangeFontType } from '@components/TextChangeFontType';
import { TextChangeFontSize } from '@components/TextChangeFontSize';

import { retrieveChannelData } from '@utils/thingSpeakAPI_2';
import Feather from '@expo/vector-icons/Feather';
import { CopyRangeDialog } from './CopyRangeDialog';
import { ControlledField } from '@components/ControlledField';
import { CopyOptionDialog } from './CopyOptionDialog';
import { DockedFormFooter } from '@components/DockedFormFooter';
import { Platform } from 'react-native';
import { customMessages } from '@utils/customMessages';
import { toNumeric } from '@utils/numberHelper';
import { saveWidgetRanges } from '@utils/widgetRange';
import { saveWidgetOptions } from '@utils/widgetOption';
import { saveWidgetFields } from '@utils/widgetField';
import sleep from 'sleep-promise';
import { getChartScales } from '@utils/chartscale';
import { useGlobalContext } from '@hooks/useGlobalContext';
import { saveProfile } from '@utils/profile';
import { useAuth } from '@hooks/useAuth';

const serializeForm = (data) => {
  const targetShown = !!data.target_is_active;
  const colorsChange = !!data.colors_change;
  return {
    ...data,
    source: toNumeric(data.source),
    field: toNumeric(data.field),
    show_navigator: toNumeric(data.show_navigator),
    decimal_places: toNumeric(data.decimal_places),

    text: data.text,
    text_color: data.text_color,
    object_color: data.object_color,
    background_color: data.background_color,
    alignment: data.alignment,
    font_type: data.font_type,
    font_size: data.font_size,
    image_width: data.image_width,
    image_height: data.image_height,
    image_base64: data.image_base64,
    image_name: data.image_name,
    image_scale_fit: data.image_scale_fit,
    image_keep_proportions: data.image_keep_proportions,
    margins: toNumeric(data.margins || '0'),

    range_min: toNumeric(data.range_min || '0'),
    range_max: toNumeric(data.range_max || '0'),
    target_is_active: targetShown,
    colors_change: colorsChange,
    target_source: targetShown ? toNumeric(data.target_source) : null,
    target_field: targetShown ? toNumeric(data.target_field) : null,
    target_value: targetShown ? toNumeric(data.target_value) : null,
    ranges: data.ranges.map((i) => ({
      ...i,
      range_min: toNumeric(i.range_min),
      range_max: toNumeric(i.range_max),
      color: i.color,
    })),
    options: data.options.map((i) => ({
      ...i,
      value: toNumeric(i.value),
      text: i.text,
      color: i.color,
    })),
    fields: data.fields.map((i) => ({
      ...i,
      thickness: toNumeric(i.thickness),
      transparency: toNumeric(i.transparency),
      type: toNumeric(i.type),
      decimal_places: toNumeric(i.decimal_places),
      scale_min: toNumeric(i.scale_min),
      scale_max: toNumeric(i.scale_max),
    })),
  };
};

const getInitialScale = (panel, scales) => {
  return scales.find((scale) => scale.name === '1H');
};

const WidgetDetail = ({ route, navigation }) => {
  const { params } = route;
  const linkTo = useLinkTo();
  const [isLoading, setIsLoading] = useState(true);
  const [widget, setWidget] = useState(null);
  const [widgets, setWidgets] = useState([]);
  const [sources, setSources] = useState([]);
  const [subtype, setSubtype] = useState(null);
  const [currentScale, setCurrentScale] = useState(null);
  const isNew = !params?.id;
  const copyRangeDialogRef = useRef(null);
  const copyOptionDialogRef = useRef(null);
  const widgetShown = Platform.OS === 'web';
  const { currentViewType } = useGlobalContext();
  const { userData, fetchUserData } = useAuth();

  const handleFontSize = (size) => {
    if (size) {
      handleInputChange('font_size')(size);
    }
  };

  const {
    values,
    errors,
    setValues,
    setValue,
    getValue,
    setError,
    getError,
    handleInputChange,
    handleInputFocus,
    arrayHelpers,
    isSubmitting,
    setIsSubmitting,
  } = useFastForm({
    source:
      global?.newConnectionId ||
      widget?.source ||
      userData?.user_last_source?.id,
    field: widget?.field,
    display_name: widget?.display_name,

    text: widget?.text,
    text_color: widget?.text_color ? widget.text_color : '#3b82f6',
    object_color: widget?.object_color,
    background_color: widget?.background_color ? widget.background_color: '#262a33',
    alignment: widget?.alignment,
    font_type: widget?.font_type,
    font_size: widget?.font_size,

    image_width: widget?.image_width ? widget?.image_width : 0,
    image_height: widget?.image_height ? widget?.image_height : 0,
    image_base64: widget?.image_base64,
    image_name: widget?.image_name,
    image_scale_fit: widget?.image_scale_fit,
    image_keep_proportions: widget?.image_keep_proportions,
    margins: widget?.margins,

    show_navigator: widget?.show_navigator,
    decimal_places: widget?.decimal_places,
    unit: widget?.unit,
    range_min: widget?.range_min,
    range_max: widget?.range_max,
    bool_value0: widget?.bool_value0,
    bool_value1: widget?.bool_value1,
    target_is_active: widget?.target_is_active,
    colors_change: widget?.colors_change,
    target_source: widget?.target_source,
    target_field: widget?.target_field,
    target_value: widget?.target_value,
    ranges:
      widget?.ranges.map((i) => ({
        id: i.id,
        range_min: i.range_min,
        range_max: i.range_max,
        color: i.color,
      })) || [],
    options:
      widget?.options.map((i) => ({
        id: i.id,
        value: i.value,
        text: i.text,
        color: i.color,
      })) || [],
    fields:
      widget?.fields.map((i) => ({
        ...i,
        display_name: i.display_name || i.data[`field${i.field}`],
      })) || [],
  });

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      setWidget(null);
      setSubtype(null);
      async function fetchWidget() {
        const results = await Promise.all([
          getWidget(params?.panel_id, params?.id).then(({ data }) =>
            retrieveChannelData({ data: [data] })
          ),
          getWidgets(params?.panel_id).then(retrieveChannelData),
          getSources(),
          getSubTypes().then(({ data }) =>
            data.find((f) => f.id.toString() === params?.subtype)
          ),
          getChartScales(),
        ]);
        const currentWidget = results[0][0];
        setWidget(currentWidget);
        setWidgets(results[1]);
        setSources(results[2].data);
        setSubtype(results[3] || currentWidget?.subtype_detail);
        setCurrentScale(getInitialScale(null, results[4].data));
        setIsLoading(false);
      }
      async function fetchNewWidget() {
        const results = await Promise.all([
          getWidgets(params?.panel_id).then(retrieveChannelData),
          getSources(),
          getSubTypes().then(({ data }) =>
            data.find((f) => f.id.toString() === params?.subtype)
          ),
          getChartScales(),
        ]);
        setWidgets(results[0]);
        setSources(results[1].data);
        setSubtype(results[2]);
        setCurrentScale(getInitialScale(null, results[3].data));
        setIsLoading(false);
      }
      if (isNew) {
        fetchNewWidget();
      } else {
        fetchWidget();
      }
      return () => {
        global.newConnectionId = null;
      };
    }, [isNew, params?.panel_id, params?.id, params?.subtype])
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: (props) => (
        <CustomHeaderRight
          {...props}
          route={route}
          overflowMenu={(rest) => (
            <DeleteItemMenu
              {...rest}
              onPress={() =>
                deleteWidget(params?.panel_id, params?.id).then(() =>
                  linkTo(`/panels/${params?.panel_id}/widgets`)
                )
              }
            />
          )}
        />
      ),
    });
  }, [navigation, params]);

  const cmbFieldOptions = useMemo(() => {
    const selectedSource = sources.find(
      (source) => source.id.toString() === values.source
    );
    if (selectedSource) {
      return selectedSource.allFields.map((i) => (
        <Select.Item
          label={`${i.name} (${i.id})`}
          key={i.id}
          value={i.id.toString()}
        />
      ));
    }
  }, [sources, values.source]);

  const cmbTargetFieldOptions = useMemo(() => {
    const selectedSource = sources.find(
      (source) => source.id.toString() === values.target_source
    );
    if (selectedSource) {
      return selectedSource.allFields.map((i) => (
        <Select.Item
          label={`${i.name} (${i.id})`}
          key={i.id}
          value={i.id.toString()}
        />
      ));
    }
  }, [sources, values.target_source]);

  const handleSourceChange = (text) => {
    if (text === '-1') {
      linkTo('/connections/new?selectable=1');
    } else {
      saveProfile({
        user_last_source: text,
      }).then(fetchUserData);
      return handleInputChange('source', true)(text);
    }
  };

  const handleRangeMaxChange = (index, text) => {
    setValue(`ranges.${index}.range_max`, text);
    if (values.ranges.length > 1 && index < values.ranges.length - 1) {
      setValue(`ranges.${index + 1}.range_min`, text);
    }
  };

  const handleChangeAlignText = (alignment) => {
    const value = alignRef.current.value;
    alignRef.current.value = `${value}${alignment}`;
  };

  const handleRangeCopy = (source) => {
    source.ranges.forEach((range, index) => {
      arrayHelpers.push('ranges', {
        id: (values.ranges.length + index + 1) * -1,
        range_min: range?.range_min?.toString() || '',
        range_max: range?.range_max?.toString() || '',
        color: range.color,
      });
    });
  };

  const handleOptionCopy = (source) => {
    source.options.forEach((range, index) => {
      arrayHelpers.push('options', {
        id: (values.options.length + index + 1) * -1,
        value: range?.value?.toString() || '',
        text: range?.text?.toString() || '',
        color: range.color || '#3b82f6',
      });
    });
  };

  const handleFormSubmit = async ({ addField = false }) => {
    let fails = false;
    /*
    if (subtype.type_tag !== 'T') {
      if (isBlank(values.source)) {
        setError('source', customMessages.isRequiredField);
        fails = true;
      }
      if (isBlank(values.field)) {
        setError('field', customMessages.isRequiredField);
        fails = true;
      }
    }
    */
    const lengthRange = values.ranges.length - 1;
    values.ranges.forEach(({ range_min, range_max }, index) => {
      if (lengthRange === 0 || index < lengthRange) {
        if (isBlank(range_max)) {
          setError(`ranges.${index}.range_max`, customMessages.isRequiredField);
          fails = true;
        } else if (_.toNumber(range_min) >= _.toNumber(range_max)) {
          setError(
            `ranges.${index}.range_max`,
            `Max ${customMessages.greaterThan} min`
          );
          fails = true;
        }
      }
    });

    if (lengthRange === -1) {
      values.colors_change = 0;
    }

    values.options.forEach(({ value, text }, index) => {
      if (isBlank(value)) {
        setError(`options.${index}.value`, customMessages.isRequiredField);
        fails = true;
      }
      if (isBlank(text)) {
        setError(`options.${index}.text`, customMessages.isRequiredField);
        fails = true;
      }
    });
    if (!fails) {
      setIsSubmitting(true);
      let currentWidget = widget;
      const serialized = serializeForm(values);

      const data = {
        ...currentWidget,
        ...serialized,
        subtype: subtype.id,
      };
      if (isNew) {
        const { data: newWidget } = await createWidget(
          params?.panel_id,
          {
            subtype: subtype.id,
            ...data,
          },
          currentViewType
        );
        currentWidget = newWidget;
      } else {
        await saveWidget(params?.panel_id, currentWidget.id, data);
      }
      if (['D', 'G'].includes(subtype.type_tag)) {
        await saveWidgetRanges(params?.panel_id, currentWidget.id, data.ranges);
      }
      if (
        ['R'].includes(subtype.type_tag) ||
        (subtype.type_tag === 'T' && values.target_is_active)
      ) {
        await saveWidgetOptions(
          params?.panel_id,
          currentWidget.id,
          data.options
        );
      }
      if (subtype.type_tag === 'T') {
        await saveWidgetFields(
          params?.panel_id,
          currentWidget.id,
          data.fields.map((field, index) => ({
            ...field,
            sort_order: index + 1,
          }))
        );
      }
      setIsSubmitting(false);
      if (addField) {
        linkTo(
          `/panels/${params?.panel_id}/widgets/${currentWidget.id}/fields/new`
        );
      } else {
        linkTo(`/panels/${params?.panel_id}/widgets`);
      }
    }
  };

  const [scaleFit, setScaleFit] = useState(false);
  const [keepProportions, setkeepProportions] = useState(false);
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [isImageName, setImageName] = useState(null);

  const pickImage = async () => {
    let result = null;

    if (Platform.OS === 'web') {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/png';
      input.capture = 'camera';

      input.onchange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async () => {
          const base64 = reader.result;
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {

            handleInputChange('image_base64')(base64);
            handleInputChange('image_width')(
              image.width > 546 ? 546 : image.width
            );
            handleInputChange('image_height')(
              image.height > 220 ? 220 : image.height
            );
            handleInputChange('image_name')(file.name);
            setImageLoaded(true);
            setImageName(file.name);
          };
        };

        reader.readAsDataURL(file);
      };

      input.click();
    } else {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      
      if (status === 'granted') {
        result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          quality: 1,
          base64: true,
          type: 'png',
        });

        if (!result.cancelled) {
          const image = new Image();
          image.src = `data:image/png;base64,${result.base64}`;
          image.onload = () => {
            handleInputChange('image_base64')(result.base64);
            handleInputChange('image_width')(image.width);
            handleInputChange('image_height')(image.height);
            setImageLoaded(true);
            setImageName(result.uri.split('/').pop());
            handleInputChange('image_name')(result.uri.split('/').pop());
          };
        }
      } else {
        alert('Access to the image gallery is required to select an image.');
      }
    }
  };

  const handleCheckboxChangeRange = (value) => {
    handleInputChange('colors_change')(value);

    if (value) {
      handleInputChange('text_color')('#3b82f6');
      handleInputChange('background_color')('#262a33');  
    } else {
      if (values.ranges.length > 0) {
        for (let x = values.ranges.length - 1; x >=0; x--) {
          arrayHelpers.remove('ranges', x);   
        }  
      }
    }
  };

  const handleScaleFit = (value) => {
    const newValue = value ? 'T' : 'F';
    setScaleFit(newValue);
    if (!value) {
      setkeepProportions('F');
      handleInputChange('image_keep_proportions')('F');
      handleInputChange('alignment')('center|center');
      handleInputChange('margins')(0);
    }
    handleInputChange('image_scale_fit')(newValue);
  };

  const handlekeepProportions = (value) => {
    const newValue = value ? 'T' : 'F';
    setkeepProportions(newValue);
    handleInputChange('image_keep_proportions')(newValue);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Container bgColor={bodyBgColor.dark}>
      <VStack>
        <Hidden till="md">
          <HStack py={2} alignItems="center" justifyContent="space-between">
            <Header subtype={subtype} />
          </HStack>
        </Hidden>
        <Flex direction="row" w="100%" mb={{ base: 0, md: 5 }}>
          <VStack
            space={3}
            flex={1}
            mr={1}
            w="50%"
            _light={{ bg: { md: 'containerBgColorLight' } }}
            _dark={{ bg: { md: 'containerBgColorDark' } }}
            shadow={{ md: '1' }}
            borderRadius={{ md: '4' }}
            py={{ base: 2, md: 5 }}
            px={{ base: 2, md: 5 }}
          >
            {subtype.type_tag !== 'T' && subtype.type_tag !== 'I' && (
              <VStack space={3}>
                <FormControl isRequired isInvalid={!!errors.source}>
                  <FormControl.Label>Connection</FormControl.Label>
                  <Select
                    placeholder="Please select..."
                    onValueChange={handleSourceChange}
                    selectedValue={values.source}
                  >
                    <Select.Item label="New Connection" value={-1} />
                    {sources.map((i) => (
                      <Select.Item
                        label={i.display_name}
                        key={i.id}
                        value={i.id.toString()}
                      />
                    ))}
                  </Select>
                  <FormControl.ErrorMessage>
                    {errors.source}
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!errors.field}>
                  <FormControl.Label>Field</FormControl.Label>
                  <Select
                    placeholder="Please select..."
                    onValueChange={handleInputChange('field', true)}
                    selectedValue={values.field}
                  >
                    {cmbFieldOptions}
                  </Select>
                  <FormControl.ErrorMessage>
                    {errors.field}
                  </FormControl.ErrorMessage>
                </FormControl>
              </VStack>
            )}
            <FormControl>
              <FormControl.Label>Display name</FormControl.Label>
              <ControlledField
                value={values.display_name}
                onChangeText={handleInputChange('display_name')}
              >
                {({ value, onChangeText }) => (
                  <Input
                    onChangeText={onChangeText}
                    onFocus={handleInputFocus('display_name')}
                    value={value}
                    maxLength={100}
                  />
                )}
              </ControlledField>
            </FormControl>
            {subtype.type_tag === 'T' && (
              <VStack space={3}>
                <FormControl>
                  <FormControl.Label>Show time navigator</FormControl.Label>
                  <Radio.Group
                    name="show_navigator"
                    value={
                      isBlank(values.show_navigator)
                        ? '1'
                        : String(values.show_navigator)
                    }
                    onChange={handleInputChange('show_navigator')}
                  >
                    <Stack
                      direction={{
                        base: 'column',
                        md: 'row',
                      }}
                      alignItems={{
                        base: 'flex-start',
                        md: 'center',
                      }}
                      space={4}
                      w="75%"
                      maxW="300px"
                    >
                      <Radio value="1" size="sm">
                        Always
                      </Radio>
                      <Radio value="0" size="sm">
                        Never
                      </Radio>
                      <Radio value="2" size="sm">
                        Only on big screen
                      </Radio>
                    </Stack>
                  </Radio.Group>
                </FormControl>
              </VStack>
            )}
            {['G', 'D'].includes(subtype.type_tag) && (
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormControl style={{ width: '47%' }}>
                  <FormControl.Label>Decimal places</FormControl.Label>
                  <ControlledField
                    value={values.decimal_places}
                    onChangeText={handleInputChange('decimal_places')}
                  >
                    {({ value, onChangeText }) => (
                      <Input
                        keyboardType="numeric"
                        onChangeText={(text) =>
                          onChangeText(text.replace(/[^0-9]/g, ''))
                        }
                        maxLength={2}
                        onFocus={handleInputFocus('decimal_places')}
                        value={value}
                      />
                    )}
                  </ControlledField>
                </FormControl>
                {!['TH'].includes(subtype.tag) && (
                  <View style={{ width: '47%' }}>
                    <FormControl>
                      <FormControl.Label>Unit</FormControl.Label>
                      <ControlledField
                        value={values.unit}
                        onChangeText={handleInputChange('unit')}
                      >
                        {({ value, onChangeText }) => (
                          <Input
                            onChangeText={onChangeText}
                            onFocus={handleInputFocus('unit')}
                            value={value}
                            maxLength={5}
                          />
                        )}
                      </ControlledField>
                    </FormControl>
                  </View>
                )}
                {['TH'].includes(subtype.tag) && (
                  <View style={{ width: '47%' }}>
                    <FormControl>
                      <FormControl.Label>Unit</FormControl.Label>
                      <Select
                        placeholder="Please select..."
                        onValueChange={handleInputChange('unit', true)}
                        selectedValue={values.unit}
                      >
                        <Select.Item label="Fahrenheit" value="°F" />
                        <Select.Item label="Celsius" value="°C" />
                        <Select.Item label="Kelvin" value="K" />
                      </Select>
                    </FormControl>
                  </View>
                )}
              </Flex>
            )}
            {(['L'].includes(subtype.type_tag) ||
              ['PB'].includes(subtype.tag)) && (
              <VStack space={3}>
                <FormControl>
                  <FormControl.Label>Text</FormControl.Label>
                  <ControlledField
                    value={values.text}
                    onChangeText={handleInputChange('text')}
                  >
                    {({ value, onChangeText }) => (
                      <Input
                        onChangeText={onChangeText}
                        onFocus={handleInputFocus('text')}
                        value={value}
                        maxLength={100}
                      />
                    )}
                  </ControlledField>
                </FormControl>
              </VStack>
            )}
            {['L', 'D'].includes(subtype.type_tag) && (
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormControl style={{ width: '47%' }}>
                  <FormControl.Label>Font type</FormControl.Label>
                  <ControlledField
                    value={values.font_type}
                    onChangeText={handleInputChange('font_type')}
                    selectedValue={values.font_type}
                  >
                    {() => (
                      <TextChangeFontType
                        onChangeText={handleInputChange('font_type')}
                        fontType={values.font_type}
                      />
                    )}
                  </ControlledField>
                </FormControl>
                <FormControl style={{ width: '47%' }}>
                  <FormControl.Label>Font size</FormControl.Label>
                  <ControlledField
                    value={values.font_size}
                    onChangeText={handleFontSize}
                    onFocus={handleFontSize}
                    selectedValue={values.font_size}
                  >
                    {() => (
                      <TextChangeFontSize
                        onChangeText={handleFontSize}
                        fontSize={
                          !values.font_size ? 'medium' : values.font_size
                        }
                      />
                    )}
                  </ControlledField>
                </FormControl>
              </Flex>
            )}

            {(['D', 'L'].includes(subtype.type_tag) ||
              (['I'].includes(subtype.type_tag) &&
                values.image_scale_fit !== 'T')) && (
              <View
                style={{
                  width: '330px',
                }}
              >
                <FormControl>
                  <ControlledField
                    value={values.alignment}
                    onChangeText={handleInputChange('alignment')}
                  >
                    {() => (
                      <AlignmentButtons
                        title={
                          ['I'].includes(subtype.type_tag)
                            ? 'Alignment'
                            : 'Text alignment'
                        }
                        onChangeText={handleInputChange('alignment')}
                        textAlignment={
                          !values.alignment ? 'center|center' : values.alignment
                        }
                      />
                    )}
                  </ControlledField>
                </FormControl>
              </View>
            )}
            {((subtype.type_tag === 'L') || (subtype.type_tag === 'D' && values.colors_change != 1)) && (
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormControl style={{ width: '47%' }}>
                  <FormControl.Label>Color</FormControl.Label>
                  <ControlledField
                    value={values.text_color}
                    onChangeText={handleInputChange('text_color')}
                  >
                    {() => (
                      <ColorInput
                        onChange={handleInputChange('text_color')}
                        value={values.text_color}
                        showPreview={false}
                      />
                    )}
                  </ControlledField>
                </FormControl>
                <FormControl style={{ width: '47%' }}>
                  <FormControl.Label>Background color</FormControl.Label>
                  <ControlledField
                    value={values.background_color}
                    onChangeText={handleInputChange('background_color')}
                  >
                    {() => (
                      <ColorInput
                        onChange={handleInputChange('background_color')}
                        value={values.background_color}
                        showPreview={false}
                      />
                    )}
                  </ControlledField>
                </FormControl>
              </Flex>
            )}
            {['PB'].includes(subtype.tag) && (
              <View style={{ flexDirection: 'row' }}>
                <FormControl style={{ width: '120px', marginLeft: 0 }}>
                  <FormControl.Label>Color</FormControl.Label>
                  <ControlledField
                    value={values.object_color}
                    onChangeText={handleInputChange('object_color')}
                  >
                    {() => (
                      <ColorInput
                        onChange={handleInputChange('object_color')}
                        value={
                          !values.object_color ? '#3b82f6' : values.object_color
                        }
                        showPreview={false}
                      />
                    )}
                  </ControlledField>
                </FormControl>
              </View>
            )}

            {['I'].includes(subtype.type_tag) && (
              <>
                <VStack space={3}>
                  <Button
                    variant="outline"
                    leftIcon={<Icon as={Feather} name="image" />}
                    mt="2"
                    size="sm"
                    onPress={pickImage}
                  >
                    {values.image_name && (
                      <Text style={{ color: '#FFF' }}>{values.image_name}</Text>
                    )}
                    {!values.image_name && (
                      <Text style={{ color: '#FFF' }}>
                        Choose a file to upload.
                      </Text>
                    )}
                  </Button>
                </VStack>
                <VStack space={3}>
                  <Checkbox
                    value={scaleFit}
                    size="sm"
                    isChecked={values.image_scale_fit === 'T'}
                    onChange={handleScaleFit}
                  >
                    Scale to fit
                  </Checkbox>
                </VStack>

                {values.image_scale_fit === 'T' && (
                  <>
                    <VStack space={3}>
                      <Checkbox
                        value={keepProportions}
                        size="sm"
                        isChecked={values.image_keep_proportions === 'T'}
                        onChange={handlekeepProportions}
                      >
                        Keep proportions
                      </Checkbox>
                      <FormControl>
                        <FormControl.Label>Margins</FormControl.Label>
                        <Select
                          placeholder="Please select..."
                          onValueChange={handleInputChange('margins')}
                          selectedValue={values.margins ?? 0}
                        >
                          <Select.Item label="Full-width" value="0" />
                          <Select.Item label="Narrow" value="2" />
                          <Select.Item label="Moderate" value="4" />
                          <Select.Item label="Wide" value="6" />
                        </Select>
                      </FormControl>
                    </VStack>
                  </>
                )}
              </>
            )}
            {['D'].includes(subtype.type_tag) && (
              <VStack space={3}>
                <Checkbox
                  value="1"
                  size="sm"
                  isChecked={values.colors_change}
                  onChange={handleCheckboxChangeRange}
                >
                  Colors change depending on values ranges
                </Checkbox>
              </VStack>
            )}
            {['S'].includes(subtype.type_tag) ? (
              <VStack space={3}>
                <FormControl>
                  <FormControl.Label>Range (min)</FormControl.Label>
                  <ControlledField
                    value={values.range_min}
                    onChangeText={handleInputChange('range_min')}
                  >
                    {({ value, onChangeText }) => (
                      <Input
                        keyboardType="numeric"
                        onChangeText={onChangeText}
                        onFocus={handleInputFocus('range_min')}
                        value={value}
                      />
                    )}
                  </ControlledField>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Range (max)</FormControl.Label>
                  <ControlledField
                    value={values.range_max}
                    onChangeText={handleInputChange('range_max')}
                  >
                    {({ value, onChangeText }) => (
                      <Input
                        keyboardType="numeric"
                        onChangeText={onChangeText}
                        onFocus={handleInputFocus('range_max')}
                        value={value}
                      />
                    )}
                  </ControlledField>
                </FormControl>
              </VStack>
            ) : null}
            {['W'].includes(subtype.type_tag) ? (
              <VStack space={3}>
                <FormControl>
                  <FormControl.Label>Text for value 0</FormControl.Label>
                  <ControlledField
                    value={values.bool_value0}
                    onChangeText={handleInputChange('bool_value0')}
                  >
                    {({ value, onChangeText }) => (
                      <Input
                        onChangeText={onChangeText}
                        onFocus={handleInputFocus('bool_value0')}
                        value={value}
                        maxLength={32}
                      />
                    )}
                  </ControlledField>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Text for value 1</FormControl.Label>
                  <ControlledField
                    value={values.bool_value1}
                    onChangeText={handleInputChange('bool_value1')}
                  >
                    {({ value, onChangeText }) => (
                      <Input
                        onChangeText={onChangeText}
                        onFocus={handleInputFocus('bool_value1')}
                        value={value}
                        maxLength={32}
                      />
                    )}
                  </ControlledField>
                </FormControl>
              </VStack>
            ) : null}
            {(['G'].includes(subtype.type_tag) ||
              (subtype.type_tag === 'D' && values.colors_change == 1)) && (
              <VStack space={3}>
                {values.ranges.map((range, index) => (
                  <HStack key={index} space={3}>
                    <FormControl
                      flex="1"
                      flexGrow={{ base: '1', lg: '4' }}
                      isInvalid={getError(`ranges.${index}.range_min`)}
                    >
                      <FormControl.Label>Min</FormControl.Label>
                      <ControlledField
                        value={range.range_min}
                        onChangeText={handleInputChange(
                          `ranges.${index}.range_min`
                        )}
                      >
                        {({ value, onChangeText }) => (
                          <Input
                            keyboardType="numeric"
                            onChangeText={(text) =>
                              onChangeText(
                                ['CY'].includes(subtype.tag)
                                  ? text.replace(/[^0-9]/g, '')
                                  : text
                              )
                            }
                            onFocus={handleInputFocus(
                              `ranges.${index}.range_min`
                            )}
                            value={value}
                            isReadOnly={index > 0}
                          />
                        )}
                      </ControlledField>
                      <FormControl.ErrorMessage>
                        {getError(`ranges.${index}.range_min`)}
                      </FormControl.ErrorMessage>
                    </FormControl>
                    <FormControl
                      flex="1"
                      flexGrow={{ base: '1', lg: '4' }}
                      isInvalid={getError(`ranges.${index}.range_max`)}
                    >
                      <FormControl.Label>Max</FormControl.Label>
                      <ControlledField
                        value={range.range_max}
                        onChangeText={(text) =>
                          handleRangeMaxChange(index, text)
                        }
                      >
                        {({ value, onChangeText }) => (
                          <Input
                            keyboardType="numeric"
                            onChangeText={(text) =>
                              onChangeText(
                                ['CY'].includes(subtype.tag)
                                  ? text.replace(/[^0-9]/g, '')
                                  : text
                              )
                            }
                            onFocus={handleInputFocus(
                              `ranges.${index}.range_max`
                            )}
                            value={value}
                          />
                        )}
                      </ControlledField>
                      <FormControl.ErrorMessage>
                        {getError(`ranges.${index}.range_max`)}
                      </FormControl.ErrorMessage>
                    </FormControl>
                    <FormControl flex="1">
                      <FormControl.Label>Color</FormControl.Label>
                      <ColorInput
                        onChange={handleInputChange(`ranges.${index}.color`)}
                        onBlur={handleInputFocus(`ranges.${index}.color`)}
                        value={range.color}
                      />
                    </FormControl>
                    <IconButton
                      colorScheme="secondary"
                      variant="ghost"
                      alignSelf="baseline"
                      mt="7"
                      icon={<SmallCloseIcon size="4" />}
                      onPressOut={() => {
                        if (index > 0 && index < values.ranges.length - 1) {
                          const rangeMax = getValue(
                            `ranges.${index - 1}.range_max`
                          );
                          setValue(`ranges.${index + 1}.range_min`, rangeMax);
                        }
                        arrayHelpers.remove('ranges', index);
                        if (values.ranges.length === 0) {
                          setValue('target_is_active', '');
                          setValue('colors_change', '');
                        }
                      }}
                    />
                  </HStack>
                ))}
                <HStack space={3}>
                  <Button
                    leftIcon={<AddIcon size="4" />}
                    mt="2"
                    size="sm"
                    onPress={() =>
                      arrayHelpers.push('ranges', {
                        id: (values.ranges.length + 1) * -1,
                        range_min:
                          values.ranges.length > 0
                            ? values.ranges[values.ranges.length - 1].range_max
                            : '',
                        range_max: '',
                        color: '#3b82f6',
                      })
                    }
                    flex="1"
                    flexGrow={{ base: '1', lg: '2' }}
                  >
                    Add Range
                  </Button>
                  <Button
                    variant="outline"
                    leftIcon={<Icon as={Feather} name="copy" />}
                    mt="2"
                    size="sm"
                    onPress={() => copyRangeDialogRef.current.show()}
                  >
                    Copy from widget
                  </Button>
                </HStack>
                <CopyRangeDialog
                  ref={copyRangeDialogRef}
                  target={widget}
                  widgets={widgets}
                  subtype={subtype}
                  onConfirm={handleRangeCopy}
                />
              </VStack>
            )}
            {values.ranges.length > 0 && (
              <VStack space={3}>
                <Checkbox
                  value="1"
                  size="sm"
                  isChecked={values.target_is_active}
                  onChange={handleInputChange('target_is_active')}
                >
                  Offset ranges with target field
                </Checkbox>
                {values.target_is_active && (
                  <>
                    <FormControl>
                      <FormControl.Label>Target connection</FormControl.Label>
                      <Select
                        placeholder="Please select..."
                        onValueChange={handleInputChange('target_source', true)}
                        selectedValue={values.target_source}
                      >
                        {sources.map((o) => (
                          <Select.Item
                            label={o.display_name}
                            key={o.id}
                            value={o.id.toString()}
                          />
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormControl.Label>Target field</FormControl.Label>
                      <Select
                        placeholder="Please select..."
                        onValueChange={handleInputChange('target_field', true)}
                        selectedValue={`${values?.target_field}`}
                      >
                        {cmbTargetFieldOptions}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormControl.Label>
                        If missing, use this value
                      </FormControl.Label>
                      <ControlledField
                        value={values.target_value}
                        onChangeText={handleInputChange('target_value')}
                      >
                        {({ value, onChangeText }) => (
                          <Input
                            keyboardType="numeric"
                            onChangeText={onChangeText}
                            onFocus={handleInputFocus('target_value')}
                            value={value}
                          />
                        )}
                      </ControlledField>
                    </FormControl>
                  </>
                )}
              </VStack>
            )}
            {subtype.type_tag === 'T' && (
              <VStack space={3}>
                <VStack>
                  <FormControl.Label>Field</FormControl.Label>
                  <VStack>
                    {values.fields.map((field, index) => (
                      <HStack
                        key={field.id}
                        w="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        space={4}
                      >
                        <Text flex={1}>{field.display_name}</Text>
                        <HStack>
                          <IconButton
                            icon={
                              <ArrowDownIcon
                                size="md"
                                _light={{ color: 'coolGray.800' }}
                                _dark={{ color: 'warmGray.50' }}
                              />
                            }
                            onPressOut={() =>
                              arrayHelpers.down('fields', index)
                            }
                            isDisabled={values.fields.length - 1 === index}
                          />
                          <IconButton
                            icon={
                              <ArrowUpIcon
                                size="md"
                                _light={{ color: 'coolGray.800' }}
                                _dark={{ color: 'warmGray.50' }}
                              />
                            }
                            onPressOut={() => arrayHelpers.up('fields', index)}
                            isDisabled={index === 0}
                          />
                          <IconButton
                            icon={
                              <Icon
                                as={Feather}
                                name="edit-3"
                                _light={{ color: 'coolGray.800' }}
                                _dark={{ color: 'warmGray.50' }}
                              />
                            }
                            onPressOut={() =>
                              linkTo(
                                `/panels/${params?.panel_id}/widgets/${params?.id}/fields/${field.id}`
                              )
                            }
                          />
                        </HStack>
                      </HStack>
                    ))}
                  </VStack>
                  <Button
                    leftIcon={<AddIcon size="4" />}
                    mt="2"
                    onPress={() =>
                      handleFormSubmit({
                        addField: true,
                      })
                    }
                    size="sm"
                    isLoading={isSubmitting}
                  >
                    Add Field
                  </Button>
                </VStack>
                <Checkbox
                  value="1"
                  size="sm"
                  isChecked={values.target_is_active}
                  onChange={handleInputChange('target_is_active')}
                >
                  Set X-axis time markers
                </Checkbox>
                {values.target_is_active && (
                  <>
                    <FormControl>
                      <FormControl.Label>Connection</FormControl.Label>
                      <Select
                        placeholder="Please select..."
                        onValueChange={handleInputChange('target_source', true)}
                        selectedValue={values.target_source}
                      >
                        {sources.map((o) => (
                          <Select.Item
                            label={o.display_name}
                            key={o.id}
                            value={o.id.toString()}
                          />
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormControl.Label>Field</FormControl.Label>
                      <Select
                        placeholder="Please select..."
                        onValueChange={handleInputChange('target_field', true)}
                        selectedValue={`${values?.target_field}`}
                      >
                        {cmbTargetFieldOptions}
                      </Select>
                    </FormControl>
                  </>
                )}
              </VStack>
            )}
            {(['R'].includes(subtype.type_tag) ||
              (subtype.type_tag === 'T' && values.target_is_active)) && (
              <VStack space={3}>
                {values.options.map((option, index) => (
                  <HStack key={index} space={3}>
                    <FormControl
                      flex="1"
                      flexGrow={{ base: '1', lg: '4' }}
                      isRequired
                      isInvalid={getError(`options.${index}.value`)}
                    >
                      <FormControl.Label>Value</FormControl.Label>
                      <ControlledField
                        value={option.value}
                        onChangeText={handleInputChange(
                          `options.${index}.value`
                        )}
                      >
                        {({ value, onChangeText }) => (
                          <Input
                            keyboardType="numeric"
                            onChangeText={onChangeText}
                            onFocus={handleInputFocus(`options.${index}.value`)}
                            value={value}
                          />
                        )}
                      </ControlledField>
                      <FormControl.ErrorMessage>
                        {getError(`options.${index}.value`)}
                      </FormControl.ErrorMessage>
                    </FormControl>
                    <FormControl
                      flex="1"
                      flexGrow={{ base: '1', lg: '4' }}
                      isRequired
                      isInvalid={getError(`options.${index}.text`)}
                    >
                      <FormControl.Label>Text</FormControl.Label>
                      <ControlledField
                        value={option.text}
                        onChangeText={handleInputChange(
                          `options.${index}.text`
                        )}
                      >
                        {({ value, onChangeText }) => (
                          <Input
                            onChangeText={onChangeText}
                            onFocus={handleInputFocus(`options.${index}.text`)}
                            value={value}
                          />
                        )}
                      </ControlledField>
                      <FormControl.ErrorMessage>
                        {getError(`options.${index}.text`)}
                      </FormControl.ErrorMessage>
                    </FormControl>
                    <FormControl flex="1">
                      <FormControl.Label>Color</FormControl.Label>
                      <ColorInput
                        onChange={handleInputChange(`options.${index}.color`)}
                        onBlur={handleInputFocus(`options.${index}.color`)}
                        value={option.color || '#3b82f6'}
                      />
                    </FormControl>
                    <IconButton
                      colorScheme="secondary"
                      variant="ghost"
                      alignSelf="baseline"
                      mt="7"
                      icon={<SmallCloseIcon size="4" />}
                      onPressOut={() => arrayHelpers.remove('options', index)}
                    />
                  </HStack>
                ))}
                <HStack space={3}>
                  <Button
                    leftIcon={<AddIcon size="4" />}
                    mt="2"
                    size="sm"
                    onPress={() =>
                      arrayHelpers.push('options', {
                        id: (values.options.length + 1) * -1,
                        value: '',
                        text: '',
                        color: '#3b82f6',
                      })
                    }
                    flex="1"
                    flexGrow={{ base: '1', lg: '2' }}
                  >
                    Add Option
                  </Button>
                  <Button
                    variant="outline"
                    leftIcon={<Icon as={Feather} name="copy" />}
                    mt="2"
                    size="sm"
                    onPress={() => copyOptionDialogRef.current.show()}
                  >
                    Copy from widget
                  </Button>
                </HStack>
                <CopyOptionDialog
                  ref={copyOptionDialogRef}
                  target={widget}
                  widgets={widgets}
                  subtype={subtype}
                  onConfirm={handleOptionCopy}
                />
              </VStack>
            )}
            <DockedFormFooter
              isSubmitting={isSubmitting}
              onDiscardPress={() =>
                linkTo(`/panels/${route.params?.panel_id}/widgets`)
              }
              onSavePress={handleFormSubmit}
            />
          </VStack>
          {widgetShown && (
            <VStack
              space={3}
              flex={1}
              alignSelf="flex-start"
              ml={1}
              w="50%"
              h={subtype.type_tag === 'T' ? 400 : 220}
              _light={{ bg: { md: 'containerBgColorLight' } }}
              _dark={{ bg: { md: 'containerBgColorDark' } }}
              shadow={{ md: '1' }}
              borderRadius={{ md: '4' }}
            >
              <WidgetWrapper
                widget={widget}
                values={values}
                subtype={subtype}
                sources={sources}
                currentScale={currentScale}
              />
            </VStack>
          )}
        </Flex>
      </VStack>
    </Container>
  );
};

function WidgetWrapper({ widget, values, subtype, sources, currentScale }) {
  const [isLoading, setIsLoading] = useState(true);
  const [newWidget, setNewWidget] = useState(null);
  const [pollingDelay] = useState(5000);
  const [pollingEnabled, setPollingEnabled] = useState(false);

  useEffect(() => {
    const serialized = serializeForm(values);
    const source = sources.find((i) => i.id === serialized.source);
    const sourceTarget = sources.find((i) => i.id === serialized.target_source);
    const newData = {
      ...widget,
      ...newWidget,
      ...serialized,
      subtype: subtype.id,
      subtype_detail: subtype,
      subtype_tag: subtype.tag,
      type_tag: subtype.type_tag,
      breakpoints: subtype.breakpoints,
      channel_id: source?.channel_id,
      read_api_key: source?.read_api_key,
      target_channel_id: sourceTarget?.channel_id,
      target_read_api_key: sourceTarget?.read_api_key,
      is_owner: true,
    };

    async function fetchChannel() {
      if (
        newWidget?.source !== newData.source ||
        newWidget?.field !== newData.field ||
        newWidget?.target_is_active !== newData.target_is_active ||
        newWidget?.target_source !== newData.target_source ||
        newWidget?.target_field !== newData.target_field ||
        (subtype.type_tag === 'T' &&
          (!_.isEqual(newWidget?.options, newData.options) ||
            !_.isEqual(
              newWidget?.fields.map((i) => i.id),
              newData.fields.map((i) => i.id)
            ) ||
            newWidget?.show_navigator !== newData.show_navigator))
      ) {
        setIsLoading(true);
        const [result] = await retrieveChannelData({ data: [newData] });
        setNewWidget(result);
        await sleep(500);
        setIsLoading(false);
      } else {
        setNewWidget(newData);
      }
    }

    fetchChannel();
  }, [values, widget, subtype, sources]);

  if (
    subtype.type_tag === 'T' &&
    (!newWidget?.fields || newWidget.fields.length === 0)
  ) {
    return (
      <Center flex={1} contentContainerStyle={{ alignItems: 'center' }}>
        <Text>No fields added</Text>
      </Center>
    );
  }

  if (isLoading) {
    return (
      <Center flex={1} contentContainerStyle={{ alignItems: 'center' }}>
        <Spinner size="lg" />
      </Center>
    );
  }
  /*
  if (subtype.type_tag !== 'T' && !newWidget?.source) {
    return (
      <Center flex={1} contentContainerStyle={{ alignItems: 'center' }}>
        <Text>No connection selected2222 {subtype.type_tag} {global.newConnectionId}</Text>
      </Center>
    );
  }
*/
  return (
    <Widget
      widget={newWidget}
      setWidgets={() => {}}
      currentScale={currentScale}
      pollingDelay={pollingDelay}
      pollingEnabled={pollingEnabled}
      isWritable={false}
    />
  );
}

export { WidgetDetail };
