import { ChartWrapper } from '@components/ChartWrapper';
import { useForceUpdate } from '@hooks/useForceUpdate';
import { useGlobalContext } from '@hooks/useGlobalContext';
import { customMessages } from '@utils/customMessages';
import {
  fcFormatDate,
  fcOutTimeFormat,
  tsDateFormat,
} from '@utils/dateTimeHelper';
import { iterateFetchChannelFeeds } from '@utils/thingSpeakAPI_2';
import { differenceInMinutes, endOfDay, format, isToday, sub } from 'date-fns';
import Constants from 'expo-constants';
import FusionCharts from 'fusioncharts';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import _ from 'lodash';
import { Box, ZStack, useColorMode } from 'native-base';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactFC from 'react-fusioncharts';
import {
  Loading,
  LoadingData,
  NoFields,
  disableAllUserEvents,
  getInitialData,
  getPaletteColors,
  getSchema,
  getXAxis,
  getYAxis,
  getTimemarker,
} from './common';

FusionCharts.options.license({
  key: Constants.manifest.extra.fcKey,
  creditLabel: false,
});

ReactFC.fcRoot(FusionCharts, TimeSeries, CandyTheme, FusionTheme);

const SimpleTimeSeries = forwardRef(
  ({ widget, currentScale, setWidgetPropValue, isDnD }, ref) => {
    const {
      fields,
      options,
      target_channel_id,
      target_field,
      target_read_api_key,
      targetFeeds,
      breakpoints,
      show_navigator,
      isMounted,
    } = widget;
    const {
      id: scaleId,
      start: scaleStart,
      timescale,
      from: scaleFrom,
      to: scaleTo,
    } = currentScale;
    const { colorMode } = useColorMode();
    const [forceUpdate, setForceUpdate] = useForceUpdate();
    const [isLoadingChart, setIsLoadingChart] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isRendered, setIsRendered] = useState(false);
    const containerRef = useRef(null);
    const chartRef = useRef(null);
    const dataStoreRef = useRef(null);
    const { winDims, toast } = useGlobalContext();
    const { w, h } = winDims;
    const bpShowNavigator =
      show_navigator === 2
        ? w.in >= breakpoints.showNavigator.w &&
          h.in >= breakpoints.showNavigator.h
        : show_navigator;
    const schema = getSchema(fields);
    const timemarkerRef = useRef([]);
    const [zoomMin, setZoomMin] = useState(null);

    const dataSource = {
      chart: {
        theme: colorMode === 'dark' ? 'candy' : 'fusion',
        palettecolors: getPaletteColors(fields),
      },
      tooltip: {
        enabled: '1',
        outputTimeFormat: {
          second: fcOutTimeFormat,
        },
      },
      caption: {
        text: null,
        style: {
          text: {
            'font-size': '12',
            'font-weight': 'medium',
          },
        },
      },
      plotConfig: {
        generic: {
          connectNullData: '1',
        },
      },
      navigator: {
        enabled: bpShowNavigator,
      },
      extensions: {
        customRangeSelector: {
          enabled: '0',
        },
        standardRangeSelector: {
          enabled: '0',
        },
      },
    };

    useEffect(() => {
      setWidgetPropValue('isMounted', false);
      setIsLoadingChart(true);
      setIsLoadingData(true);
      setIsRendered(false);
      setForceUpdate();
    }, [scaleId, scaleFrom, scaleTo]);

    async function getSeriesData() {
      toast.closeAll();
      const now = new Date();
      const start = scaleFrom || sub(now, { minutes: scaleStart });
      const scaleEndDay = scaleTo ? endOfDay(scaleTo) : now;
      const end = isToday(scaleTo) ? now : scaleEndDay;
      const xaxis = getXAxis(currentScale, now, zoomMin);
      const yaxis = getYAxis(fields);
      const initialData = getInitialData(start, end, xaxis);
      timemarkerRef.current = [];
      dataStoreRef.current = null;
      const dataStore = new FusionCharts.DataStore();
      dataStore.createDataTable(initialData, schema);

      const onRead = ({ id: ch_id, data }) => {
        if (fields.some((field) => field.channel_id === ch_id)) {
          const rows = data.map((record) => {
            const row = fields.map((serie) => {
              if (ch_id === serie.channel_id) {
                return record[`field${serie.field}`];
              }
              return null;
            });
            row.unshift(fcFormatDate(record.created_at));
            return row;
          });
          dataStore.appendRows(rows);
          if (chartRef.current) {
            chartRef.current.chartObj.setChartData({
              ...dataSource,
              data: dataStore.getDataTable(),
              xaxis: {
                ...xaxis,
                timemarker: timemarkerRef.current,
              },
              yaxis,
            });
          }
          setIsLoadingChart(false);
          setIsLoadingData(true);
        }
        if (target_channel_id === ch_id) {
          timemarkerRef.current = getTimemarker(
            timemarkerRef.current,
            data,
            target_field,
            options
          );
        }
      };

      const inChannels = fields.map((record) => ({
        channelId: record.channel_id,
        apiKey: record.read_api_key,
      }));
      inChannels.push({
        channelId: target_channel_id,
        apiKey: target_read_api_key,
      });
      const results = await iterateFetchChannelFeeds({
        channels: inChannels,
        options: {
          recordsLimit: 100000,
          start,
          end,
          timescale,
          round: _.max(fields.map(({ decimal_places }) => decimal_places)),
        },
        onRead,
      });
      // console.log(results);
      if (chartRef.current?.chartObj) {
        chartRef.current.chartObj.setChartData({
          ...dataSource,
          data: dataStore.getDataTable(),
          xaxis: {
            ...xaxis,
            timemarker: timemarkerRef.current,
          },
          yaxis,
        });
        dataStoreRef.current = dataStore;
        setIsLoadingData(false);
        setWidgetPropValue('isMounted', true);
      }
    }

    useEffect(() => {
      if (isRendered) {
        getSeriesData();
        disableAllUserEvents(containerRef.current, ['mousewheel', 'wheel']);
      }
    }, [isRendered]);

    const setChartData = useCallback(() => {
      try {
        const rows = [];
        fields.forEach((serie, index) => {
          if (serie.feeds) {
            serie.feeds.forEach((feed) => {
              const createdAt = fcFormatDate(feed.created_at);
              let row = rows.find((r) => r[0] === createdAt);
              if (!row) {
                row = [createdAt, ...Array(fields.length).fill(null)];
              }
              row[index + 1] = feed[`field${serie.field}`];
              rows.push(row);
            });
          }
        });
        if (rows.length > 0) {
          const now = _.max([
            new Date(),
            ...fields.map((serie) => serie.lastEntry?.created_at),
          ]);
          const dataStore = dataStoreRef.current;
          dataStore.appendRows(rows);
          const { chartObj } = chartRef.current;
          const chartData = chartObj.getChartData();
          chartData.data = dataStore.getDataTable();
          const xaxis = getXAxis(currentScale, now, zoomMin);

          timemarkerRef.current = getTimemarker(
            timemarkerRef.current,
            targetFeeds,
            target_field,
            options,
            true
          );
          chartData.xaxis = {
            ...chartData.xaxis,
            ...xaxis,
            timemarker: timemarkerRef.current,
          };
          chartData.navigator.enabled = bpShowNavigator;
          chartObj.setChartData(chartData);
          dataStoreRef.current = dataStore;
        }
      } catch (e) {
        console.log(e);
      }
    }, [bpShowNavigator, fields, targetFeeds, currentScale]);

    useEffect(() => {
      if (isMounted) {
        setChartData();
      }
    }, [isMounted, setChartData]);

    if (fields.length === 0) {
      return <NoFields />;
    }

    const handleChartUpdate = () => {
      const { contextLimit, focusLimit } = chartRef.current.chartObj.apiInstance.config;
      setWidgetPropValue(
        'isMounted',
        focusLimit[1] >= contextLimit[1]
      );

      const minX = focusLimit[0];
      setZoomMin(minX);
    };

    return (
      <ZStack
        flex={1}
        alignItems="center"
        justifyContent="center"
        h="100%"
        w="100%"
      >
        <Box ref={containerRef} h="96%" w="96%" bottom="0">
          <ChartWrapper
            render={({ adjustedHeight }) => {
              return (
                <div className="fc-fusiontime-container">
                  <ReactFC
                    ref={chartRef}
                    key={forceUpdate}
                    type="timeseries"
                    dataEmptyMessage="Chart is loading..."
                    renderAt="chart-container"
                    width="100%"
                    height={adjustedHeight}
                    dataFormat="json"
                    onRender={() => setIsRendered(true)}
                    fcEvent-timeNavBrushEnd={() => {
                      handleChartUpdate();
                    }}                    
                  />
                </div>
              );
            }}
          />
        </Box>
        {isLoadingChart && <Loading />}
        {isRendered && <LoadingData isLoadingData={isLoadingData} />}
        {isDnD && <Box w="100%" h="100%" position="absolute" zIndex={999} />}
      </ZStack>
    );
  }
);

const MemoizedTimeSeries = memo(SimpleTimeSeries);

export { MemoizedTimeSeries as TimeSeries };
