import { DeleteIconButton } from '@components/DeleteIconButton';
import Feather from '@expo/vector-icons/Feather';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useGlobalContext } from '@hooks/useGlobalContext';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { deletePanel } from '@utils/panel';
import { Button, HStack, Icon, Text, useToast } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

function Header({
  panel,
  setIsLoading,
  isSubmitting,
  onSaveChanges,
  onAccessToggle,
}) {
  const linkTo = useLinkTo();
  const toast = useToast();
  const { alertDialog, currentViewType, setCurrentViewType } =
    useGlobalContext();
  const route = useRoute();

  const handlePanelPreview = () => {
    linkTo(`/panels/${panel.id}/preview`);
  };

  return (
    <HStack space={2}>
      <Button
        variant="outline"
        isDisabled={isSubmitting}
        isPressed={!panel.is_private}
        onPress={onAccessToggle}
      >
        {panel.is_private ? 'Private' : 'Public'}
      </Button>
      <Button
        leftIcon={<Icon as={Ionicons} name="search" />}
        isDisabled={isSubmitting}
        onPress={async () => {
          const result = await onSaveChanges();
          if (result) {
            handlePanelPreview();
          }
        }}
      >
        View
      </Button>
      <Button
        isDisabled={isSubmitting}
        onPress={async () => {
          const result = await onSaveChanges();
          if (result) {
            linkTo(`/panels/${panel.id}/types`);
          }
        }}
      >
        New Widget
      </Button>
      <Button
        leftIcon={<Icon as={Feather} name="save" />}
        onPress={async () => {
          const result = await onSaveChanges();
          if (result)
            toast.show({
              description: 'Your changes are saved successfully',
              placement: 'top',
            });
        }}
        isLoading={isSubmitting}
        isLoadingText="Saving..."
      >
        Save
      </Button>
      <Button
        colorScheme="secondary"
        leftIcon={<Icon as={Feather} name="trash-2" />}
        isDisabled={isSubmitting}
        onPress={() =>
          alertDialog.current.show(
            'Discard changes',
            'Do you want to discard the changes?',
            <Button
              colorScheme="danger"
              onPress={() => {
                alertDialog.current.close();
                setIsLoading(true);
              }}
            >
              Discard
            </Button>
          )
        }
      >
        Discard
      </Button>
      <DeleteIconButton
        tip="Delete Panel"
        onPress={() =>
          deletePanel(panel.id, currentViewType).then(() => linkTo('/panels'))
        }
      />
    </HStack>
  );
}

export { Header };
