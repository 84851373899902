import { BottomBar, Container } from '@components/Container';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { useLinkTo } from '@react-navigation/native';
import { bodyBgColor } from '@utils/customTheme';
import { getSubTypes } from '@utils/widget';
import { sortBy } from 'lodash';
import {
  CheckIcon,
  Fab,
  Hidden,
  VStack,
  HStack,
  Radio,
  Button,
  ArrowBackIcon,
  ArrowForwardIcon,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { Header } from './header';

const SelectWidgetSubTypeScreen = ({ route }) => {
  const { params } = route;
  const linkTo = useLinkTo();
  const [subtypes, setSubtypes] = useState([]);
  const [selectedSubtype, setSelectedSubtype] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSubTypes().then((resp) => {
      const subtype_data = resp.data.filter(
        (subtype) => subtype.type.toString() === params?.id
      );
      setSubtypes(sortBy(subtype_data, [(o) => o.name === 'Custom', 'name']));
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const handleNextClick = () => {
    linkTo(
      `/panels/${route.params?.panel_id}/widgets/new?subtype=${selectedSubtype}`
    );
  };

  return (
    <>
        <Container pt={{ base: 0, md: 4 }} bgColor={bodyBgColor.dark}>
          <VStack>
            <Hidden till="md">
              <HStack py={2} alignItems="center" justifyContent="space-between">
                <Header subtypes={subtypes} />
              </HStack>
            </Hidden>
            <VStack
              _light={{ bg: { md: 'containerBgColorLight' } }}
              _dark={{ bg: { md: 'containerBgColorDark' } }}
              shadow={{ md: '1' }}
              borderRadius={{ md: '4' }}
              py={{ base: 2, md: 5 }}
              px={{ base: 2, md: 5 }}
              mb={{ base: 0, md: 5 }}
            >
              <Radio.Group
                onChange={(nextValue) => {
                  setSelectedSubtype(nextValue);
                }}
              >
                {subtypes.map((item) => (
                  <Radio key={item.id} value={item.id} my={1.5}>
                    {item.name}
                  </Radio>
                ))}
              </Radio.Group>
              <Hidden till="md">
                <BottomBar>
                  <Button
                    colorScheme="secondary"
                    onPress={() =>
                      linkTo(`/panels/${route.params?.panel_id}/types`)
                    }
                    startIcon={<ArrowBackIcon size="4" />}
                  >
                    Previous
                  </Button>
                  <Button
                    onPress={handleNextClick}
                    endIcon={<ArrowForwardIcon size="4" />}
                    isDisabled={!selectedSubtype}
                  >
                    Next
                  </Button>
                </BottomBar>
              </Hidden>
            </VStack>
          </VStack>
        </Container>
      <Hidden from="md">
        <Fab
          renderInPortal={false}
          icon={<CheckIcon />}
          onPress={handleNextClick}
          isDisabled={!selectedSubtype}
        />
      </Hidden>
    </>
  );
};

export { SelectWidgetSubTypeScreen };
