import { apiClient } from './apiClient';
import {
  readDataFromAllFields,
  readLastFieldEntryChannel,
} from './thingSpeakAPI';

const getWidgetFields = async (panel_id, widget_id) => {
  try {
    let { data: fields } = await apiClient.get(
      `panels/${panel_id}/widgets/${widget_id}/fields`
    );

    let promises = fields
      .filter((field) => field.channel_id)
      .map((field) =>
        readDataFromAllFields(field.channel_id, field.read_api_key)
      );
    const channels = await Promise.all(promises);
    fields = fields.map((field) => {
      const channel = channels.find(
        (channel) => channel.id === field.channel_id
      );
      if (channel) {
        return {
          ...field,
          name: channel[`field${field.field}`],
        };
      }
      return field;
    });

    promises = fields
      .filter((field) => field.channel_id)
      .map((field) =>
        readLastFieldEntryChannel(
          field.channel_id,
          field.read_api_key,
          field.field
        )
      );
    const allFields = await Promise.all(promises);
    fields = fields.map((field) => {
      const afield = allFields.find(
        (afield) =>
          afield.channel_id === field.channel_id &&
          afield.field_id === field.field
      );
      if (afield) {
        return {
          ...field,
          lastValue: afield[`field${field.field}`],
        };
      }
      return field;
    });

    return {
      data: fields,
    };
  } catch (e) {
    console.info(e);
  }
};

const saveWidgetFields = (panel_id, widget_id, data) =>
  apiClient.post(`panels/${panel_id}/widgets/${widget_id}/fields`, data);

const createWidgetField = (panel_id, widget_id, data) =>
  apiClient.post(`panels/${panel_id}/widgets/${widget_id}/fields/create`, data);

const getWidgetField = (panel_id, widget_id, field_id) =>
  apiClient.get(`panels/${panel_id}/widgets/${widget_id}/fields/${field_id}`);

const saveWidgetField = (panel_id, widget_id, field_id, data) =>
  apiClient.put(
    `panels/${panel_id}/widgets/${widget_id}/fields/${field_id}`,
    data
  );

const deleteWidgetField = (panel_id, widget_id, field_id) =>
  apiClient.delete(
    `panels/${panel_id}/widgets/${widget_id}/fields/${field_id}`
  );

export {
  createWidgetField,
  deleteWidgetField,
  saveWidgetField,
  getWidgetField,
  getWidgetFields,
  saveWidgetFields,
};
