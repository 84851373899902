import { useLinkTo } from '@react-navigation/native';
import { Box, HStack, Link } from 'native-base';
import React from 'react';
import { CustomHeaderRight } from '@components/CustomHeaderRight';
import { ContainerHeader } from '@components/Container';
import { LogoTitle } from '@components/LogoTitle';
import { appBarBgColor } from '@utils/customTheme';
import { useAuth } from '@hooks/useAuth';

function CustomAppBar(props) {
  const linkTo = useLinkTo();
  const { isAuthenticated } = useAuth();

  return (
    <ContainerHeader bgColor={appBarBgColor.dark}>
      <Box
        flex={1}
        flexDirection="row"
        py="14"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack>
          <LogoTitle />
          {isAuthenticated && (
            <HStack ml="12" space="8" alignItems="center">
              <Link
                onPress={() => linkTo('/panels')}
                isUnderlined={false}
                _text={{ fontSize: 'lg', fontWeight: 'medium' }
              }
              >
                Panels
              </Link>
              <Link            
                onPress ={() => linkTo('/connections')}
                isUnderlined={false}
                _text={{ fontSize: 'lg', fontWeight: 'medium' }}
              >
                Connections
              </Link>
            </HStack>
          )}
        </HStack>
        {isAuthenticated && (
          <HStack>
            <CustomHeaderRight />
          </HStack>
        )}
      </Box>
    </ContainerHeader>
  );
}

export { CustomAppBar };
