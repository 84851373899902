import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Box, Icon, IconButton, Text } from 'native-base';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

const TextChangeFontSize = ({ onChangeText, fontSize }) => {
  useEffect(() => {
    if (fontSize) {
      handleFontSizeChange(fontSize);  
    }
  }, [fontSize]);

  const [selectedFontSize, setSelectedFontSize] = useState(null);
  const [hoverSmall, setHoverSmall] = useState(false);
  const [hoverMedium, setHoverMedium] = useState(false);
  const [hoverLarge, setHoverLarge] = useState(false);

  const handleFontSizeChange = (size) => {
    setSelectedFontSize(size);
    onChangeText(size);
  };

  const styles = StyleSheet.create({
    mainStyle: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    textTitle: {
      fontSize: '14px',
      fontWeight: 500,
    },
    selectedButton: {
      backgroundColor: '#3b82f61a',
    },
  });

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        <View>
          <TouchableOpacity
            onPress={() => handleFontSizeChange('small')}
            onMouseEnter={() => setHoverSmall(true)}
            onMouseLeave={() => setHoverSmall(false)}
            style={[
              styles.mainStyle,
              selectedFontSize === 'small' && styles.selectedButton,
              hoverSmall && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={
                <Icon
                  as={MaterialCommunityIcons}
                  name="format-font-size-decrease"
                />
              }
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleFontSizeChange('small')}
            />
          </TouchableOpacity>
        </View>

        <View>
          <TouchableOpacity
            onPress={() => handleFontSizeChange('medium')}
            onMouseEnter={() => setHoverMedium(true)}
            onMouseLeave={() => setHoverMedium(false)}
            style={[
              styles.mainStyle,
              selectedFontSize === 'medium' && styles.selectedButton,
              hoverMedium && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={
                <Icon as={MaterialCommunityIcons} name="format-letter-case" />
              }
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleFontSizeChange('medium')}
            />
          </TouchableOpacity>
        </View>

        <View>
          <TouchableOpacity
            onPress={() => handleFontSizeChange('large')}
            onMouseEnter={() => setHoverLarge(true)}
            onMouseLeave={() => setHoverLarge(false)}
            style={[
              styles.mainStyle,
              selectedFontSize === 'large' && styles.selectedButton,
              hoverLarge && styles.selectedButton,
            ]}
          >
            <IconButton
              icon={
                <Icon
                  as={MaterialCommunityIcons}
                  name="format-font-size-increase"
                />
              }
              style={{ backgroundColor: 'transparent' }}
              onPressOut={() => handleFontSizeChange('large')}
            />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

export { TextChangeFontSize };
