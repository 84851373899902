import profileImage from '@assets/blank-profile-picture-g064812886_640.png';

import { ModalDialog } from '@components/ModalDialog';
import Entypo from '@expo/vector-icons/Entypo';
import { useAuth } from '@hooks/useAuth';
import { useLinkTo } from '@react-navigation/native';
import {
  Avatar,
  Divider,
  HStack,
  Hidden,
  Icon,
  Menu,
  Pressable,
  Tooltip,
} from 'native-base';
import React, { Fragment, useRef } from 'react';
import sleep from 'sleep-promise';

function CustomHeaderRight({ overflowMenu, route }) {
  const linkTo = useLinkTo();
  const { signout, userData, userPicture } = useAuth();
  const alertDialog = useRef(null);

  return (
    <>
      <HStack space="5" alignItems="center" mr={{ base: 3, md: 4 }}>
        <Menu
          style={{ left: 5 }}
          w="190"
          placement="bottom right"
          trigger={(triggerProps) => {
            return (
              <Pressable {...triggerProps}>

              { overflowMenu ? (<Icon as={Entypo} name="dots-three-vertical" size="lg" />) : (    
                    <>
                      {userPicture && (
                        <Avatar
                          w="10"
                          h="10"
                          _light={{ bg: 'darkGrey' }}
                          _dark={{ bg: 'darkGrey' }}
                          rounded
                          source={{
                            uri: userPicture,
                          }}
                        />
                      )}
                      {!userPicture && (
                        <Avatar
                          w="10"
                          h="10"
                          _light={{ bg: 'bodyBgColorLight' }}
                          _dark={{ bg: 'bodyBgColorDark' }}            
                        >
                          {userData?.letter}
                        </Avatar>
                      )}
                    </>
                  )
                      }
              </Pressable>
            );
          }}
        >
          {overflowMenu ? (
            overflowMenu({ alertDialog, route })
          ) : (
            <Fragment>
              <Menu.Group title={userData?.fullName}>
                <Menu.Item
                  onPress={() => sleep(100).then(() => linkTo('/Account'))}
                >
                  Account
                </Menu.Item>
              </Menu.Group>
              <Divider mt="3" w="100%" />
              <Menu.Group title="Shortcuts">
                <Menu.Item>Settings</Menu.Item>
                <Menu.Item
                  onPress={async () => {
                    await signout()
                    sleep(100).then(() => linkTo('/signin'))
                    }                   
                  }
                >
                  Sign Out
                </Menu.Item>
              </Menu.Group>
            </Fragment>
          )}
        </Menu>
      </HStack>
      <ModalDialog ref={alertDialog} />
    </>
  );
}

export { CustomHeaderRight };
