import React from 'react';
import { Hidden, HStack, useBreakpointValue, VStack } from 'native-base';
import { useGlobalContext } from '@hooks/useGlobalContext';

import { CustomGridStack12, CustomGridStack24 } from './CustomGridStack.web';

const GridStackContainer = ({ children, header, innerRef, widgets }) => {
  const { currentViewType } = useGlobalContext();
  const isLargeScreen = useBreakpointValue({
    md: true,
  });

  return (
    <VStack w="100%" alignSelf="flex-start">
      <Hidden till="md">
        {header && (
          <HStack
            _dark={{
              bgColor: '#1d2027',
            }}
            px={3}
            py={1}
            m="4px"
            mt="0"
            alignItems="center"
          >
            {header}
          </HStack>
        )}
      </Hidden>
      {currentViewType !== 'M' && <CustomGridStack24 />}
      {currentViewType === 'M' && <CustomGridStack12 />}

      <div className="grid-stack-wrapper">
        <div
          className={`grid-stack-inner ${
            currentViewType === 'M' && isLargeScreen
              ? 'grid-stack-inner-50'
              : ''
          }`}
        >
          <div ref={innerRef} className="grid-stack">
            {children}
          </div>
        </div>
      </div>
    </VStack>
  );
};

export { GridStackContainer };
