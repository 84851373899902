import { widgetBgColor } from '@utils/customTheme';
import { colord } from 'colord';
import Constants from 'expo-constants';
import { Box, Text } from 'native-base';
import React, { memo } from 'react';

const ComponentDimensions = memo(({ size }) => {
  const { w, h } = size;
  if (!Constants.manifest.extra.showDimensions) {
    return null;
  }
  return (
    <Box
      position="absolute"
      bottom="0.5"
      left="0.5"
      px="1"
      rounded={2}
      _light={{
        bgColor: colord(widgetBgColor.light).darken(0.03).toHex(),
      }}
      _dark={{
        bgColor: colord(widgetBgColor.dark).darken(0.014).toHex(),
      }}
    >
      {// prettier-ignore
      }
      {/*}
      // Remoção temporária pedida pela gerência através da demanda 538760
      <Text fontSize="11" noOfLines={1} adjustsFontSizeToFit  color="secondary.400" w="100%">{`${w.in} x ${h.in} in`}</Text>
    */}
    </Box>
  );
});

export { ComponentDimensions };
