import { HStack, View, Box, Flex, ScrollView } from 'native-base';
import { useWinDimensions } from '@hooks/useWinDimensions';
import {
  bodyBgColor,
  colorModeManager,
  containerBgColor,
  customTheme,
  headerStyleTheme,
  navigationContainerTheme,
} from '@utils/customTheme';
import React from 'react';
import { Platform } from 'react-native';
const maxW = 1140;
function ContainerHeader({ children, innerProps, ...props }) {
  return (
    <Flex {...props} w="100%" px={{ base: 0, md: 1 }} alignItems="center">
      <Box
        maxW={maxW}
        {...innerProps}
        w="100%"
        // bgColor="amber.100"
      >
        {children}
      </Box>
    </Flex>
  );
}
const ScrollViewCustom = ({ children }) => {
  if (Platform.OS === 'web') {
    const winDims = useWinDimensions();
    const h =  winDims.h.px - 100;
    return (
      <ScrollView maxW={maxW} w="100%" maxH={h} px={1} pb={4}>
        {children}
      </ScrollView>
    );
  } else {
    return <ScrollView pb={4}>{children}</ScrollView>;
  }
};
function Container({ children, innerProps, ...props }) {
  return (
    <Flex {...props} w="100%" px={{ base: 0, md: 1 }} alignItems="center">
      <Box maxW={maxW} {...innerProps} w="100%">
        <ScrollViewCustom>{children}</ScrollViewCustom>
      </Box>
    </Flex>
  );
}

function BottomBar({ children }) {
  return (
    <HStack
      space={3}
      mt={{ base: 50 }}
      pb={{ base: 10, md: 5 }}
      alignItems="center"
      justifyContent="space-between"
    >
      {children}
    </HStack>
  );
}

export { BottomBar, Container, ContainerHeader };
