// temporary workaround for expo 45 bug:
// https://github.com/expo/snack/pull/292
// fix: https://github.com/wcandillon/react-native-redash/issues/395#issuecomment-839586492
global.__reanimatedWorkletInit = function () {};
//https://github.com/expo/expo/issues/17758
import 'react-native-reanimated';
import lcdUltra from '@assets/fonts/LCD-Ultra.ttf';
import fntArial from '@assets/fonts/arial.ttf';
import fntCourierNew from '@assets/fonts/cour.ttf';
import fntVerdana from '@assets/fonts/verdana.ttf';
import fntTimesNewRoman from '@assets/fonts/times.ttf';
import fntCalibri from '@assets/fonts/calibri.ttf';
import fntComicSans from '@assets/fonts/comic.ttf';
import fntFranklinGothic from '@assets/fonts/framd.ttf';
import fntGabriola from '@assets/fonts/Gabriola.ttf';
import fntImpactRegular from '@assets/fonts/impact.ttf';
import fntInkFree from '@assets/fonts/Inkfree.ttf';
import fntSegoePrint from '@assets/fonts/segoepr.ttf';
import fntSourceCode from '@assets/fonts/SourceCodePro-Regular.ttf';

import { CustomStyles } from '@components/CustomStyles';
import { NavigationFallback } from '@components/NavigationFallback';
import { ProvideAuth } from '@components/ProvideAuth';
import { useAuth } from '@hooks/useAuth';
import { ProvideGlobal } from '@components/ProvideGlobal';
import {
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { createStackNavigator, Header } from '@react-navigation/stack';
import { ConnectionDetailScreen } from '@screens/ConnectionDetailScreen';
import { CustomAppBar } from '@components/CustomAppBar';
import { CustomHeaderRight } from '@components/CustomHeaderRight';
import { HomeScreen } from '@screens/HomeScreen';
import { NotFoundScreen } from '@screens/NotFoundScreen';
import { AccountScreen } from '@screens/Account';
import { AccountPasswordScreen } from '@screens/AccountPassword';
import { PanelWizardScreen } from '@screens/PanelWizardScreen';
import { SelectWidgetSubTypeScreen } from '@screens/SelectWidgetSubTypeScreen';
import { SelectWidgetTypeScreen } from '@screens/SelectWidgetTypeScreen';
import { SignInScreen } from '@screens/SignInScreen';
import { WidgetDetailScreen } from '@screens/WidgetDetailScreen';
import { WidgetFieldDetailScreen } from '@screens/WidgetFieldDetailScreen';
import { WidgetListScreen } from '@screens/WidgetListScreen';
import { WidgetPreviewScreen } from '@screens/WidgetPreviewScreen';
import { ForgetPasswordScreen } from '@screens/ForgetPasswordScreen';
import {
  bodyBgColor,
  colorModeManager,
  containerBgColor,
  customTheme,
  headerStyleTheme,
  navigationContainerTheme,
} from '@utils/customTheme';
import { decode, encode } from 'base-64';
import Constants from 'expo-constants';
import { useFonts } from 'expo-font';
import * as ScreenOrientation from 'expo-screen-orientation';
import {
  NativeBaseProvider,
  StatusBar,
  useBreakpointValue,
  useColorMode,
} from 'native-base';
import React, { useEffect } from 'react';
import {
  LogBox,
  Platform,
  SafeAreaView,
  StyleSheet,
  UIManager, ActivityIndicator, View
} from 'react-native';
import 'react-native-gesture-handler';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SplashScreen } from '@screens/SplashScreen';
import { linking } from './linking';
import { useFlipper } from '@react-navigation/devtools';
import { OpenInAppDialog } from '@components/OpenInAppDialog';

Promise.allSettled =
  Promise.allSettled ||
  ((promises) =>
    Promise.all(
      promises.map((p) =>
        p
          .then((value) => ({
            status: 'fulfilled',
            value,
          }))
          .catch((reason) => ({
            status: 'rejected',
            reason,
          }))
      )
    ));

if (Platform.OS === 'android') {
  UIManager.setLayoutAnimationEnabledExperimental &&
    UIManager.setLayoutAnimationEnabledExperimental(true);
}

if (!global.btoa) global.btoa = encode;
if (!global.atob) global.atob = decode;

LogBox.ignoreLogs([
  'When server rendering, you must wrap your application in an <SSRProvider>',
]);
LogBox.ignoreAllLogs(true);

const Stack = createStackNavigator();

function RootNavigator() {
  const navigationRef = useNavigationContainerRef();
  const isLargeScreen = useBreakpointValue({
    md: true,
  }) && Platform.OS === 'web';
  const { colorMode } = useColorMode();
  const { isLoading, isAuthenticated } = useAuth();

  useFlipper(navigationRef);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <NavigationContainer
      linking={linking}
      fallback={<NavigationFallback />}
      theme={navigationContainerTheme({ isLargeScreen, colorMode })}
      ref={navigationRef}
    >
      <OpenInAppDialog navigationRef={navigationRef} />
      <Stack.Navigator
        detachInactiveScreens={true}
        screenOptions={{
          unmountInactiveRoutes: true,
          unmountOnBlur: true,
          title: Constants.manifest.extra.pageTitle,
          headerTitleStyle: {
            fontSize: 14,
          },
          ...headerStyleTheme({ isLargeScreen, colorMode }),
          // headerShown: !isLargeScreen,
          header: (props) =>
            isLargeScreen ? <CustomAppBar {...props} /> : <Header {...props} />,
        }}
      >
        {isAuthenticated ? (
          <Stack.Group>
            <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="PanelWizard"
              component={PanelWizardScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="SelectWidgetType"
              component={SelectWidgetTypeScreen}
              options={{ headerTitle: 'New Widget' }}
            />
            <Stack.Screen
              name="SelectWidgetSubType"
              component={SelectWidgetSubTypeScreen}
              options={{ headerTitle: 'New Widget' }}
            />
            <Stack.Screen
              name="WidgetList"
              component={WidgetListScreen}
              options={{
                headerTitle: '',
                headerRight: (props) => <CustomHeaderRight {...props} />,
              }}
            />
            <Stack.Screen
              name="WidgetDetail"
              component={WidgetDetailScreen}
              options={{ headerTitle: 'Widget' }}
            />
            <Stack.Screen
              name="WidgetNew"
              component={WidgetDetailScreen}
              options={{ headerTitle: 'New Widget' }}
            />
            <Stack.Screen
              name="WidgetFieldDetail"
              component={WidgetFieldDetailScreen}
              options={{ headerTitle: 'Widget Field' }}
            />
            <Stack.Screen
              name="WidgetFieldNew"
              component={WidgetFieldDetailScreen}
              options={{ headerTitle: 'New Widget Field' }}
            />
            <Stack.Screen
              name="ConnectionNew"
              component={ConnectionDetailScreen}
              options={{ headerTitle: 'New Connection' }}
            />
            <Stack.Screen
              name="ConnectionDetail"
              component={ConnectionDetailScreen}
              options={{ headerTitle: 'Connection' }}
            />
            <Stack.Screen name="Account" component={AccountScreen} />
            <Stack.Screen
              name="AccountPassword"
              component={AccountPasswordScreen}
            />
          </Stack.Group>
        ) : (
          <>
            <Stack.Screen
              name="SignIn"
              component={SignInScreen}
              options={{
                headerShown: !!isLargeScreen,
              }}
            />
            <Stack.Screen
              name="Authorize"
              component={SignInScreen}
              options={{
                headerShown: !!isLargeScreen,
              }}
            />
          </>
        )}
        <Stack.Group navigationKey={isAuthenticated ? 'user' : 'guest'}>
          <Stack.Screen
            name="PanelPreview"
            component={WidgetPreviewScreen}
            options={{              
              headerTitle: '',
              headerRight: (props) => <CustomHeaderRight {...props} />,
            }}
          />
        </Stack.Group>
        <Stack.Screen
          name="ForgetPasswordScreen"
          component={ForgetPasswordScreen}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="passwordresetcomp"
          component={ForgetPasswordScreen}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="passwordresettkn"
          component={ForgetPasswordScreen}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default function App() {
  let [fontsLoaded, error] = useFonts({
    'LCD-Ultra': lcdUltra,
    'Arial': fntArial,
    'Courier New': fntCourierNew,
    'Verdana': fntVerdana,
    'Times New Roman': fntTimesNewRoman,
    'Calibri': fntCalibri,
    'Comic Sans': fntComicSans,
    'Franklin Gothic': fntFranklinGothic,
    'Gabriola': fntGabriola,
    'Impact Regular': fntImpactRegular,
    'Ink Free': fntInkFree,
    'Segoe Print': fntSegoePrint,
    'Source Code': fntSourceCode    
  });

  useEffect(() => {
    try {
      ScreenOrientation.unlockAsync();
    } catch (error) {}
  }, []);

  if (!fontsLoaded) {
    if (!error){
      return (
        <View style={{flex:1, justifyContent: 'space-around', flexDirection: "row", backgroundColor:bodyBgColor.dark}}>
          <ActivityIndicator size="large"  />    
        </View>    
        )
    }
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaView style={styles.container}>
        <StatusBar barStyle="transparent" backgroundColor={bodyBgColor.dark} />
        <CustomStyles>
          <ProvideAuth>
            <NativeBaseProvider
              theme={customTheme}
              colorModeManager={colorModeManager}
            >
              <ProvideGlobal>
                <RootNavigator />
              </ProvideGlobal>
            </NativeBaseProvider>
          </ProvideAuth>
        </CustomStyles>
      </SafeAreaView>
    </GestureHandlerRootView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: containerBgColor.dark,
  },
});
