import { useComponentSize } from '@hooks/useComponentSize';
import { Box, Flex } from 'native-base';
import React from 'react';

function ChartWrapper({ maxH, middle = false, render = () => {} }) {
  const [size, onLayout] = useComponentSize();

  const adjustedHeight =
    middle && size.height > size.width / 2
      ? Math.floor(size.width / 2)
      : size.height || '100%';

  return (
    <Box p={1} w="100%" h="100%">
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyItems="center"
        w="100%"
        h="100%"
        onLayout={onLayout}
      >
        <Flex
          w="100%"
          h={adjustedHeight}
          maxH={maxH}
        >
          {!!size.height && render({ ...size, adjustedHeight })}
        </Flex>
      </Flex>
    </Box>
  );
}

export { ChartWrapper };
